/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
export default {
  thermometer: {
    type: 'thermometer',
    name: '온습도계',
    icon: 'temperature-high',
    color: '#b3d9ff',
  },
  thermostat: {
    type: 'thermostat',
    name: '온도조절기',
    icon: 'fire-alt',
    color: '#b3d9ff',
  },
  generalio: {
    type: 'generalio',
    name: '범용스위치',
    icon: 'toggle-on',
    color: '#b3d9ff',
  },
  farmcontroller: {
    type: 'farmcontroller',
    name: '농업용개폐기',
    icon: 'toggle-on',
    color: '#b3d9ff',
  },
  light: {
    type: 'light',
    name: '조명스위치',
    icon: {
      prefix: 'far',
      iconName: 'lightbulb',
    },
    color: '#b3d9ff',
  },
  smartsensor: {
    type: 'smartsensor',
    name: '스마트센서',
    icon: {
      prefix: 'far',
      iconName: 'temperature-high',
    },
    color: '#b3d9ff',
  },
  heatcable: {
    type: 'heatcable',
    name: '열선제어기',
    icon: {
      prefix: 'far',
      iconName: 'temperature-high',
    },
    color: '#b3d9ff',
  },
  iotHub: {
    type: 'gateway',
    name: '네모안 IoT 허브',
    icon: 'hdd',
    color: '#b3d9ff',
  },
};


import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

export default class DeviceUnsubscribe extends Component {
  constructor(props) {
    super(props);

    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleApply() {
    this.props.onApply();
  };

  handleCancel() {
    this.props.onCancel();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>디바이스 구독 취소</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>디바이스 목록에서 디바이스가 삭제 됩니다.</span>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={this.handleApply}>구독취소</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

import React, { Component } from 'react';
import 'react-rangeslider/lib/index.css'
import Slider from "react-rangeslider"
import { queryDeviceControl } from "../../api/device";
import { convertUnit2String } from "../../utils";

class LevelSwitch extends Component {
  constructor (props, context) {
    super(props, context);

    const { value } = props;
    this.state = {
      value: value,
      baseValue: value
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({value: this.props.value})
    }
  }

  handleChange = (value) => {
    this.setState({value})
  };

  handleChangeComplete = () => {
    const { name } = this.props;
    const { value } = this.state;
    this.props.onChange(name, value);
    this.setState({
      baseValue: this.state.value
    });
  };

  /*
  handleChangeComplete = () => {
    const { deviceUuid, principalId } = this.props;
    if (this.state.baseValue !== this.state.value){
      const { uuid, name, type } = trait;
      queryDeviceControl(principalId, deviceUuid, {
        [uuid]: {
          name,
          type,
          settingValue: this.state.value
        }
      });
      this.setState({baseValue:this.state.value});
    }
  };
   */

  render () {
    const { unit, min, max, disabled } = this.props;
    const { value } = this.state;
    const strunit = convertUnit2String(unit);

    return (
      <div className='slider'>
        <div className="d-flex justify-content-center">
          <h6 className='value'>{value + ' ' + unit}</h6>
        </div>
        <Slider
          className="my-0"
          disabled={disabled}
          min={min}
          max={max}
          value={value}
          tooltip={true}
          //labels={{5:5, 40:40}}
          format={value => value + strunit}
          //handleLabel={value}
          orientation='horizontal'
          onChange={this.handleChange}
          onChangeStart={this.handleStartChange}
          onChangeComplete={this.handleChangeComplete}
        />
      </div>
    )
  }
}

export default LevelSwitch;

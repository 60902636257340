/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React, { Component } from 'react';

export default class InfoCard extends Component {
  render() {
    const { className, ...otherProps } = this.props;
    const { header, title, children } = otherProps;
    return (
      <div className={['card', className].join(' ')}>
        <div className="card-header">{header}</div>
        <div className="card-body d-flex flex-column">
          <div className="card-title h5">{title}</div>
          {children}
        </div>
      </div>
    );
  }
}
/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React, { Component } from 'react';

export default class InputRow extends Component {
  render() {
    const { label, children } = this.props;

    return (
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">{label}</label>
        <div className="col-sm-10">
          {children}
        </div>
      </div>
    )
  }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { NavLink, Link } from "react-router-dom";
import { selectSidebarItem } from "../../actions";


class SidebarItem extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { to, onItemClick } = this.props;
    onItemClick(to);
  }

  render () {
    const { to, children } = this.props;

    return (
      <NavLink className='nav-link' activeClassName='nav-link active' to={to} onClick={this.handleClick}>
        {children}
      </NavLink>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onItemClick: to => {
      dispatch(selectSidebarItem(to))
    }
  }
};

export default connect(
  undefined,
  mapDispatchToProps,
)(SidebarItem);

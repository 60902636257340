/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React from "react";

export default function BinarySensor(props) {
  const { value } = props;
  return  <h6>{value.toString()}</h6>;
}
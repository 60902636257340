
/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import { API } from 'aws-amplify';

const apiName = 'RaonixCloud';

export function queryUserGet (principalId, payment = false) {
  let path = `/users/${principalId}`;

  return API.get(apiName, path, {
    queryStringParameters: {
      payment
    },
    headers: {
      principalId
    },
    response: true
  });
}

export function queryUserPost (principalId, params) {
  let path = `/users`;
  return API.post(apiName, path, {
    headers: {
      principalId
    },
    body: params,
    response: true
  });
}

export function queryUserPut (principalId, params) {
  let path = `/users/${principalId}`;
  return API.put(apiName, path, {
    headers: {
      principalId
    },
    body: params,
    response: true
  });
}

export function queryNotificationEmailGet(principalId) {
  let path = `/users/notification/email`;
  return API.get(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    response: true
  });
}

export function queryNotificationEmailPost(principalId, emails) {
  let path = `/users/notification/email`;
  return API.post(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    body: [ ...emails ],
    response: true
  });
}

export function queryNotificationEmailDelete(principalId, emails) {
  let path = `/users/notification/email`;
  return API.del(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    body: [ ...emails ],
    response: true
  });
}

export function queryNotificationSmsGet(principalId) {
  let path = `/users/notification/sms`;
  return API.get(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    response: true
  });
}

export function queryNotificationSmsPost(principalId, phones) {
  let path = `/users/notification/sms`;
  return API.post(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    body: [ ...phones ],
    response: true
  });
}

export function queryNotificationSmsDelete(principalId, phones) {
  let path = `/users/notification/sms`;
  return API.del(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    body: [ ...phones ],
    response: true
  });
}

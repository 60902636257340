import "react-datepicker/dist/react-datepicker.css" // TODO: css 가 적용이 안되는것 같다. LevelSwitch 는 이렇게 절대경로를 써서 적용을 했는데 왜지?

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import { InputRow, RangeSelect } from "../../components/common";
import { REPORT_WRITERS} from "./report";

function InputText (props) {
  const { label, empty, name, placeholder, onChange } = props;
  let value = props.value;

  let style = {};

  if (!value && empty) {
    style = { color: 'red' };
    value = "입력해주세요";
  }

  return (
    <InputRow label={label}>
      <input
        style={style}
        className="form-control"
        name={name}
        value={value}
        placeholder={placeholder}
        type="text"
        onChange={onChange}
      />
    </InputRow>
  );
}

export default class ReportSetting extends Component {
  constructor(props) {
    super(props);

    /**
     * history device에 있는 traits은 삭제
     */

    const { devices, report } = this.props;

    const devTraits = devices.reduce((acc, dev) => {
      acc.push(...Object.keys(dev.traits));
      return acc;
    }, []);

    Object.keys(report.traits).forEach(uuid => {
      if (!devTraits.includes(uuid)) {
        delete report.traits[uuid];
      }
    });

    this.state = {
      validInputs: true,
      deviceType: report.deviceType,
      writer: report.writer,
      title: report.title,
      description: report.description,
      startDate: new Date(report.startDate),
      endDate: new Date(report.endDate),
      traits: report.traits
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;


    switch(name) {
      case 'writer':
        this.setState({
          validInputs: true,
          [name]: value,
          traits: {}
        });
        break;
      case 'deviceType':
        this.setState({
          validInputs: true,
          [name]: value,
          writer: REPORT_WRITERS[value][0].name,
        });
        break;
      default:
        this.setState({
          validInputs: true,
          [name]: value,
        });
        break;
    }

    /*
    if (name === 'writer') {
      this.setState({
        validInputs: true,
        [name]: value,
        traits: {}
      });
    } else {
      this.setState({
        validInputs: true,
        [name]: value,
        writer: REPORT_WRITERS[value][0].name,
      });
    }
     */
  };

  handleStartChange = (date) => {
    this.setState({ startDate: date });
  };

  handleEndChange = (date) => {
    this.setState({ endDate:  date });
  };

  handleReportChange = (traits) => {
    this.setState({ traits });
  };

  handleApply = () => {
    const { onApply } = this.props;
    const {
      title,
      description,
    } = this.state;

    if (!title || !description) {
      this.setState({ validInputs: false });
      return;
    }

    const {
      validInputs,
      startDate,
      endDate,
      ...rest
    } = this.state;

    onApply({
      ...rest,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  };

  handleCancel = () => {
    const traits = this.props.report.traits;
    this.setState( { traits });
    this.props.onCancel();
  };

  render () {
    const modalTitle = this.props.title;
    const { devices } = this.props;
    const {
      deviceType,
      writer,
      title,
      description,
      startDate,
      endDate,
      traits,
      validInputs,
    } = this.state;


    let reportWriter = REPORT_WRITERS[deviceType].find(e => {
      return e.name === writer;
    });

    return (
      <Modal
        show={this.props.show}
        size="lg"
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputText
            label="제목"
            empty={!validInputs}
            name="title"
            value={title}
            placeholder="보고서 제목"
            onChange={this.handleChange}
          />
          <InputText
            label="메모"
            empty={!validInputs}
            name="description"
            value={description}
            placeholder="간단히 메모 해주세요."
            onChange={this.handleChange}
          />

          <InputRow label="날짜">
            <RangeSelect
              startDate={startDate}
              endDate={endDate}
              onStartChange={this.handleStartChange}
              onEndChange={this.handleEndChange}
            />
          </InputRow>

          <InputRow label="디바이스">
            <select
              className="custom-select"
              name="deviceType"
              value={deviceType}
              onChange={this.handleChange}
            >
              {Object.keys(REPORT_WRITERS).map(key =>
                <option key={key} value={key}>{key}</option>
              )}
            </select>
          </InputRow>

          <InputRow label="리포트형식">
            <select
              className="custom-select"
              name="writer"
              value={writer}
              onChange={this.handleChange}
            >
              {deviceType && REPORT_WRITERS[deviceType].map(e => {
                return <option key={e.name} value={e.name}>{e.nickname}</option>;
              })}
            </select>
          </InputRow>

          {reportWriter && (
            <reportWriter.Setting
              devices={devices}
              traits={traits}
              onChange={this.handleReportChange}
            />
          )}

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleApply}>적용</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ReportSetting.defaultProps = {
  principalId: '',
  show: false,
  title: '',
  report: {
    title: '',
    description: '',
    deviceType: Object.keys(REPORT_WRITERS)[0],
    // TODO: check default
    // writer: REPORT_WRITERS.thermometer[0].name,
    startDate: (() => {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      startDate.setHours(0,0,0,0);
      return startDate.toISOString();
    })(),
    endDate: (() => {
      let endDate = new Date();
      endDate.setHours(0,0,0,0);
      return endDate.toISOString();
    })(),
    traits: {}
  }
};

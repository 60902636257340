/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React from "react";
import { convertUnit2String } from "../../utils";

export default function LevelSensor(props) {
  const { value, unit } = props;
  return <h6>{value + ' ' + convertUnit2String(unit)}</h6>;
}


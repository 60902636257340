import {combineReducers} from 'redux';
import dashboard from './dashboard';
import user from './user';
import devices from './devices';
import places from './places';
import reports from './reports';
import records from './records';

export default combineReducers({
  dashboard,
  user,
  places,
  devices,
  reports,
  records
});

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import { API } from 'aws-amplify';
import config from '../config';

const apiName = 'RaonixCloud';

export function queryPlacesPost(principalId, place) {
  let path = `/places`;

  return API.post(apiName, path, {
    headers: {
      principalId
    },
    body: place,
    response: true
  });
}

export function queryGetPlaces(principalId) {
  let path = `/places`;

  return API.get(apiName, path, {
    headers: {
      principalId
    },
    response: true
  });
}

export function queryPutPlace(principalId, uuid, params) {
  let path = `/places/${uuid}`;
  return API.put(apiName, path, {
    headers: {
      principalId
    },
    response: true,
    body: params
  });
}

export function queryDeletePlace(principalId, uuid) {
  let path = `/places/${uuid}`;
  return API.del(apiName, path, {
    headers: {
      principalId
    },
    response: true
  });
}

export function queryGetPlaceDevices(principalId, uuid) {
  let path = `/places/${uuid}/devices`;

  return API.get(apiName, path, {
    headers: {
      principalId
    },
    response: true
  });
}

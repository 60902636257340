import React, { Component } from "react";
import DeviceTraits from "./DeviceTraits";
import { Dropdown, Col, Card } from "react-bootstrap";
import DropdownToggle from "../../components/common/DropdownToggle";

import config from "../../config";

function Connection(props) {
    const { value } = props;

    return <span>{value ? "con" : "dis"}</span>;
}

function Rssi(props) {
    const { value } = props;

    return <span>{value}</span>;
}

function Voltage(props) {
    const { value } = props;

    return <span>{value}</span>;
}

class DeviceItem extends Component {
    constructor(props) {
        super(props);
    }

    handleSelectMenu = (eventKey, event) => {
        this.props.onSelectMenu(eventKey, this.props.device);
    };

    render() {
        const { principalId, device } = this.props;
        let model;

        if (config.models.hasOwnProperty(device.modelCode)) {
            model = config.models[device.modelCode];
        }

        let connected, rssi, voltage;
        let traits = [];

        for (let uuid in device.traits) {
            let tr = device.traits[uuid];

            if (tr.name === "connected") {
                connected = tr;
            }
            if (tr.name === "rssi") {
                rssi = tr;
            }
            if (tr.name === "batteryvoltage") {
                voltage = tr;
            }

            tr = { uuid, ...tr };
            if (model) {
                if (model.traitFilter.includes(tr.name)) {
                    traits.push(tr);
                }
            } else {
                traits.push(tr);
            }
        }

        const compareNickname = (a, b) => {
            const na = a.nickname;
            const nb = b.nickname;
            return na < nb ? -1 : na > nb ? 1 : 0;
        };

        traits.sort(compareNickname);

        return (
            <Col className={"form-group"} xl={3} lg={4} md={6} sm={6}>
                <Card className={"card-" + device.type}>
                    {/*
          <Card.Header style={{background: `var(--${this.colorByType(device.type)})`, fontWeight: 'bold'}}>
          */}
                    <Card.Header className={"statcard-" + device.type} style={{ fontWeight: "bold" }}>
                        <div className="d-flex justify-content-between">
                            <Dropdown className="dropdown" onSelect={this.handleSelectMenu}>
                                <Dropdown.Toggle as={DropdownToggle}>
                                    <span>{device.nickname}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey={"settings"}>설정</Dropdown.Item>
                                    <Dropdown.Item eventKey={"filedownload"}>CSV 다운로드</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/*
            <span>{`isFetching: ${device.isFetching} isInvalid: ${device.isInvalid}`}</span>
            */}
                            <div className="d-flex flex-row-reverse">
                                {connected && <Connection value={connected.currentValue} />}/
                                {rssi && <Rssi value={rssi.currentValue} />}/
                                {voltage && <Voltage value={voltage.currentValue} />}
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>{this.props.children}</Card.Body>
                </Card>
            </Col>
        );
    }
}

export default DeviceItem;

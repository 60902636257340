import React, { Component } from 'react';

import { Pagination } from "react-bootstrap";

export default class TablePagination extends Component {
  constructor (props) {
    super(props);

    this.state = {
      active: this.props.selectedPage,
    };

    this.handlePageSelect = this.handlePageSelect.bind(this);
  }

  getPagination() {
    const { count, pageLine, pageGroup } = this.props;
    const { active } = this.state;

    const pages = Math.trunc(count / pageLine) + ((count % pageLine === 0) ? 0 : 1);
    const start = Math.trunc((active - 1) / pageGroup) * pageGroup + 1;
    const end = (pages - start) >= pageGroup ? start+(pageGroup-1) : pages;

    return { pages, start, end };
  }

  handlePageSelect(eventKey, e) {
    console.debug('eventKey: ', eventKey);
    console.debug('event: ', e);

    const { pageGroup } = this.props;
    const { active } = this.state;

    const { pages, start, end } = this.getPagination();

    let selectedPage = active;

    switch(eventKey) {
      case 'first':
        selectedPage = 1;
        break;
      case 'prevel':
        if (start > pageGroup) {
          selectedPage = start - pageGroup;
        }
        break;
      case 'prev':
        selectedPage = selectedPage == 1 && selectedPage || selectedPage - 1;
        break;
      case 'nextel':
        if (pages - end > 0) {
          selectedPage = end + 1;
        }
        break;
      case 'next':
        selectedPage = selectedPage == pages && pages || selectedPage + 1;
        break;
      case 'last':
        selectedPage = pages;
        break;
      default:
        selectedPage = parseInt(eventKey);
        break;
    }

    this.setState({
      active: selectedPage
    });

    this.props.onPageSelect(selectedPage);
  };


  render () {
    const { count, pageLine, pageGroup } = this.props;
    const { active } = this.state;

    let pageItems=[];

    const { pages, start, end } = this.getPagination();

    /*
    console.debug('count : ', count);
    console.debug('pageLine : ', pageLine);
    console.debug('pageGroup : ', pageGroup);
    console.debug('active : ', active);
    console.debug('pages : ', pages);
    console.debug('start: ', start);
    console.debug('end : ', end);
     */

    const pageHead = (
      <React.Fragment>
        <Pagination.First onClick={(e) => this.handlePageSelect('first', e)} />
        <Pagination.Prev onClick={(e) => this.handlePageSelect('prev', e)} />
        {start > pageGroup && (
          <React.Fragment>
            <Pagination.Item key={1} onClick={(e) => this.handlePageSelect('1', e)}>{1}</Pagination.Item>
            <Pagination.Ellipsis onClick={(e) => this.handlePageSelect('prevel', e)} />
          </React.Fragment>
        )}
      </React.Fragment>
    );

    const pageTail = (
      <React.Fragment>
        {end < pages && (
          <React.Fragment>
            <Pagination.Ellipsis onClick={(e) => this.handlePageSelect('nextel', e)} />
            <Pagination.Item key={pages} onClick={(e) => this.handlePageSelect(pages.toString(), e)}>{pages}</Pagination.Item>
          </React.Fragment>
        )}
        <Pagination.Next onClick={(e) => this.handlePageSelect('next', e)} />
        <Pagination.Last onClick={(e) => this.handlePageSelect('last', e)} />
      </React.Fragment>
    );

    for (let i=start; i<=end; i++) {
      pageItems.push(
        <Pagination.Item key={i} active={i === active} activeLabel={i} onClick={(e) => this.handlePageSelect(i.toString(), e)}>
          {i}
        </Pagination.Item>);
    }

    return (
      <Pagination>
        {pageHead}
        {pageItems}
        {pageTail}
      </Pagination>
    );
  }
}

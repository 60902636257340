import React, { Component } from 'react';

/*
class DropdownToggle extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <div className={'toggle dropdown-toggle'} href=""
           onClick={this.handleClick }>
        {this.props.children}
      </div>
    );
  }
}
 */

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const DropdownToggle = React.forwardRef( ({ children, onClick }, ref) => (
    <div className={'toggle dropdown-toggle'}
         href=""
         ref={ref}
         onClick={e => {
           e.preventDefault();
           onClick(e);
         }}
    >
      {children}
    </div>
  )
);

export default DropdownToggle;

import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
//import DatePicker from 'react-datepicker/es'   // https://reactdatepicker.com/
//import "react-datepicker/dist/react-datepicker.css";  // TODO: css 적용안됨

class RangeSelect extends Component {
  constructor(props) {
    super(props);

    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);

    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
    }
  }

  handleStartChange = (date) => {
    this.setState({startDate: date});
    this.props.onStartChange(date);
  };

  handleEndChange = (date) => {
    this.setState({endDate: date});
    this.props.onEndChange(date);
  };

  render() {
    return (
      <div>
        <ReactDatePicker
          className="form-control"
          selected={this.state.startDate}
          selectsStart
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleStartChange}
        />
        <span className="mx-2">~</span>
        <ReactDatePicker
          className="form-control"
          selected={this.state.endDate}
          selectsEnd
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleEndChange}
        />
      </div>
    )
  }
}
export default RangeSelect;

import React, { useEffect } from 'react';
import { Hub } from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';

import {Auth} from "aws-amplify";

const OAuthCallback3 = (props) => {
  const { location, history } = props;

  useEffect(async () => {

    // const { code, state } = queryString.parse(location.search);
    const {id_token} = queryString.parse(location.hash);
    console.log(jwtDecode(id_token));

    try {
      const {name, email, exp} = jwtDecode(id_token);
      /*
      const credentials = await Auth.federatedSignIn(
        'dev-lb.raonix.com',
        { token: id_token, expires_at: exp },
        { name, email }
      );
      console.log('credentials', credentials);
      history.replace('/');
       */
    } catch(err) {
      console.error(err);
    }
  });

  return (
    <p>
      {location.search}
    </p>
  );
}

export default OAuthCallback3;

import React, { Component } from 'react';
import { Storage } from 'aws-amplify';

import PdfPreview from '../report/PdfPreview';
import { dateOpt } from '../report/report'

import {
  Toolbar,
  TablePagination
} from "../../components/common";

const PAGE_LINE  = 20;
const PAGE_GROUP  = 10;
const toolBar = [
  { name: 'download', icon: 'icon-download' },
  { name: 'delete', icon: 'icon-trash' },
  { name: 'refresh', icon: 'icon-cw' }
];

function Theader(props) {
  const { width, id, sortType, sort, onClick } = props;
  let className = 'header';

  if (id === sortType) {
    className += (sort === 1) ? ' headerSortUp' : ' headerSortDown';
  }

  return (<th width={width} id={id} className={className} onClick={onClick}>{props.children}</th>)
}


function TRow(props) {
  const { id, onSelect } = props;

  function handleClick(e) {
    if (e.target.type === undefined) {
      e.preventDefault();
      console.debug(e.type);
      console.debug(e.target);
      onSelect(parseInt(id));
    }
  }

  return (
    <tr onClick={handleClick}>
      {props.children}
    </tr>
  )
}

class ReportList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreview: false,
      fileList: [],
      sortType: 'date',
      sortDirections: {
        date: 1,
        type: 0,
        title: 0
      },

      // Table pagination
      selectedPage: 1,
      fileCount: 0
    };
  }

  componentDidMount() {
    console.debug('>>>>>>>>>>>>>>> componentWillUnmount');
    this.getFileList();
  }

  handleToolbar = (e) => {
    e.preventDefault();

    const { principalId } = this.props;
    const { fileList } = this.state;
    const name = e.currentTarget.name;

    switch (name) {
      case 'delete':
        (async (array) => {
          try {
            for (const e of array) {
              if (e.checked) {
                await Storage.remove(`reports/${principalId}/${e.fileName}`,
                  { level: 'public' })
                  .then(result =>  console.log(result))
                  .catch(err => console.error(err));
              }
            }
          } catch(err) {
            console.error(err);
          }
        })(fileList)
          .then(() => {
            this.getFileList();
          })
          .catch(err => console.error(err));
        break;
      case 'refresh':
        this.getFileList();
        break;
      case 'download':
        (async (array) => {
          try {
            for (const e of array) {
              if (e.checked) {
                // S3에서 Object로 다운 받아서 Blob로 만들어서 <a>로 링크해서 저징???
                // url를 S3에서 받아서 window.open()를 이용하면 하나만 다운로드...
                const res = await Storage.get(`reports/${principalId}/${e.fileName}`,
                  {level: 'public', download: true});

                let blob = new Blob([res.Body], {type: res.ContentType});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = e.fileName;
                link.click();

                /*
                const url = await Storage.get(`reports/${principalId}/${e.fileName}`,
                  {level: 'public', download: false});
                window.open(url);
                 */
              }
            }
          } catch(err) {
            console.error(err);
          }
        })(fileList)
          .then(() => {
            // this.getFileList();
          })
          .catch(err => console.error(err));
        break;
    }
  };

  handlePageSelect = (selectedPage) => {
    this.setState({ selectedPage });
  };

  handleSort = (e) => {
    const { sortType, sortDirections } = this.state;

    const { id } = e.target;

    if (id === sortType) {
      this.setState({
        sortDirections: {
          ...sortDirections,
          [id]:  sortDirections[id] === 1 ? 0 : 1
        }
      })
    } else {
      this.setState({
        sortType: id
      })
    }
  };

  handleRowSelect = (index)  => {
    const { principalId } = this.props;
    const { fileList } = this.state;

    let file = fileList.find(e => {
      return (e.index === index);
    });

    Storage.get(`reports/${principalId}/${file.fileName}`, {
      level: 'public',
      download: false  // true 실제 data가 다운 됨
    })
      .then(url => {
        console.log(url);

        this.setState( {
          showPreview: true,
          pdfFile: url
        });
      })
      .catch(err => console.log(err));
  }

  handleCheck = (e) => {
    const { fileList } = this.state;

    const target = e.target;
    const { id, name } = target;

    if (name === 'selectAll') {
      const { selectedPage } = this.state;

      fileList.forEach((e, index) => {
        const start = (selectedPage - 1) * PAGE_LINE;
        const end = start + PAGE_LINE;
        if (index >= start && index < end) {
          e.checked = target.checked;
        }
      });
    } else {
      const index = parseInt(id);
      let file = fileList.find(e => {
        return (e.index === index);
      });

      file.checked = target.checked;
    }

    this.setState({ fileList });
  };

  handlePreviewClose = () => {
    this.setState({ showPreview: false });
  };

  getFileList() {
    const { principalId } = this.props;

    Storage.list(`reports/${principalId}/`, {
      level: 'public'
    })
      .then(result => {
        let fileList = result.reduce((acc, e) => {
          let fileName = (path => path.replace(/^.*[\\\/]/, ''))(e.key);
          if (fileName) {
            let items = fileName.split('#');
            acc.push({
              fileName,
              title: items[0],
              type: items[1],
              writer: items[2],
              start: items[3],
              end: items[4],
              step: items[5], // TODO: step에 확장자가 붙음....
              checked: false
            });
          }
          return acc;
        }, []).map((e, index) => ({ index, ...e }));

        this.setState({
          fileCount: fileList.length,
          fileList
        });
      })
      .catch(err => console.log(err));
  }

  sortTable(rows, sort, direction) {
    const compare = (a, b) => {
      if (a === b) {
        return 0;
      } else {
        if (direction === 0) {
          return (a > b) ? 1 : -1
        } else {
          return (a < b) ? 1 : -1
        }
      }
    };

    rows.sort((a, b) => {
      switch(sort) {
        case 'date':
          return compare(a.start, b.start);
          break;
        case 'title':
          return compare(a.title, b.title);
          break;
        case 'type':
          return compare(a.type, b.type);
          break;
      }
    });

    return rows;
  }

  render () {
    console.debug('>>>>>>>>>>>>>>> render');

    const { fileList, fileCount, selectedPage, sortType, sortDirections } = this.state;

    let rows = fileList.filter((e, index) => {
      const start = (selectedPage - 1) * PAGE_LINE;
      const end = start + PAGE_LINE;
      return (index >= start && index < end);
    });
    this.sortTable(rows, sortType, sortDirections[sortType]);

    return (
      <React.Fragment>
        <Toolbar items={toolBar} onClick={this.handleToolbar}/>
        <div className="table-responsive">
          <table className="table" data-sort="table">
            <thead>
            <tr>
              <th width="5%" id='check' className="header" >
                <input
                  type="checkbox"
                  className="select-all"
                  name="selectAll"
                  onChange={this.handleCheck}
                />
              </th>
              <Theader width="25%" id='type' sortType={sortType} sort={sortDirections['type']} onClick={this.handleSort}>
                Type
              </Theader>
              <Theader width="40%" id='title' sortType={sortType} sort={sortDirections['title']} onClick={this.handleSort}>
                Title
              </Theader>
              <Theader width="30%" id='date' sortType={sortType} sort={sortDirections['date']} onClick={this.handleSort}>
                Date
              </Theader>
            </tr>
            </thead>
            <tbody>
            {rows.map((e) => {
              return (
                <TRow key={e.index} id={e.index} onSelect={this.handleRowSelect}>
                  <td>
                    <input
                      id={e.index}
                      type="checkbox"
                      checked={e.checked}
                      onChange={this.handleCheck}
                    />
                  </td>
                  <td>{e.type}</td>
                  <td>{e.title}</td>
                  <td>
                    {new Date(e.start).toLocaleString('ko-KR', dateOpt)} ~
                    {new Date(e.end).toLocaleString('ko-KR', dateOpt)}
                  </td>
                </TRow>
              )
            })}
            </tbody>
          </table>
        </div>
        <TablePagination
          selectedPage={selectedPage}
          count={fileCount}
          pageLine={PAGE_LINE}
          pageGroup={PAGE_GROUP}
          onPageSelect={this.handlePageSelect}/>

        <PdfPreview
          file={this.state.pdfFile}
          show={this.state.showPreview}
          onClose={this.handlePreviewClose}
        />

      </React.Fragment>
    );
  }
}

export default ReportList;

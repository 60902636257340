import React, { Component } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Auth, Logger } from 'aws-amplify';

const logger = new Logger('SignUp');

export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.signUp = this.signUp.bind(this);
    this.changeState = this.changeState.bind(this);
    this.inputs = {};
    this.state = { error: '', alert: false }
  }

  changeState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(state, data);
    }
  }

  signUp(e) {
    e.preventDefault();

    const { username, password, name, phone_number, address, email } = this.inputs;

    let err = [];

    [
      ['username', 'Username'],
      ['password', 'Password'],
      ['name', 'Name'],
      ['phone_number', 'Phone number'],
      ['address', 'Address']
    ].forEach((e) => {
      if (this.inputs[e[0]] === undefined) {
        err.push(e[1]);
      }
    });

    logger.debug(err.toString());

    if (err.length) {
      this.setState({
        error: 'The following fields need to be filled out: ' + err.toString(),
        alert: true
      });
    } else {
      Auth.signUp({
        username,
        password,
        attributes: {
          email,          // optional
          name,
          phone_number,   // optional - E.164 number convention
          address
        }
      })
        .then(() => this.handleSuccess(username))
        .catch(err => this.handleError(err));
    }
  }

  handleSuccess(username) {
    logger.info('sign up success with ' + username);
    this.changeState('confirmSignUp', { username });
  }

  handleError(err) {
    logger.info('sign up error', err);
    let message = err.message || err;
    if (message.startsWith('Invalid phone number')) {
      // reference: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
      message = 'Phone numbers must follow these formatting rules: A phone number must start with a plus (+) sign, followed immediately by the country code. A phone number can only contain the + sign and digits. You must remove any other characters from a phone number, such as parentheses, spaces, or dashes (-) before submitting the value to the service. For example, a United States-based phone number must follow this format: +14325551212.'
    }
    this.setState({
      error: message,
      alert: true
    });
  }

  render() {
    const { authState } = this.props;
    if (authState !== 'signUp') { return null; }

    const { error } = this.state;

    return (
      <div className="text-center">
        <div className="login bg-white">
          <h5 className="font-weight-bold text-primary title">
            Create a new account
          </h5>
          <Form onSubmit={this.signUp}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="text-primary">Username<small>*</small></Form.Label>
              <Form.Control type="email" placeholder="Username"
                            onChange={event => {
                              this.inputs.username = event.target.value;
                              this.inputs.email = event.target.value;
                            }}/>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="text-primary">Password<small>*</small></Form.Label>
              <Form.Control type="password" placeholder="Password"
                            onChange={event => this.inputs.password = event.target.value}/>
            </Form.Group>
            <Form.Group controlId="formBasicName">
              <Form.Label className="text-primary">Name<small>*</small></Form.Label>
              <Form.Control type="text" placeholder="Name"
                            onChange={event => this.inputs.name = event.target.value}/>
            </Form.Group>
            <Form.Group controlId="formBasicPhonenumber">
              <Form.Label className="text-primary">Phone number<small>*</small></Form.Label>
              <Form.Control type="text" placeholder="+82317350501"
                            onChange={event => this.inputs.phone_number = event.target.value}/>
            </Form.Group>

            <Form.Group controlId="formBasicAddress">
              <Form.Label className="text-primary">Address<small>*</small></Form.Label>
              <Form.Control type="text" placeholder="경기도 광주시 오포읍 창뜰윗길 21-11 라온익스"
                            onChange={event => this.inputs.address = event.target.value}/>
            </Form.Group>

            <div className="d-flex align-items-center submit-box">
              <a className="mr-auto" href="#" onClick={(e) => {
                e.preventDefault();
                this.changeState('signIn')
              }}>Have an account? Sign in</a>
              <Button className="submit-btn" type="submit">CREATE ACCOUNT</Button>
            </div>
          </Form>

          <Alert className="fixed-top" variant="warning" dismissible
                 show={this.state.alert}
                 onClose={() => this.setState({ alert: false })}>
            <p>{error}</p>
          </Alert>
        </div>
      </div>
    )
  }
}

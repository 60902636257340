import React, { Component } from 'react';
import queryString from 'query-string';

import { InfoCard, ItemRow }from "../../components/common";

import { queryPaymentApprovePost, queryPaymentReadyGet } from "../../api/payment";
import { priceFormat } from "../../utils";

class KakaoPayResult extends Component {
  constructor(props) {
    super(props);
    console.debug('>>>>>>>>>>>>>>> constructor');

    this.state = {
      result: 'approve',
      disabled: false
    };
  }

  componentDidMount() {
    console.debug('>>>>>>>>>>>>>>> componentDidMount');

    const { location, match } = this.props;
    let paymentUuid = match.params.uuid;

    const query = queryString.parse(location.search);
    const a = location.pathname.split('/');
    const result = a.pop();

    queryPaymentReadyGet(paymentUuid)
      .then(res => {
        const payment = res.data;
        this.setState({ payment })
      })
      .catch(err => {
        console.error(err);
      });

    if (result === "success") {
      queryPaymentApprovePost({
        paymentUuid: match.params.uuid,
        pg_token: query.pg_token
      })
        .then(res => {
          console.debug(res);
          if (res.status === 200) {
            this.setState({ result: 'success' })
          } else {
            this.setState({ result: 'fail' })
          }
        })
        .catch(err => {
          console.debug(err);
          this.setState({ result: 'fail' })
        });
    } else {
      this.setState({ result })
    }
  }

  render() {
    const { payment, result } = this.state;

    let button;
    switch(result) {
      case 'success':
        button = <h6 className="text-success font-weight-bold">결제 완료</h6>;
        break;
      case 'fail':
        button = <h6 className="text-danger font-weight-bold">결제 실패</h6>;
        break;
      case 'cancel':
        button = <h6 className="text-danger font-weight-bold">결제 취소</h6>;
        break;
      default:
        button = <h6 className="text-danger font-weight-bold">승인 요청</h6>;
        break;
    }

    return (
      <div className="m-2" style={{ maxWidth: '400px' }}>
        {payment &&
        <InfoCard className="infocard-primary" header="결제 내역">
          <ItemRow label="상품명">{payment.itemName}</ItemRow>
          <ItemRow label="가격">{priceFormat.format(payment.totalAmount)}</ItemRow>
          <div className="d-flex w-100 justify-content-center mt-5">
            {button}
          </div>
        </InfoCard>}
      </div>
    )
  }
}

export default KakaoPayResult;

import { merge } from '../utils'
import {
  ADD_PLACE,
  REMOVE_PLACE,
  UPDATE_PLACE,
  REQUEST_PLACES,
  RECEIVE_PLACES,
  ADD_PLACE_DEVICE,
  REMOVE_PLACE_DEVICE,
  SET_PLACE_FETCH_ERROR
} from '../actions/types';

const initialState = {
  /*
  isFetching: false,
  isInvalid: true,
  icon: -1,
   */
};

const places = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLACE:
      return {
        ...state,
          [action.uuid]: { devices: [] }
      };
    case REMOVE_PLACE: {
      let { ...rest } = state;
      delete rest[action.uuid];
      return rest;
    }
    case UPDATE_PLACE:
      return state;
    case REQUEST_PLACES:
      return {
        ...state,
        // isFetching: true,
        // isInvalid: false
      };
    case RECEIVE_PLACES:
      return {
        ...action.places,
        // isFetching: false,
        // isInvalid: false,
      };
    case ADD_PLACE_DEVICE: {
      let place = [ ...state[action.uuid]];
      place.devices.push(action.deviceUuid);
      return {
        ...state,
        [action.uuid]: place
      };
    }
    case REMOVE_PLACE_DEVICE: {
      let place = [ ...state[action.uuid]];
      place.devices.filter(dev => dev !== action.deviceUuid);
      return {
        ...state,
        [action.uuid]: place
      };
    }
    case SET_PLACE_FETCH_ERROR:
      return {
        ...state,
        // isFetching: false,
        // isInvalid: true
      };
    default:
      return state;
  }
};

export default places;

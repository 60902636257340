import React, {Component, Fragment} from 'react';
import r9iot from '../../api/r9iot';
import { InputRow } from '../../components/common';

export default class ThermometerSetting extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { principalId, device, onChange } = this.props;

    r9iot.getChildState(principalId, device.id)
      .then(response => {
        console.debug(response);
        if (response.status === 200) {
          const body = response.data;
          onChange({
            samplingTemperature:  body.mode.deltatemp,
            samplingHumidity:  body.mode.deltahumidity,
            periodicReportInterval:  body.mode.reporttime / 60,
            periodicReport: !!(body.mode.enable & 0x40)
          });
        }
      })
      .catch(error => console.error(error));
  }

  handleChange(e) {
    const { onChange } = this.props;

    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : (target.value == "" ? undefined : parseInt(target.value));

//    onChange({ [name]: parseInt(e.target.value) });
    onChange({ [name]: value });
  };

  render() {
    const { settings } = this.props;

    return (
      <Fragment>
        <InputRow label="샘플링 조건">
          <div className="row">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">온도</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  name="samplingTemperature"
                  value={settings.samplingTemperature || 0}
                  placeholder={`1 ~ 5`}
                  min={1}
                  max={5}
                  step={1}
                  onChange={this.handleChange}
                />
              </div>
              <div className="mb-3">
                <span className="input-hint">{`${settings.samplingTemperature}도 변할 때마다 현재 상태를 클아우드에 저장`}</span>
              </div>
            </div>
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">습도</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  name="samplingHumidity"
                  value={settings.samplingHumidity || 0}
                  placeholder={`1 ~ 10`}
                  min={1}
                  max={10}
                  step={1}
                  onChange={this.handleChange}
                />
              </div>
              <div className="mb-3">
                <span className="input-hint">{`${settings.samplingHumidity}% 변할 때마다 현재 상태를 클아우드에 저장`}</span>
              </div>
            </div>
          </div>
        </InputRow>
        <InputRow label="주기 보고">
          <div className="input-group mb-0">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <input
                  type="checkbox"
                  name="periodicReport"
                  checked={settings.periodicReport || false}
                  onChange={this.handleChange}
                />
              </div>
              <span className="input-group-text">간격(분)</span>
            </div>
            <input
              name="periodicReportInterval"
              type="number"
              className="form-control"
              value={settings.periodicReportInterval}
              min={5}
              max={720}
              step={5}
              onChange={this.handleChange}
            />
          </div>
          <div className="mb-3">
            <span className="input-hint">{`${settings.periodicReportInterval}분 간격으로 현재 상태를 클아우드에 저장`}</span>
          </div>
        </InputRow>
      </Fragment>
    )
  }
}

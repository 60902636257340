import { API } from 'aws-amplify';
import config from "../config";

const apiName = 'RaonixCloud';

function getHub(principalId, hubId) {
  let uri = `/r9iot/hubs/${hubId}`;

  return API.get(apiName, uri, {
    headers: {
      principalId:principalId
    },
    response: true
  });
}

function getHubDevices(principalId, hubId) {
  let uri = `/r9iot/hubs/${hubId}/devices`;

  return API.get(apiName, uri, {
    headers: {
      principalId:principalId
    },
    response: true,
  });
}

function getHubChildren(principalId, hubId) {
  let uri = `/r9iot/hubs/${hubId}/children`;

  return API.get(apiName, uri, {
    headers: {
      principalId:principalId
    },
    response: true,
  });
}

function deleteHubChildren(principalId, id, unpairing = true) {
  const encodedId = encodeURIComponent(id);
  const uri = `/r9iot/children/${encodedId}`;
  return API.del(apiName, uri, {
    headers: {
      principalId: principalId
    },
    queryStringParameters: {
      unpairing,
    },
    response: true
  });
}

function getChildState(principalId, id) {
  const encodedId = encodeURIComponent(id);
  let uri = `/r9iot/children/${encodedId}/state`;
  return API.get(apiName, uri, {
    headers: {
      principalId: principalId
    },
    response: true
  });
}

function setChildState(principalId, id, params) {
  const encodedId = encodeURIComponent(id);
  let uri = `/r9iot/children/${encodedId}/state`;
  return API.put(apiName, uri, {
    headers: {
      principalId
    },
    response: true,
    body: params
  });
}

export default {
  getHub,
  getHubDevices,
  getHubChildren,
  deleteHubChildren,
  getChildState,
  setChildState,
}

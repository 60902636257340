import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions";

import { sortbyNickname } from "../../utils";
import DeviceSelectBox from "../alarm/DeviceSelectBox";
import AlarmTable from "../alarm/AlarmTable";
import {
  InfoCard,
  InputRow,
  Toolbar,
  TablePagination,
  RangeSelect
} from "../../components/common";

import {
  queryRecordAlarmGet,
  queryRecordAlarmPut,
  queryRecordAlarmDelete,
  queryRecordAlarmCountGet
} from "../../api/record";

const PAGE_LINE  = 20;
const PAGE_GROUP  = 10;

const toolBar = [
  { name: 'delete', icon: 'icon-trash' },
  { name: 'check', icon: 'icon-check' },
  { name: 'refresh', icon: 'icon-cw' }
];

class AlarmList extends Component {
  constructor (props) {
    super(props);

    this.state = {
      checkbox: false,

      startDate: undefined,
      endDate: undefined,
      selectedUuid: undefined,

      alarms: [],

      // Table pagination
      selectedPage: 1,
      count: 0
    };

    this.handleToolbar = this.handleToolbar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeviceSelect = this.handleDeviceSelect.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
  }

  componentDidMount() {
    const { principalId } = this.props;
    const { startDate, endDate, selectedUuid, selectedPage } = this.state;

    this.props.actions.fetchRecordDevices(principalId);

    this.getAlarms(principalId, selectedUuid, startDate, endDate, selectedPage);
  }

  handleToolbar(e) {
    e.preventDefault();

    const { principalId } = this.props;
    const { alarms, startDate, endDate, selectedUuid, selectedPage } = this.state;
    //
    // event의 target은 span element을 return함
    //
    const name = e.currentTarget.name;

    switch (name) {
      case 'delete':
        let params = alarms.reduce((acc, alarm) => {
          if(alarm.selected) {
            acc.push(alarm.date);
          }
          return acc;
        }, []);
        queryRecordAlarmDelete(principalId, {}, params)
          .then(response => {
            this.getAlarms(principalId, selectedUuid, startDate, endDate, selectedPage);

            this.props.actions.fetchUncheckedAlarms(principalId);
          });
        break;
      case 'check':
        let ps = [];
        alarms.forEach(alarm => {
          if (alarm.selected) {
            let params = {
              date: alarm.date,
              checked: true
            };
            ps.push(queryRecordAlarmPut(principalId, params));
          }
        });

        Promise.all(ps).then(result => {
          this.getAlarms(principalId, selectedUuid, startDate, endDate, selectedPage);

          this.props.actions.fetchUncheckedAlarms(principalId);
        });
        break;
      case 'refresh':
        this.getAlarms(principalId, selectedUuid, startDate, endDate, selectedPage)
        break;
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleDeviceSelect(uuid) {
    const { principalId } = this.props;
    const { startDate, endDate, selectedPage } = this.state;

    if (uuid === 'all') {
      uuid = undefined;
    }

    this.setState({ selectedUuid: uuid });

    this.getAlarms(principalId, uuid, startDate, endDate, selectedPage);
  };

  handleStartChange(date) {
    const { principalId } = this.props;
    const { endDate, selectedUuid, selectedPage } = this.state;
    let startDate;

    if (date) {
      date.setHours(0,0,0,0);
      startDate = date.toISOString();
    } else {
      startDate = undefined;
    }

    this.setState({ startDate });

    this.getAlarms(principalId, selectedUuid, startDate, endDate, selectedPage);
  };

  handleEndChange(date) {
    const { principalId } = this.props;
    const { startDate, selectedUuid, selectedPage } = this.state;
    let endDate;

    if (date) {
      date.setHours(23, 59, 59, 999);
      endDate = date.toISOString();
    } else {
      endDate = undefined;
    }

    this.setState({ endDate });

    this.getAlarms(principalId, selectedUuid, startDate, endDate, selectedPage);
  }

  handlePageSelect = (selectedPage) => {
    const { principalId } = this.props;
    const { startDate, endDate, selectedUuid } = this.state;

    this.setState({ selectedPage });

    this.getAlarms(principalId, selectedUuid, startDate, endDate, selectedPage);
  }

  handleTableChange = (index, selected) => {
    // all alarms
    if (index < 0) {
      this.setState((state) => {
        let alarms = state.alarms.map(alarm => {
          alarm.selected = selected;
          return alarm;
        });
        return { alarms };
      });
    } else {
      this.setState((state) => {
        state.alarms[index].selected = selected;
        return { alarms: state.alarms };
      });
    }
  }

  getAlarms(principalId, uuid, start, end, page) {
    let query = {
      uuid: uuid,
      start: start,
      end: end
    };

    Object.keys(query).forEach(key => query[key] === undefined && delete query[key]);

    return queryRecordAlarmCountGet(principalId, query)
      .then(response => {
        const count = response.data;
        if (count > 0) {
          return queryRecordAlarmGet(principalId, {
            ...query,
            offset: (page - 1) * PAGE_LINE + 1,
            limit: PAGE_LINE,
          })
            .then(response => {
              const alarms = response.data;
              this.setState({ count, alarms });
            });
        } else {
          this.setState({ count, alarms: [] });
        }
      })
  }

  render () {
    const { records } = this.props;
    const { checkbox, selectedUuid, startDate, endDate, selectedPage, count, alarms } = this.state;

    let recordDevices = checkbox ? [ ...records.history, ...records.current ] : records.current;

    sortbyNickname(recordDevices);

    return (
      <div>
        <InfoCard className="mb-4" header="필터링">
          <InputRow label="Device">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text mr-0 form-check form-check-inline">
                  <input
                    name="checkbox"
                    className="form-check-input"
                    type="checkbox"
                    onChange={this.handleChange}
                  />
                  <label className="form-check-label">Include history</label>
                </div>
              </div>
              <DeviceSelectBox
                devices={recordDevices}
                selectedUuid={selectedUuid}
                onChange={this.handleDeviceSelect}
              />
            </div>
          </InputRow>
          <InputRow label="Date">
          <RangeSelect
            startDate={startDate}
            endDate={endDate}
            onStartChange={this.handleStartChange}
            onEndChange={this.handleEndChange}
          />
        </InputRow>
        </InfoCard>

        <React.Fragment>
          <Toolbar items={toolBar} onClick={this.handleToolbar}/>
          <AlarmTable
            alarms={alarms}
            onChange={this.handleTableChange}/>
          <TablePagination
            selectedPage={selectedPage}
            count={count}
            pageLine={PAGE_LINE}
            pageGroup={PAGE_GROUP}
            onPageSelect={this.handlePageSelect}/>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.devices,
    records: state.records
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlarmList);

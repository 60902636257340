/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React, { Component } from 'react';
import {connect} from "react-redux";
import * as Actions from "../../actions";
import { bindActionCreators } from "redux";
import {Dropdown, Modal} from "react-bootstrap";
import r9iot from "../../api/r9iot";
import DeviceResult from "../device/DeviceResult";
import {queryDeviceSubscribe, queryDeviceUnsubscribe} from "../../api/device";
import ConfirmModal from "../../components/common/ConfirmModal";
import DeviceAdd from "../device/DeviceAdd"

function SubscribeButton(props) {
  function handleClick(e) {
    e.preventDefault();
    const { hubId, child} = props;
    props.onClick(props.subscribed ? false : true, { hubId, ...child });
  }

  return (
    <button className="btn btn-text color-danger" onClick={handleClick}>
      {props.subscribed ? '구독해지' : '구독'}
    </button>
  );
}

function DeleteButton(props) {
  function handleClick(e) {
    e.preventDefault();
    const { hubId, child } = props;
    props.onClick({ hubId, ...child });
  }

  return (
    <button className="btn btn-text color-danger" onClick={handleClick}>디바이스 삭제</button>
  );
}

/* className={'toggle dropdown-toggle'} */
const DropdownToggle = React.forwardRef( ({ children, onClick }, ref) => (
    <div
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  )
);

class ChildrenList extends Component {
  constructor(props) {
    super(props);
  }

  handleSelect = (eventKey, event) => {
    console.debug(eventKey);
    let { hub } = this.props;
    switch(eventKey) {
      case 'delete':
        this.props.onUnsubscribeHub(hub);
        break;
    }
  };

  render() {
    let { hub, children, devices } = this.props;

    const compareName = (a, b) => {
      const na = a.name;
      const nb = b.name;
      return (na < nb) ? -1 : (na > nb) ? 1 : 0;
    };

    return (
      <React.Fragment>
        <div className="row col mt-3 mb-1 justify-content-between">
          <h6 className="font-weight-bold">{`${hub.nickname} (${hub.id})`}</h6>
          <Dropdown className="dropdown" onSelect={this.handleSelect}>
            <Dropdown.Toggle as={DropdownToggle}>
              <span className={'icon icon-dots-three-vertical'}/>
            </Dropdown.Toggle>
            <Dropdown.Menu align={"right"}>
              <Dropdown.Item eventKey={"delete"}>삭제</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ul className="list-group">
          { children && children.sort(compareName).map(child => {
            const subscribed = devices.hasOwnProperty(child.uuid);
            return (
              <li className="list-group-item" key={child.devid}>
                <div className="form-group row mb-0">
                  <label className="col-sm-2 col-form-label">{child.type}</label>
                  <label className="col-sm-3 col-form-label">{child.name}</label>
                  <label className="col-sm-3 col-form-label">{child.devid}</label>
                  <div className="col-sm-2 d-flex justify-content-end">
                    <SubscribeButton
                      name="subscribe"
                      hubId={hub.id}
                      child={child}
                      onClick={this.props.onSubscribe}
                      subscribed={subscribed}
                    />
                  </div>
                  <div className="col-sm-2 d-flex justify-content-end">
                    <DeleteButton
                      name="delete"
                      hubId={hub.id}
                      child={child}
                      onClick={this.props.onDelete}
                    />
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </React.Fragment>
    )
  }
}

class ChildDelete extends Component {
  constructor(props) {
    super(props);
  }

  handleApply = () => {
    this.props.onApply();
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>디바이스 삭제</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>허브에서 해당 디바이스가 삭제됩니다. 허브와 페어링 후 사용이 가능하며 모든 기록이 새롭게 관리됩니다.</span>
          <p className="color-danger mt-2 mb-0">삭제하시겠습니까?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={this.handleApply}>삭제</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

class HubManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDevice: false,
      showUnsubscribeHub: false,
      showDeleteChild: false,
      showResult: false
    };
  }

  componentDidMount() {
    const { user, devices } = this.props;

    Object.keys(devices).forEach((uuid) => {
      if (devices[uuid].type === "gateway") {
        let hubId = devices[uuid].id;
        this.getHubDevices(user.principalId, hubId)
          .catch(error=>console.error(error));
      }
    });
  }

  async getHubDevices(userUuid, hubId) {
    try {
      let res = await r9iot.getHubDevices(userUuid, hubId);
      let children = Object.keys(res.data).map(uuid => {
        return res.data[uuid];
      });

      this.setState({ [hubId]: children });
    } catch (err) {
      console.error(err);
    }
  }

  handleToolbar = (e) => {
    e.preventDefault();
    const { principalId } = this.props;
    const { selectedUuids } = this.state;
    //
    // event의 target은 span element을 return함
    //
    const name = e.currentTarget.name;

    switch (name) {
      case 'add':
        this.setState({ showAddDevice: true });
        break;
      case 'refresh':
        this.props.actions.fetchRecordDevices(principalId);
        break;
    }
  }

  handleApplyDeviceAdd = (hubId) => {
    const { principalId } = this.props;

    r9iot.getHub(principalId, hubId)
      .then(response => {
        const { uuid } = response.data;
        return queryDeviceSubscribe(principalId, uuid)
          .then(response => {
            if (response.status === 200) {
              this.showResultModal("네모안허브가 구독 되었습니다.", 2000);
              // this.props.actions.fetchDeviceList(principalId, uuid);
              this.props.actions.addDeviceAndFetchDevice(principalId, uuid);
              this.getHubDevices(principalId, hubId);
            } else {
              this.showResultModal("네모안허브 구독을 실패했습니다.", 2000);
            }
          })
          .catch(err => {
            console.error(err);
            this.showResultModal("네모안허브 구독을 실패했습니다.", 2000);
          });
      })
      .catch(err => {
        console.error(err);
        this.showResultModal("네모안허브 구독을 실패했습니다.", 2000);
      });

    this.setState({ showAddDevice: false });
  };

  handleCancelDeviceAdd = () => {
    this.setState({ showAddDevice: false });
  };


  handleChildSubscribe = (subscribe, device) => {
    const { principalId } = this.props;

    if (subscribe) {
      queryDeviceSubscribe(principalId, device.uuid)
        .then(response=> {
          if (response.status === 200) {
            return this.props.actions.addDeviceAndFetchDevice(principalId, device.uuid)
              .then(result => {
                this.showResultModal("디바이스가 구독 되었습니다.", 2000);
              });
          } else {
            this.showResultModal("디바이스 구독을 실패했습니다.", 2000);
          }
        })
        .catch(err => {
          console.error(err.response);
          this.showResultModal("디바이스 구독을 실패했습니다.", 2000);
        });
    } else {
      queryDeviceUnsubscribe(principalId, device.uuid)
        .then(result=>{
          this.props.actions.removeDevice(device.uuid);
          this.showResultModal("디바이스가 구독해지 되었습니다.", 2000);
        })
        .catch(err => {
          this.showResultModal("디바이스 구독해지를 실패했습니다.", 2000);
          console.error(err);
        });
    }

  };

  handleHubUnsubscribe = (hub) => {
    this.setState({
      showUnsubscribeHub: true,
      unsubhub: { ...hub },
    });
  }

  handleApplyHubUnsubscribe = async () => {
    const { principalId  } = this.props;
    const { unsubhub: hub } = this.state;

    try {
      let children = await r9iot.getHubDevices(principalId, hub.id)
        .then(result => {
          return Object.keys(result.data).map(
            uuid => result.data[uuid],
          );
        })

      for (let child of children) {
        console.info('child unsubscribe', child.uuid);
        //ps.push(queryDeviceUnsubscribe(principalId, child.uuid));
        await queryDeviceUnsubscribe(principalId, child.uuid)
          .catch(err => console.error('query unsub dev error : ', err));
      }

      await queryDeviceUnsubscribe(principalId, hub.uuid);

      // this.props.actions.fetchDeviceList(principalId);
      this.props.actions.removeDevice(hub.uuid);
      this.getHubDevices(principalId, hub.id);
    } catch(err) {
      console.log(err);
    }

    this.setState({ showUnsubscribeHub: false });
  };

  handleCancelHubUnsubscribe = () => {
    this.setState({ showUnsubscribeHub: false });
  };

  handleChildDelete = (child) => {
    this.setState({
      showDeleteChild: true,
      deleting: {
        ...child
      }
    });
  };

  handleApplyChildDelete = async () => {
    const { principalId } = this.props;
    const { deleting } = this.state;

    this.setState({ showDeleteChild: false });

    try {
      await r9iot.deleteHubChildren(principalId, deleting.id);
      this.showResultModal("디바이스가 삭제되었습니다.", 2000);
      this.props.actions.fetchDeviceList(principalId);
      this.getHubDevices(principalId, deleting.hubId)
        .catch(error=>console.error(error));
    } catch(err) {
      this.showResultModal("디바이스 삭제를 실패했습니다.", 2000);
      console.error(err);
    }
  };

  handleCancelChildDelete = () => {
    this.setState({ showDeleteChild: false });
  };

  handleResultClose = () => {
    this.setState({showResult: false});
  };

  showResultModal = (text, timeout) => {
    this.setState({
      showResult :true,
      showResultText :text
    });

    this.hideResultModal(timeout);
  };

  hideResultModal (msec) {
    setTimeout(()=>{
      this.setState({showResult: false});
    }, msec);
  }

  render() {
    const { principalId, devices } = this.props;
    const {
      showAddDevice,
      showUnsubscribeHub,
      showDeleteChild,
      showResult,
      showResultText,
      deleting,
      unsubhub,
    } = this.state;

    let hubs = Object.keys(devices).reduce((acc, uuid) => {
      let dev = devices[uuid];
      if (dev.type === "gateway") {
        acc.push(dev);
        return acc;
      }
      return acc;
    }, []);

    return (
      <React.Fragment>
        <div className="mt-4">
          {hubs.map(hub => {
              if (this.state) {
                return (
                  <ChildrenList
                    key={hub.id}
                    hub={hub}
                    principalId={principalId}
                    children={this.state[hub.id]}
                    devices={devices}
                    onUnsubscribeHub={this.handleHubUnsubscribe}
                    onSubscribe={this.handleChildSubscribe}
                    onDelete={this.handleChildDelete}
                  />
                )
              }
              return <></>;
            }
          )}

          {deleting && <ChildDelete
            show={showDeleteChild}
            onApply={this.handleApplyChildDelete}
            onCancel={this.handleCancelChildDelete}
          />}

          {/*
          <DeviceAdd
            title='디바이스 등록'
            principalId={principalId}
            show={showAddDevice}
            onApply={this.handleApplyDeviceAdd}
            onCancel={this.handleCancelDeviceAdd}
          />
          */}

          {unsubhub && <ConfirmModal
            buttonName={"삭제"}
            title={"허브 삭제"}
            show={showUnsubscribeHub}
            onApply={this.handleApplyHubUnsubscribe}
            onCancel={this.handleCancelHubUnsubscribe}
          >
            <p>
              허브를 삭제합니다.<br/>
              연결된 하위 디바이스들의 구독도 함께 해지됩니다.<br/>
              다시 연결하기 위해서는 허브 뒷면의 버튼을 누른 뒤 '장치추가'를 해야 됩니다.
              <br/>
              <br/>
              <b>
                삭제하시겠습니까?
              </b>
            </p>
          </ConfirmModal>}

          <DeviceResult
            show={showResult}
            text={showResultText}
            onClose={this.handleResultClose}
          />

        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    devices: state.devices
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HubManagement);

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
export default {
  default: {
    type: 'default',
    name: '기본',
  },
  strawberry: {
    type: 'strawberry',
    name: '딸기농장',
  },
};


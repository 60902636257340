// import './assets/css/toolkit-inverse.css'
import './assets/css/toolkit-light.css'
import './assets/css/application.css'

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import {AppContainer} from 'react-hot-loader';

import rootReducer from './reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer,
  composeEnhancer(
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(thunk)
//      : applyMiddleware(logger, thunk))
      : applyMiddleware(thunk))
);

/*
<AppContainer>
  <Provider store={store}>
    <App />
  </Provider>
</AppContainer>,
*/

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

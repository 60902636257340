/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React from 'react';
import DeviceItem from "./DeviceItem";
import DeviceTraits from "./DeviceTraits";
import r9iot from "../../api/r9iot";
import config from "../../config";

class Smartsensor extends React.Component {
  handleChange = (name, value) => {
    const { principalId, device } = this.props;
    let model;

    if (config.models.hasOwnProperty(device.modelCode)) {
      model = config.models[device.modelCode];
    }

    if (model.traitFilter.includes(name)) {
      let swidx = parseInt(name.match(/\d+/g));
      let key = name.match(/[a-zA-Z]+/g);

      const params = {
        switches: [
          {
            swidx,
            [key]: value
          }
        ]
      };
      r9iot.setChildState(principalId, device.id, params)
        .catch(error => console.error(error));
    }
  };

  render() {
    const { device } = this.props;

    return (
      <DeviceItem {...this.props}>
        <DeviceTraits
          device={device}
          onChange={this.handleChange}
        />
      </DeviceItem>
    );
  }
}

export default Smartsensor;

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import { queryGetPlaces, queryGetPlaceDevices } from '../api/place';

import {
  ADD_PLACE,
  REMOVE_PLACE,
  REQUEST_PLACES,
  RECEIVE_PLACES,
  ADD_PLACE_DEVICE,
  REMOVE_PLACE_DEVICE,

  SET_PLACE_FETCH_ERROR
} from './types';

export function addPlace(uuid){
  return {
    type: ADD_PLACE,
    uuid
  }
}

export function removePlace(uuid){
  return {
    type: REMOVE_PLACE,
    uuid
  }
}
export function requestPlaces() {
  return {
    type: REQUEST_PLACES
  };
}

export function receivePlaces(data) {
  return {
    type: RECEIVE_PLACES,
    places: {...data}
  };
}

export function addPlaceDevice(uuid, deviceUuid) {
  return {
    type: ADD_PLACE_DEVICE,
    uuid,
    deviceUuid
  }
}

export function removePlaceDevice(uuid, deviceUuid) {
  return {
    type: REMOVE_PLACE_DEVICE,
    uuid,
    deviceUuid
  }
}

export function setFetchError() {
  return {
    type: SET_PLACE_FETCH_ERROR
  };
}

export function fetchPlaces(userUuid) {
  return async (dispatch, getState) => {
    dispatch(requestPlaces());

    let res;
    let places = {};
    try {
      res = await queryGetPlaces(userUuid);
      places = { ...res.data };

      for (let uuid in places) {
        let place = places[uuid];
        res = await queryGetPlaceDevices(userUuid, uuid);
        if (res.status === 200) {
          place.devices = [ ...res.data ];
        } else {
          place.devices = [];
        }
      }

      dispatch(receivePlaces(places));
      return getState();
    } catch(err) {
      console.error(err);
      dispatch(setFetchError());
    }
  };
}

export function addPlaceAndFetchPlaces(userUuid, uuid) {
  return (dispatch, getState) => {
    dispatch(addPlace(uuid));
    return dispatch(fetchPlaces(userUuid, uuid));
  };
}

export function removePlaceAndFetchPlaces(userUuid, uuid, deviceUuid) {
  return (dispatch, getState) => {
    dispatch(removePlace(uuid));
    return dispatch(fetchPlaces(userUuid, uuid));
  };
}

export function addPlaceDeviceAndFetchPlaces(userUuid, uuid, deviceUuid) {
  return (dispatch, getState) => {
    dispatch(addPlaceDevice(uuid, deviceUuid));
    return dispatch(fetchPlaces(userUuid, uuid));
  };
}

export function removePlaceDeviceAndFetchPlaces(userUuid, uuid, deviceUuid) {
  return (dispatch, getState) => {
    dispatch(removePlaceDevice(uuid, deviceUuid));
    return dispatch(fetchPlaces(userUuid, uuid));
  };
}

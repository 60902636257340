/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React from 'react';
import {Modal} from "react-bootstrap";
import PlacesAutocomplete, {geocodeByPlaceId, getLatLng} from "../../PlacesAutocomplete";
import {InputRow} from "../../components/common";
import {APPLICATIONS, DEVICES} from '../../constants';

export const classnames = (...args) => {
  const classes = [];
  args.forEach(arg => {
    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (typeof arg === 'object' && arg !== null) {
      Object.keys(arg).forEach(key => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    } else {
      throw new Error(
        '`classnames` only accepts string or object as arguments'
      );
    }
  });

  return classes.join(' ');
};

export default class PlaceAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saveButtonDisabled: true,
      nickname: '',
      address: '',
      placeId: '',
      longitude: null,
      application: 'default',
      errorMessage: '',
      isGeocoding: false,
    };
  }

  componentDidMount() {
    /*
    this.applications = [];
    Object.keys(APPLICATIONS).map(key => {
      this.applications.push(
        <Picker.Item
          key={APPLICATIONS[key].name+APPLICATIONS[key].type}
          label={APPLICATIONS[key].name}
          value={APPLICATIONS[key].type}
        />,
      );
    });
     */
  }

  handleApply = () => {
    const {
      nickname,
      application,
      placeId,
      address,
      latitude,
      longitude,
    } = this.state;

    this.props.onApply({
      nickname,
      application,
      placeId,
      address,
      latitude,
      longitude,
    });
    this.setState({
      nickname: '',
      application: '',
      address: '',
    });
  };

  handleCancel = () => {
    this.props.onCancel();
    this.setState({
      nickname: '',
      address: ''
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [name]: value });
  };

  handlePlaceChange = (address) => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    });
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    });
  };

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  handleSelect = (address, placeId) => {
    this.setState({ isGeocoding: true, address: address });
    // geocodeByAddress(address)
    geocodeByPlaceId(placeId)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.setState({
          placeId,
          address,
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        });
      })
      .catch(error => {
        this.setState({ isGeocoding: false });
        console.log('error', error); // eslint-disable-line no-console
      });
  };

  render() {
    const modalTitle = this.props.title;
    const {
      nickname,
      address,
      errorMessage
    } = this.state;

    return (
      <Modal
        show={this.props.show}
        size="lg"
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputRow label="이름">
            <input
              name="nickname"
              type="text"
              className="form-control"
              placeholder ="장소 이름"
              value={nickname}
              onChange={this.handleChange}
            />
          </InputRow>
          <InputRow label="유형">
            <select
              className="form-control"
              name="application"
              aria-label="select place application"
              onChange={this.handleChange}
            >
              {
                Object.keys(APPLICATIONS).map(key => (
                  <option key={APPLICATIONS[key].type} value={APPLICATIONS[key].type}>
                    {APPLICATIONS[key].name}
                  </option>
                ))
              }
            </select>
          </InputRow>
          <InputRow label="주소">
            <PlacesAutocomplete
              searchOptions={{ types: ['geocode']}}
              onChange={this.handlePlaceChange}
              value={address}
              onSelect={this.handleSelect}
              onError={this.handleError}
              shouldFetchSuggestions={address.length > 2}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                return (
                  <React.Fragment>
                    <div className="place-search-input-container">
                      <input
                        {...getInputProps({
                          placeholder: '주소 검색',
                          className: 'form-control',
                        })}
                      />
                      {this.state.address.length > 0 && (
                        <button
                          className="place-clear-button"
                          onClick={this.handleCloseClick}
                        >
                          x
                        </button>
                      )}
                    </div>
                    {suggestions.length > 0 && (
                      <div className="place-autocomplete-container">
                        {suggestions.map((suggestion, index) => {
                          const className = classnames('place-suggestion-item', {
                            'place-suggestion-item--active': suggestion.active,
                          });

                          return (
                            /* eslint-disable react/jsx-key */
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, { className })}
                            >
                              <strong>
                                {suggestion.formattedSuggestion.mainText}
                              </strong>{' '}
                              <small>
                                {suggestion.formattedSuggestion.secondaryText}
                              </small>
                            </div>
                          );
                          /* eslint-enable react/jsx-key */
                        })}
                        <div className="place-dropdown-footer">
                          <div>
                            <h6>powered by Google</h6>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              }}
            </PlacesAutocomplete>
          </InputRow>
          {errorMessage.length > 0 && (
            <div className="place-error-message">{this.state.errorMessage}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleApply}>추가</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

PlaceAdd.defaultProps = {
  place: {
    nickname: '',
    address: ''
  }
};

import React, { useEffect } from 'react';
import { Hub } from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { queryUserPost, queryUserGet} from "../../api/user";

import {Auth} from "aws-amplify";

const OAuthCallback1 = (props) => {
  const { location, history } = props;

  useEffect(async () => {

    const { code, state, ...rest } = queryString.parse(location.search);

    // const code = (location.search.match(/code=([^&]+)/) || [])[1];
    // const state = (location.search.match(/state=([^&]+)/) || [])[1];
    var details = {
      code,
      client_id: 'nemoahn-cloud',
      client_secret: 'Raon#3ix09',
      grant_type: 'authorization_code',
      redirect_uri: 'http://localhost:3000/oauth-callback1',
      scope: 'openid profile email',
      code_verifier: '123456789012345678901234567890123456789012345',
    };

    try {

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      let res = await fetch(`https://dev-lb.raonix.com/token?`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody
      })
        .then(res => res.json());

      const { access_token, id_token, expires_in } = res;
      // let decoded = jwtDecode(access_token);
      const {exp, sub, aud} = jwtDecode(id_token);

      let userinfo = await fetch('https://dev-lb.raonix.com/userinfo', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
        .then(res => res.json());

      await Auth.federatedSignIn(
        'dev-lb.raonix.com',
        {
          token: id_token,
          // identity_id, // Optional
          expires_at: exp * 1000 + new Date().getTime() // the expiration timestamp
        },
        // { email: sub }
        {username: sub, ...userinfo}
      ).then(async cred => {
        // If success, you will get the AWS credentials
        console.log(cred);
        return await Auth.currentAuthenticatedUser();
      })
        .then(user => {
          /**
           *
           *  federated시 raonix_cloud.users DB에 계정 생성 방법에 대해서 다시 체크!!!
           *
           */
          // If success, the user object you passed in Auth.federatedSignIn
          console.log(user);

          return queryUserGet(user.id)
            .catch(err => {
              if (err.response.status == 404) {
                return queryUserPost(user.id, {
                  uuid: user.id,
                  id: user.username,
                  type: 'user',
                  aud,
                });
              }
            });
        })
        .then(() => {
          history.push('/');
        })
    } catch(err) {
      console.error(err);
    }

    /*
    fetch(`https://dev-lb.raonix.com/token?`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody
    })
      .then(res => res.json())
      .then(async (res) => {
        console.log(res);
        const { access_token, id_token, expires_at } = res;
        // let decoded = jwtDecode(access_token);
        // console.log(decoded);

        // const {name, email, exp} = jwtDecode(id_token);
        console.log(jwtDecode(id_token));
        const {name, email, exp, sub} = jwtDecode(id_token);

        await fetch('https://dev-lb.raonix.com/userinfo', {
          method: 'GET',
            credentials: 'include',
            headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        })
          .then(res => res.json())
          .then(async (res) => {
            console.log(res);
          })
          .catch(err => {
            console.error(err);
          });

        Auth.federatedSignIn(
          'dev-lb.raonix.com',
          {
            token: id_token,
            // identity_id, // Optional
            expires_at: exp * 1000 + new Date().getTime() // the expiration timestamp
          },
          { email: sub }
        ).then(async cred => {
          // If success, you will get the AWS credentials
          console.log(cred);

          return Auth.currentAuthenticatedUser();
        }).then(user => {
          // If success, the user object you passed in Auth.federatedSignIn
          console.log(user);
          history.replace('/');
        }).catch(e => {
          console.log(e)
        });
      })
      .catch((err) => {
        console.error(err);
      });
    */

  }, []);

  return (
    <p>
      {location.search}
    </p>
  );
}

export default OAuthCallback1;

import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions";
import { Logger } from "aws-amplify";
import {
  queryPutReport,
  queryGetReport,
  queryReportRecords,
  queryDeleteReport
} from "../../api/report";
import { queryRecordDevices } from "../../api/record";
import ReportItem from "../report/ReportItem";
import ReportSetting from '../report/ReportSetting';
import ReportWritePdf from '../report/ReportWritePdf';
import ReportPeriodic from '../report/ReportPeriodic';
import ReportResult from '../report/ReportResult';
import ConfirmModal from "../../components/common/ConfirmModal";

const logger = new Logger('SignOut');

class ReportChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recordedDevices: [],
      showAdd: false,
      showModify: false,
      showPeriodicCreate: false,
      showDelete: false,
      showCreatePdf: false,
      showResult: false,
    };
  }

  componentDidMount() {
    console.debug('>>>>>>>>>>>>>>> componentDidMount');
    const { location, principalId, devices } = this.props;

    const { reportUuid } = location.state;
    let report;

    queryGetReport(principalId, reportUuid)
      .then(response => {
        if (response.status === 200) {
          report = { uuid: reportUuid, ...response.data };
          queryReportRecords(principalId, report)
            .then(records => {
              report.records = records;
            })
            .catch(err => {
              logger.error(err);
              report.records = {};
            })
            .finally(() => {
              this.setState({ report });
            })
        }
      })
      .catch(err => logger.error(err));

    queryRecordDevices(principalId)
      .then(response => {
        let recordedDevices = Object.values(response.data);
        recordedDevices.forEach(recordDev => {
          recordDev.history = devices.hasOwnProperty(recordDev.uuid);
        });
        recordedDevices.sort((a, b) => {
          return (a.type < b.type) ? 1 : (a.type > b.type) ? -1 : 0;
        });
        this.setState({ recordedDevices });
      })
      .catch(err => logger.error(err));
  }

  handleDelete = (report) => {
    this.setState({
      showDelete: true,
      deleting: {
        ...report
      }
    });
  };

  handleCreatePdf = (report) => {
    this.setState({
      showCreatePdf: true,
      preview: {
        ...report
      }
    });
  };

  handleModify = (report) => {
    this.setState({
      showModify: true,
      setting: {
        ...report
      }
    });
  };

  handleModifyApply = (params) => {
    const { principalId } = this.props;
    const { setting } = this.state;

    queryPutReport(principalId, setting.uuid, params)
      .then(response=>{
        if (response.status === 200) {
          this.showResultModal("리포트가 수정 되었습니다.", 2000);

          let report;
          queryGetReport(principalId, setting.uuid)
            .then(response => {
              if (response.status === 200) {
                report = { uuid: setting.uuid, ...response.data };
                queryReportRecords(principalId, report)
                  .then(records => {
                    report.records = records;
                    this.setState({ report });
                  });
              }
            })
            .catch(err => {console.error(err);});
        }
      })
      .catch(err => {console.error(err);});

    this.setState({
      showModify: false,
      setting: undefined
    });
  };

  handleModifyCancel = () => {
    this.setState({
      showModify: false,
      setting: undefined
    });
  };

  handlePeriodicCreate = (report) => {
    this.setState({
      showPeriodicCreate: true,
      setting: {
        ...report
      }
    });
  };

  handlePeriodicCreateApply = (params) => {
    const { principalId } = this.props;
    const { setting } = this.state;

    queryPutReport(principalId, setting.uuid, params)
      .then(resp => {
        if (resp.status === 200) {
          this.showResultModal("자동리포트가 수정되었습니다.", 2000);
          this.setState(state => ({ report: {...state.report, ...resp.data}}));
        }
      })
      .catch(err => {console.error(err);});

    this.setState({
      showPeriodicCreate: false,
      setting: undefined
    });
  };

  handlePeriodicCreateCancel = () => {
    this.setState({
      showPeriodicCreate: false,
      setting: undefined
    });
  };

  handleApplyDeleteReport = () => {
    const { principalId } = this.props;
    const { deleting } = this.state;

    queryDeleteReport(principalId, deleting.uuid)
      .then(response => {
        this.showResultModal("리포트가 삭제 되었습니다.", 2000);
        this.props.actions.removeReport(deleting.uuid);
        this.props.history.goBack();
      })
      .catch(err => {console.error(err);});

    this.setState({showDelete: false});
  };

  handleCancelDeleteReport = () => {
    this.setState({showDelete: false});
  };

  handleCreatePdfClose = () => {
    this.setState({showCreatePdf: false});
  };

  handleResultClose = () => {
    this.setState({showResult: false});
  };

  showResultModal = (text, timeout) => {
    this.setState({
      showResult :true,
      showResultText :text
    });

    this.hideResultModal(timeout);
  };

  hideResultModal (msec) {
    setTimeout(()=>{
      this.setState({showResult: false});
    }, msec);
  }

  render () {
    const { principalId, user } = this.props;
    const {
      report,
      recordedDevices,
      showModify,
      showDelete,
      showPeriodicCreate,
      setting,
      deleting,
      preview
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            {report && <ReportItem
              key={report.uuid}
              uuid={report.uuid}
              devices={recordedDevices}
              report={report}
              principalId={principalId}
              onModify={this.handleModify}
              onPeriodicCreate={this.handlePeriodicCreate}
              onDelete={this.handleDelete}
              onCreatePdf={this.handleCreatePdf}
            />}
          </div>
        </div>

        {setting && <ReportSetting
          title='리포트 설정'
          principalId={principalId}
          devices={recordedDevices}
          report={setting}
          show={showModify}
          onApply={this.handleModifyApply}
          onCancel={this.handleModifyCancel}
        />}

        {setting && <ReportPeriodic
          title='자동 리포트'
          principalId={principalId}
          timeZone = {user.timeZone}
          report={setting}
          show={showPeriodicCreate}
          onApply={this.handlePeriodicCreateApply}
          onCancel={this.handlePeriodicCreateCancel}
        />}

        {deleting && <ConfirmModal
          buttonName={"삭제"}
          title={"리포트 삭제"}
          show={showDelete}
          onApply={this.handleApplyDeleteReport}
          onCancel={this.handleCancelDeleteReport}
        >
          <p>리포트를 삭제합니다.</p>
        </ConfirmModal>}

        {preview && <ReportWritePdf
          show={this.state.showCreatePdf}
          principalId={principalId}
          preview={preview}
          onClose={this.handleCreatePdfClose}
        />}

        <ReportResult
          show={this.state.showResult}
          text={this.state.showResultText}
          onClose={this.handleResultClose}
        />

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    reports: state.reports,
    devices: state.devices
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportChart);

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions";

import DeviceHistoryTable from "../device/DeviceHistoryTable";
import { Toolbar, TablePagination } from "../../components/common";
import {
  queryRecordDeviceUsersDelete
} from "../../api/record";

const PAGE_LINE  = 20;
const PAGE_GROUP  = 10;
const toolBar = [
  { name: 'delete', icon: 'icon-trash' },
  { name: 'refresh', icon: 'icon-cw' }
];

class DeviceHistory extends Component {
  constructor (props) {
    super(props);

    this.state = {
      checkbox: false,
      selectedUuids: [],

      selectedPage: 1,
      count: 0,
    };

    this.handleToolbar = this.handleToolbar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleTableMenu = this.handleTableMenu.bind(this);
  }

  componentDidMount() {
    const { principalId } = this.props;
    this.props.actions.fetchRecordDevices(principalId);
  }

  handleToolbar(e) {
    e.preventDefault();
    const { principalId } = this.props;
    const { selectedUuids } = this.state;
    //
    // event의 target은 span element을 return함
    //
    const name = e.currentTarget.name;

    switch (name) {
      case 'delete':
        let ps = [];
        selectedUuids.forEach(uuid => {
          ps.push(queryRecordDeviceUsersDelete(principalId, uuid)
            .catch(error => console.error(error)));
        });
        Promise.all(ps).then(() => {
          this.props.actions.fetchRecordDevices(principalId);
        });
        break;
      case 'refresh':
        this.props.actions.fetchRecordDevices(principalId);
        break;
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handlePageSelect(selectedPage) {
    this.setState({ selectedPage });
  }

  handleTableChange(index, selected) {
    const { records } = this.props;

    if (index < 0) {
      if (selected) {
        this.setState((state) => {
          let selectedUuids = records.history.map(device => {
            return device.uuid;
          });
          return { selectedUuids };
        });
      } else {
        this.setState({ selectedUuids: [] });
      }
    } else {
      this.setState((state) => {
        let selectedUuids = state.selectedUuids;
        let device = records.history[index];
        let idx = selectedUuids.indexOf(device.uuid);
        if (selected) {
          (idx === -1) && selectedUuids.push(device.uuid);
        } else {
          if (idx !== -1) {
            selectedUuids.splice(idx, 1);
          }
        }

        return { selectedUuids };
      });
    }
  }

  handleTableMenu(key) {
    switch (key) {
      case 'delete':
        break;
      case 'check':
        break;
    }
  };

  render () {
    const { records } = this.props;
    const { selectedUuids, selectedPage, count } = this.state;

    return (
      <React.Fragment>
        <Toolbar items={toolBar} onClick={this.handleToolbar}/>
        {records &&
        <DeviceHistoryTable
          devices={records.history}
          selectedUuids={selectedUuids}
          onChange={this.handleTableChange}
          onMenu={this.handleTableMenu}/>}
        {count > PAGE_LINE &&
        <TablePagination
          selectedPage={selectedPage}
          totalLine={count}
          pageLine={PAGE_LINE}
          pageGroup={PAGE_GROUP}
          onPageSelect={this.handlePageSelect}
        />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.devices,
    records: state.records
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceHistory);

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import { API } from 'aws-amplify';
import config from '../config';

const apiName = 'RaonixCloud';

/**
 *   device
 */
export function queryGetDevice(principalId, uuid) {
  let path = `/devices/${uuid}`;
  return API.get(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    response: true
  });
}

export function queryPutDeviceSetting (principalId, uuid, params) {
  let path = `/devices/${uuid}`;
  return API.put(apiName, path, {
    headers: {
      principalId
    },
    response: true,
    body: params

  });
}

export function queryDevices(principalId) {
  let path = '/devices';
  return API.get(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    }
  });
}

export function queryDeleteDevice (principalId, deviceUuid) {
  let path = `/devices/${deviceUuid}`;
  return API.del(apiName, path, {
    headers: {
      principalId
    },
    response: true,
  });
}

/**
 *  user
 */
export function queryDeviceSubscribe (principalId, deviceUuid, userId = '') {
  let path = `/devices/${deviceUuid}/users`;

  return API.post(apiName, path, {
    headers: {
      principalId
    },
    body: {
      userId: userId
    },
    response: true
  });
}

export function queryDeviceUnsubscribe (principalId, deviceUuid) {
  let path = `/devices/${deviceUuid}/users`;
  return API.del(apiName, path, {
    headers: {
      principalId
    },
    response: true,
  });
}

/**
 *   trait
 */
export function queryDeviceTraits(principalId, deviceUuid) {
  let path = `/devices/${deviceUuid}/traits`;
  return API.get(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    response: true,
  });
}

export function queryDeviceControl(principalId, deviceUuid, settings) {
  let path = `/devices/${deviceUuid}/traits`;

  return API.put(apiName, path, {
    headers: {
      principalId
    },
    body:settings
  })
    .then(result => {
      return result;
    })
    .catch(error => {
      console.error(`error: ${JSON.stringify(error)}`);
      return error;
    })
}

/**
 *   Place
 */
export function queryGetPlace(principalId, uuid) {
  let path = `/devices/${uuid}/place`;
  return API.get(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    response: true
  });
}

export function queryPutPlace (principalId, uuid, params) {
  let path = `/devices/${uuid}/place`;
  return API.put(apiName, path, {
    headers: {
      principalId
    },
    response: true,
    body: params
  });
}


import React, { Component } from 'react';

import PdfPreview from '../report/PdfPreview';
import { queryWriteReport } from '../../api/report'

class ReportWritePdf extends Component {
  constructor(props) {
    super(props);

    this.handlePreviewClose = this.handlePreviewClose.bind(this);

    this.state = {
      file: undefined
    }
  }

  componentDidMount() {
    const { principalId, preview } = this.props;

    queryWriteReport(principalId, preview.uuid, preview.writer,
      {
        type: 'custom',
        title: preview.title,
        start: preview.startDate,
        end: preview.endDate,
        step: 1
      })
      .then(res => {
        this.setState({ready: true, file: res.data });
      })
      .catch(err => {
        console.error(err);
      })
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { principalId, preview } = this.props;

    if (preview !== prevProps.preview) {
      queryWriteReport(principalId, preview.uuid, preview.writer,
        {
          type: 'custom',
          title: preview.title,
          start: preview.startDate,
          end: preview.endDate,
          step: 1
        })
        .then(res => {
          this.setState({ready: true, file: res.data });
        })
        .catch(err => {
          console.error(err);
        })
    }
  }

  handlePreviewClose() {
    this.props.onClose(false);
  };

  render() {
    const { show, preview } = this.props;
    const { file } = this.state;

    return (
      <PdfPreview
        title={preview.title}
        file={file}
        show={show}
        onClose={this.handlePreviewClose}
      />
    )
  }
}

export default ReportWritePdf;

import {
  RECEIVE_RECORD_DEVICES,
} from "../actions/types";

const records = (state = { history: [], current: [] }, action) => {
  switch(action.type) {
    case RECEIVE_RECORD_DEVICES: {
      return {
        ...state,
        ...action.records
      }
    }
    default:
      return state;
  }
};

export default records;

import React, { Component } from 'react';

import { queryTraitGetAlarm } from "../../api/alarm";
import config from "../../config";

const SUPPORTED_TRAIT_TYPE = {
  levelSensor: true,
  levelSwitch: true,
  binarySensor: false,
  binarySwitch: false
};

class DeviceTraitsAlarm extends Component {
  constructor (props) {
    super(props);
  }

  componentDidMount() {
    const { device, principalId, onChange } = this.props;

    let model = config.models[device.modelCode];
    Object.keys(device.traits).map(uuid => {
      let tr = device.traits[uuid];

//      if (SUPPORTED_TRAIT_TYPE[tr.type] && trait.record == true) {
      if (model.alarmFilter.includes(tr.name)) {
        queryTraitGetAlarm(principalId, uuid)
          .then(response => {
            let alarm = response.data;

            onChange(uuid, alarm);
          })
          .catch(err => {
            console.error(err);
          });
      }
    });
  }

  handleChange(uuid, e) {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    const { alarms, onChange } = this.props;

    let alarm = alarms[uuid];

    if (name === 'min' || name === 'max') {
      if (alarm.alarmCondition) {
        alarm.alarmCondition[name] = parseInt(value);
      } else {
        alarm.alarmCondition = {
          [name]: parseInt(value)
        }
      }
      onChange(uuid, alarm);
    } else {
      alarm[name] = value;
      onChange(uuid, alarm);
    }
  };

  render() {
    const { device, alarms } = this.props;
    let model;
    let sortTraits = [];

    if (config.models.hasOwnProperty(device.modelCode)) {
      model = config.models[device.modelCode];
    }

    for (let uuid in device.traits) {
      let tr = device.traits[uuid];

      if (model) {
        if (model.alarmFilter.includes(tr.name)) {
          sortTraits.push({
            uuid,
            ...tr
          });
        }
      } else {
        sortTraits.push({
          uuid,
          ...tr
        });
      }
    }

    const compareNickname = (a, b) => {
      const na = a.nickname;
      const nb = b.nickname;
      return (na < nb) ? -1 : (na > nb) ? 1 : 0;
    };

    sortTraits.sort(compareNickname);

    return (
      <ul className="list-group">
        {/* {traits && Object.keys(traits).map(uuid => { */}
        {sortTraits.map(trait => {
          let alarm = alarms[trait.uuid];
          if (alarm && SUPPORTED_TRAIT_TYPE[trait.type] && trait.record == true) {
            return (
              <li key={trait.uuid} className="list-group-item">
                <div className="row">
                  <div className="col-1 d-flex">
                    <input
                      className="align-self-center"
                      type="checkbox"
                      name="alarm"
                      checked={alarm.alarm}
                      onChange={this.handleChange.bind(this, trait.uuid)}
                    />
                  </div>
                  <label className="col-2 col-form-label">{trait.nickname}</label>
                  <div className="col">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">min</span>
                      </div>
                      <input
                        disabled={!alarm.alarm}
                        type="number"
                        className="form-control"
                        name="min"
                        value={alarm.alarmCondition && alarm.alarmCondition.min}
                        placeholder={`${trait.min} ~ ${trait.max}`}
                        min={trait.min}
                        max={trait.max}
                        step={trait.step}
                        onChange={this.handleChange.bind(this, trait.uuid)}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">max</span>
                      </div>
                      <input
                        disabled={!alarm.alarm}
                        type="number"
                        className="form-control"
                        name="max"
                        value={alarm.alarmCondition && alarm.alarmCondition.max}
                        placeholder={`${trait.min} ~ ${trait.max}`}
                        min={trait.min}
                        max={trait.max}
                        step={trait.step}
                        onChange={this.handleChange.bind(this, trait.uuid)}
                      />
                    </div>
                  </div>
                </div>
              </li>
            )
          }
        })}
      </ul>
    )
  }
}
export default DeviceTraitsAlarm;

import React from 'react';
import ReactSwitch from "react-switch"
import TraitItem from "./TraitItem";

class BinarySwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  handleChange = (value) => {
    const { name } = this.props;
    this.setState({ value });
    this.props.onChange(name, value);
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value });
    }
  }

  render() {
    const { label, disabled } = this.props;
    return (
      <ReactSwitch
        checked={this.state.value}
        disabled={disabled}
        onChange={this.handleChange}
      />
    );
  }
}

export default BinarySwitch;

import {
  RECEIVE_REPORT,
  REMOVE_REPORT,
  REMOVE_REPORT_ALL,
  RECEIVE_REPORT_RECORDS
} from "../actions/types";

const reports = (state = {}, action) => {
  switch(action.type) {
    case RECEIVE_REPORT: {
      /*
      let newState = {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          ...action.data
        }
      };
      return newState;
      */
      return {
        ...state,
        [action.uuid]: action.data
      }
    }
    case REMOVE_REPORT:
      let {...rest}=state;
      delete rest[action.uuid];
      return rest;
    case REMOVE_REPORT_ALL:
      return {};

    case RECEIVE_REPORT_RECORDS: {
      let newState = {
        ...state
      };
      newState[action.uuid].records = action.data;
      console.debug('state: ', state);
      console.debug('newState: ', newState);
      return  newState;
    }
    default:
      return state;
  }
};

export default reports;

import React, {Component} from "react";
import {Modal} from "react-bootstrap";

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
  }

  handleApply = () => {
    this.props.onApply();
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const {title, buttonName = '확인', children} = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={this.handleApply}>{buttonName}</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ConfirmModal;

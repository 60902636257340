import { API } from 'aws-amplify';
import config from "../config";

const apiName = 'RaonixCloud';

export function queryPaymentSubscriptionGet(principalId) {
  let path = '/kakao/payment/subscription';

  return API.get(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId
    },
    response: true
  });
}

export function queryPaymentReadyPost (principalId, item) {
  let path = "/kakao/payment/ready";

  return API.post(apiName, path, {
    headers: {
      principalId: principalId
    },
    queryStringParameters: {
      item
    },
    response: true,
  });
}

export function queryPaymentReadyGet(uuid) {
  let path = '/kakao/payment/ready/' + uuid;

  return API.get(apiName, path, {
    queryStringParameters: {
    },
    headers: {
      principalId: ""
    },
    response: true
  });
}

export function queryPaymentApprovePost (json) {
  let path = "/kakao/payment/approve";

  return API.post(apiName, path, {
    headers: {
      principalId: ""
    },
    response: true,
    body: {
      ...json
    }
  });
}

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

import React from 'react';
import { Line } from "react-chartjs-2";

const temperatureAxis = {
  id: 'y-axis-temperature',
  gridLines: {
    borderDash: [],
    zeroLineColor: 'rgba(0, 0, 0, 1)',
  },
  scaleLabel: {
    display: true,
    labelString: '온도',
  },
  ticks: {
    suggestedMin: 0,
    suggestedMax: 40
  }
};
const humidityAxis =  {
  id: 'y-axis-humidity',
  gridLines: {
    borderDash: [5, 5],
    zeroLineColor: 'rgba(0, 0, 0, 1)',
  },
  scaleLabel: {
    display: true,
    labelString: '습도',
  },
  ticks: {
    suggestedMin: 0,
    suggestedMax: 100
  }
};

const OPTION_DEFAULT = {
  responsive: true,
  legend: {
    position: 'top',
  },

  scales: {
    xAxes: [
      {
        id: 'x-axis-1',
        type: 'time',
        time: {
          displayFormats: {
            hour: 'D일 HH시',
            day: 'M월D일',
            year: 'YY년M월',
          }
        },
        offset: true,
        gridLines: {
          zeroLineColor: 'rgba(0, 0, 0, 1)',
        },
        scaleLabel: {
          display: true,
          labelString: '시간',
        }
      }
    ],
    yAxes: []
  }
};

export default function ThermometerChart(props) {
  const { data, type } = props;

  // shallow copy 하면 "Cannot read property 'getBasePixel' of undefined..." 에라 발생
  // deep copy를 해야 함.
  let options = JSON.parse(JSON.stringify(OPTION_DEFAULT));
  options.scales.yAxes = [];

  // TODO: add chart
  switch(type) {
    case 'ThermometerTemperatureHumidity':
      options.scales.yAxes.push({ ...temperatureAxis, position: 'left' });
      options.scales.yAxes.push({ ...humidityAxis, position: 'right' });
      break;
    case 'ThermometerTemperature':
      options.scales.yAxes.push({ ...temperatureAxis, position: 'left' });
      break;
    case 'ThermometerHumidity':
      options.scales.yAxes.push({ ...humidityAxis, position: 'left' });
      break;
    default:
      options.scales.yAxes.push({ ...temperatureAxis, position: 'left' });
      break;
  }

  return <Line data={data} options={options}/>;
}

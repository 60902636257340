/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React, {Component} from 'react';
import TraitItem from "./TraitItem";
import Slider from "react-rangeslider";

import BinarySensor from './BinarySensor';
import BinarySwitch from './BinarySwitch';
import LevelSensor from './LevelSensor';
import LevelSwitch from './LevelSwitch';
import config from "../../config";

function Trait(props) {
  const { trait, onChange } = props;

  if (trait.currentValue === undefined) {
    return null;
  }

  switch (trait.type) {
    case "binarySensor":
      return <BinarySensor value={trait.currentValue}/>;
    case "binarySwitch":
      return (
        <BinarySwitch
          name={trait.name}
          value={trait.currentValue}
          onChange={onChange}
        />
      );
    case "levelSensor":
      return <LevelSensor value={trait.currentValue} unit={trait.unit}/>;
    case "levelSwitch":
      return (
        <LevelSwitch
          name={trait.name}
          value={trait.currentValue}
          min={trait.min}
          max={trait.max}
          unit={trait.unit}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
}

export default class DeviceTraits extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { device, onChange } = this.props;
    let model;

    if (config.models.hasOwnProperty(device.modelCode)) {
      model = config.models[device.modelCode];
    }

    let traits = [];
    for (let uuid in device.traits) {
      let tr = device.traits[uuid];
      tr = { uuid, ...tr };
      if (model) {
        if (model.traitFilter.includes(tr.name)) {
          traits.push(tr);
        }
      } else {
        traits.push(tr);
      }
    }

    const compareNickname = (a, b) => {
      const na = a.nickname;
      const nb = b.nickname;
      return (na < nb) ? -1 : (na > nb) ? 1 : 0;
    };

    traits.sort(compareNickname);

    return (
      <div>
        {traits && traits.map((trait, idx) => {
          return (
            <TraitItem key={trait.uuid} label={trait.nickname}>
              <Trait
                key={idx}
                trait={trait}
                onChange={onChange}
              />
            </TraitItem>
          );
        })}
      </div>
    )
  }
}

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
// Bij Tdb tussen -100 tot 0°C
const Const1 = -5.6745359 * Math.pow(10, 3);
const Const2 = 6.3925247;
const Const3 = -9.6778430 * Math.pow(10, -3);
const Const4 = 6.2215701 * Math.pow(10, -7);
const Const5 = 2.0747825 * Math.pow(10, -9);
const Const6 = -9.4840240 * Math.pow(10, -13);
const Const7 = 4.1635019;

// Bij Tdb tussen 0 tot 200°C
const Const8 = -5.8002206 * Math.pow(10, 3);
const Const9 = 1.3914993;
const Const10 = -4.8640239 * Math.pow(10, -2);
const Const11 = 4.1764768 * Math.pow(10, -5);
const Const12 = -1.4452093 * Math.pow(10,-8);
const Const13 = 6.5459673;

// Voor de berekening van dauwpunt
const Const14 = 6.54;
const Const15 = 14.526;
const Const16 = 0.7389;
const Const17 = 0.09486;
const Const18 = 0.4569;

/**
 *
 * @param Tdb (C): dry-bulb temperature of moist air
 * @param rh (%): relative humidity
 * @param masl (m): metres above sea level
 *
 * @returns
 * ap (Pa): atmospheric pressure
 * pws (Pa): pressure of saturated pure water
 * W (kg/kg): humidity ratio of moist air, mass of water per unit mass of dry air
 * h (Kj/kg): specific enthalpy of moist air
 * v (m^3/kg): specific volume
 * rho (kg/m^3): density
 * pw (Pa): partialPressureWaterVapor: partial pressure of water vapor in moist air,
 * dewPoint (C): number
 */
function calculate(Tdb, rh, masl = 0) {
  const ap = 101325 * Math.pow(1 - 2.25577 * Math.pow(10, -5) * masl, 5.256);
  const pws = Tdb < 0 ?
    Math.E ** (Const1/(Tdb+273.15)+Const2+Const3*(Tdb+273.15)+Const4*Math.pow(Tdb+273.15, 2)+Const5*Math.pow(Tdb+273.15, 3)+Const6*Math.pow(Tdb+273.15, 4)+Const7*Math.log(Tdb+273.15)) :
    Math.E ** (Const8/(Tdb+273.15)+Const9+Const10*(Tdb+273.15)+Const11* Math.pow(Tdb+273.15, 2)+Const12*Math.pow(Tdb+273.15, 3)+Const13*Math.log(Tdb+273.15));
  const W = (0.62198 * rh * pws / 100) / (ap - rh * pws / 100);
  const h = 1.006 * Tdb + W * (2500.77 + Tdb * 1.82);
  const v = 0.2871 * (Tdb + 273.15) * (1 + 1.6078 * W) / (ap / 1000);
  const rho = 1 / v;
  const pw = ap * W / (0.62198 + W);
  const Td = ( Tdb<0 ) ?
    6.09 + 12.608 * Math.log(pw / 1000)
    + 0.4959 * Math.pow(Math.log(pw / 1000), 2)
    : Const14 + Const15 * Math.log(pw / 1000)
    + Const16 * Math.pow(Math.log(pw / 1000), 2)
    + Const17 * Math.pow(Math.log(pw / 1000), 3)
    + Const18 * Math.pow(pw / 1000, 0.1984);

  return { ap, pws, W, h, v, rho, pw, Td };
}

/**
 *
 * @param Tdb
 * @param rh
 * @param masl
 * @returns {{vapourPressureDeficit: number, humidityDeficit: number, dewPoint: number}}
 * dewPoint (C): Dew Point
 * humidityDeficit (g/m^3): Humidity Deficit
 * vapourPressureDeficit (kPa): Vapour Pressure Deficit
 */
export default function (Tdb, rh, masl = 0) {
  const ml = calculate(Tdb, rh, masl);
  const ml100 = calculate(Tdb, 100, masl);

  const saturatedMoistureContent = ml100.W * ml100.rho * 1000;
  const moistureContent = ml.W * ml.rho * 1000;
  const saturatedVapourPressure =  ml.pws / 1000;
  const vapourPressure =  ml.pw / 1000;
  const dewPoint = ml.Td;

  return {
    dewPoint: dewPoint.toFixed(1),
    humidityDeficit: (saturatedMoistureContent - moistureContent).toFixed(1),
    vapourPressureDeficit: (saturatedVapourPressure - vapourPressure).toFixed(2)
  };
}

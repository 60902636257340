/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
export const chartColors = {
  red: 'rgb(255, 99, 132)',
  green: 'rgb(75, 192, 192)',
  orange: 'rgb(255, 159, 64)',
  blue: 'rgb(54, 162, 235)',
  yellow: 'rgb(255, 205, 86)',
  purple: 'rgb(153, 102, 255)'
};

export const rgb2rgba = (rgb, alpha) => {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
};

export const hex2rgb = (hex, alpha) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React from "react";

export default function TraitItem(props) {
  return (
    <div className="d-flex justify-content-between align-items-center px-0 pb-1">
      <h6 className="mr-1">{props.label}</h6>
      {props.children}
    </div>
  )
}

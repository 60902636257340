import React, { Component } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Auth, Logger } from 'aws-amplify';

const logger = new Logger('ForgotPassword');

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.sendCode = this.sendCode.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.changeState = this.changeState.bind(this);
    this.inputs = {};
    this.state = { error: '', alert: false, codeSent: false }
  }

  changeState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(state, data);
    }
  }

  sendCode(e) {
    e.preventDefault();

    const username = this.inputs.username;

    logger.info('resend code to ' + username);
    Auth.forgotPassword(username)
      .then(data => {
        this.setState({
          codeSent: true,
          error: 'Code sent',
          alert: true
        })
      })
      .catch(err => this.handleError(err));
  }

  resetPassword(e) {
    e.preventDefault();

    const username = this.inputs.username;

    if (!username) {
      this.setState({
        error: 'missing username',
        alert: true
      });
      return;
    }

    const { code, password } = this.inputs;

    Auth.forgotPasswordSubmit(username, code, password)
      .then(data => {
        this.changeState('signIn', { username } );
      })
      .catch(err => this.handleError(err));
  }

  handleError(err) {
    logger.info('forgot password send code error', err);
    this.setState({
      error: err.message || err,
      alert: true
    });
  }

  render() {
    const { authState, authData } = this.props;
    if (authState !== 'forgotPassword') { return null; }

    const { error } = this.state;

    const sendform = (
      <Form key='sendform' onSubmit={this.sendCode}>
        <Form.Group controlId="formBasicUsername">
          <Form.Label className="text-primary">Username<small>*</small></Form.Label>
          <Form.Control type="email" placeholder="Enter your username(Email)" autoFocus
                        onChange={event => this.inputs.username = event.target.value}/>
        </Form.Group>

        <div className="d-flex align-items-center submit-box">
          <a className="mr-auto" href="#" onClick={(e) => {
            e.preventDefault();
            this.changeState('signIn')
          }}>Back to Sign In</a>
          <Button className="submit-btn" type="submit">SEND CODE</Button>
        </div>
      </Form>
    );

    const resetform = (
      <Form key='resetform' onSubmit={this.resetPassword}>
        <Form.Group controlId="formBasicPassword">
          <Form.Label className="text-primary">Password<small>*</small></Form.Label>
          <Form.Control type="text" placeholder="New Password" defaultValue=""
                        autoFocus
                        onChange={event => this.inputs.password = event.target.value}/>
        </Form.Group>
        <Form.Group controlId="formBasicCode">
          <Form.Label className="text-primary">Confirmation Code<small>*</small></Form.Label>
          <Form.Control type="text" placeholder="Enter your code" autoFocus
                        onChange={event => this.inputs.code = event.target.value}/>
        </Form.Group>
        <div className="d-flex align-items-center submit-box">
          <a className="mr-auto" href="#" onClick={this.sendCode}>Resend code</a>
          <Button className="submit-btn" type="submit">SUMBIT</Button>
        </div>
      </Form>
    );

    return (
      <div className="text-center">
        <div className="login bg-white">
          <h4 className="text-primary title">Reset your password</h4>

          { this.state.codeSent ? resetform : sendform }

          <Alert className="fixed-top" variant="warning" dismissible
                 show={this.state.alert}
                 onClose={() => this.setState({ alert: false })}>
            <p>{error}</p>
          </Alert>
        </div>
      </div>
    )
  }
}

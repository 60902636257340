import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

import { ItemRow } from '../../components/common';

export default class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  handleApply() {
    const { payment } = this.props;
    payment.subscribe = false;
    this.props.onApply(payment);
  };

  handleCancel() {
    this.props.onCancel();
  };

  render() {
    const { payment } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>서비스 신청</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemRow label='서비스'><p>{payment.rating}</p></ItemRow>
          <ItemRow label='상품명'><p>{payment.name}</p></ItemRow>
          <ItemRow label='가격'><p>{payment.price}</p></ItemRow>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleApply}>Apply</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

Payment.defaultProps = {
  payment: {
    rating: '',
    name: '',
    price: 0
  }
};

/* eslint-disable import/first */
/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as Actions from "../../actions";

import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom'
import { Auth, Logger } from "aws-amplify";
const logger = new Logger('SignOut');

import { queryRecordAlarmPut } from '../../api/record'
import Sidebar from './Sidebar'
import { SignOut } from '../../components/auth';
import DropdownToggle from "../../components/common/DropdownToggle";

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const defaultProps = {
  user: {
    username: '',
    principalId: ''
  },
  alarms: [],
  children: null
  //  controlSidebar: null,
};

// TODO: new menu
function Title(props) {
  let title = '';

  switch (props.to) {
    case '/dashboard/device/list':
      title = 'List';
      break;
    case '/dashboard/device/history':
      title = 'History';
      break;
    case '/dashboard/device/management':
      title = 'Management';
      break;
    case '/dashboard/report/report':
      title = 'Report';
      break;
    case '/dashboard/report/chart':
      title = 'Chart';
      break;
    case '/dashboard/report/list':
      title = 'List';
      break;
    case '/dashboard/alarm/list':
      title = 'List';
      break;
    case '/dashboard/account/subscription':
      title = 'Subscription';
      break;
    case '/dashboard/account/setting':
      title = 'Account';
      break;
  }
  return (
    <h2 className="dashhead-title">{title}</h2>
  );
}

// TODO: new menu
function SubTitle(props) {
  let subtitle = '';

  switch (props.to) {
    case '/dashboard/device/list':
    case '/dashboard/device/history':
    case '/dashboard/device/management':
      subtitle = 'DEVICE';
      break;
    case '/dashboard/report/report':
    case '/dashboard/report/chart':
    case '/dashboard/report/list':
      subtitle = 'REPORT';
      break;
    case '/dashboard/alarm/list':
      subtitle = 'ALARM';
      break;
    case '/dashboard/account/overview':
    case '/dashboard/account/subscription':
    case '/dashboard/account/setting':
      subtitle = 'ACCOUNT';
      break;
  }
  return (
    <h6 className="dashhead-subtitle">{subtitle}</h6>
  );
}

/*
class BadgeToggle extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    const { icon, variant, count } = this.props;

    return (
      <div className="text-white" href=""
           onClick={this.handleClick}>
        <span className={`icon ${icon}`} style={{ color: 'black' }}/>
        <span className={`badge badge-${variant}`}>{count}</span>
      </div>
    );
  }
}
 */

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const BadgeToggle = React.forwardRef(
    ({ icon,variant, count, children, onClick }, ref) => (
    <div className="text-white"
         href=""
         ref={ref}
         onClick={e => {
           e.preventDefault();
           onClick(e);
         }}
    >
      <span className={`icon ${icon}`} style={{ color: 'black' }}/>
      <span className={`badge badge-${variant}`}>{count}</span>
    </div>
));

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.handleAlarmSelect = this.handleAlarmSelect.bind(this);
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
  }

  componentDidMount() {
  }

  handleAlarmSelect(eventKey, event) {
    const { user, alarms } = this.props;

    queryRecordAlarmPut(user.principalId, {
      date: alarms[eventKey].date,
      checked: true
    })
      .then((response) => {
        this.props.actions.fetchUncheckedAlarms(user.principalId);
      })
  }

  handleMenuSelect(eventKey, e) {
    const target = e.target;

    switch(eventKey) {
      case 'account':
        break;
      case 'signout':
        Auth.signOut()
          .then(() => console.info('sign out success'))
          .catch(err => console.error('sign out error', err));

        fetch(`https://dev-lb.raonix.com/session/end?` + new URLSearchParams({
          'post_logout_redirect_uri': 'http://localhost:3000',
        }), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
          .then(res => res.json())
          .catch(err => console.error(err));
        break;
    }
  }

  handleAccountSelect(to, eventKey) {
    this.props.actions.selectSidebarItem(to);
  }

  render() {
    const {user, alarms, sidebarItem, children} = this.props;

    const dropdownItems = alarms.map((e, index) => {
        return (
          <Dropdown.Item key={index} eventKey={index} onSelect={this.handleAlarmSelect}>
            <span className={`icon icon-notification text-${e.variant} mr-1`}/>{e.message}
          </Dropdown.Item>
        )
      }
    );

    return (
      <React.Fragment>
        <div className="container">
          <div className="row">

            <div className="col-md-3 sidebar">
              <Sidebar/>
            </div>

            <div className="col-md-9 content">
              <div className="dashhead">
                <div className="dashhead-titles">
                  <SubTitle to={sidebarItem}/>
                  <Title to={sidebarItem}/>
                </div>
                <div className="dashhead-toolbar">
                  <Dropdown className="dashhead-toolbar-item mr-4" alignRight onSelect={this.handleAlarmSelect}>
                    <Dropdown.Toggle
                      as={BadgeToggle}
                      variant="warning"
                      id="dropdown-notification"
                      icon="icon-notification"
                      count={alarms.length}
                    />
                    <Dropdown.Menu>
                      <Dropdown.Header>You have {alarms.length} notifications</Dropdown.Header>
                      {dropdownItems}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown className="dashhead-toolbar-item" onSelect={this.handleMenuSelect}>
                    <Dropdown.Toggle as={DropdownToggle} id="dropdown-user">
                      <span>{user.username}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        eventKey="account"
                        as="div"
                        onSelect={this.handleAccountSelect.bind(this, '/dashboard/account/setting')}>
                        <Link to="/dashboard/account/setting">
                          Account
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="signout">Sign Out</Dropdown.Item>
                      {/*
                      <Dropdown.Item eventKey="signout"><SignOut/></Dropdown.Item>
                      */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <hr className="mt-0 mb-1"/>

              {children}

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

const mapStateToProps = (state) => {
  return {
    sidebarItem: state.dashboard.sidebarItem,
    alarms: state.dashboard.alarms
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

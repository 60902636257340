import React, { Component } from 'react';
import { chartColors, rgb2rgba } from "./utils";
import { getEventDatasets, getTraitByName } from "./report";
import ThermometerChart from './ThermometerChart';

class Setting extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(uuid, e) {
    const { devices, onChange } = this.props;
    let traits = this.props.traits;
    const target = e.target;
    const name = target.name;

    let dev = devices.find(e => e.uuid === uuid);
    let temp = getTraitByName(dev, 'temperature');
    let humi = getTraitByName(dev, 'humidity');

    if (name === 'checkbox') {
      if (target.checked) {
        traits[temp.uuid] = { name: temp.name, label: dev.nickname+'온도' };
        traits[humi.uuid] = { name: humi.name, label: dev.nickname+'습도' };
      } else {
        delete traits[temp.uuid];
        delete traits[humi.uuid];
      }
    }
    if (name === 'label') {
      traits[temp.uuid].label = target.value+'온도';
      traits[humi.uuid].label = target.value+'습도';
    }

    onChange(traits);
  };

  render() {
    const { devices, traits } = this.props;

    const compareNickname = (a, b) => {
      const na = a.nickname;
      const nb = b.nickname;
      return (na < nb) ? -1 : (na > nb) ? 1 : 0;
    };

    devices.sort(compareNickname);

    return (
      <React.Fragment>
        <ul className="list-group mb-3">
          {devices.reduce((listItems, dev) => {

            if (dev.type === 'thermometer') {
              let tr = getTraitByName(dev, "temperature");
              let checked = traits.hasOwnProperty(tr.uuid);
              let label = checked ? traits[tr.uuid].label.slice(0, -2) : dev.nickname;

              listItems.push((
                <li className="list-group-item" key={dev.uuid}>
                  <div className="row">
                    <div className="col-auto m-auto">
                      <input
                        name="checkbox"
                        type="checkbox"
                        checked={checked}
                        onChange={this.handleChange.bind(this, dev.uuid)}
                      />
                    </div>
                    <div className="col-2">
                      <label className="col-form-label">{dev.nickname}</label>
                    </div>
                    <div className="col">
                      <input
                        name='label'
                        className="form-control"
                        type="text"
                        placeholder="label"
                        disabled={!checked}
                        value={label}
                        onChange={this.handleChange.bind(this, dev.uuid)}
                      />
                    </div>
                  </div>
                </li>
              ));
            }
            return listItems;
          }, [])}
        </ul>
      </React.Fragment>
    )
  }
}

const options = {
  responsive: true,
  legend: {
    position: 'top',
  },

  scales: {
    xAxes: [
      {
        id: 'x-axis-1',
        type: 'time',
        offset: true,
        time: {
          displayFormats: {
            hour: 'D. HH',
            day: 'M.D.',
            year: 'YYYY.M.',
          }
        },
        scaleLabel: {
          display: true,
          labelString: '시간',
        }
      }
    ],
    yAxes: [
      {
        id: 'y-axis-temperature',
        labels: {
          show: true
        },
        position: 'left',
        scaleLabel: {
          display: true,
          labelString: '온도',
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: 40
        }
      },
      {
        id: 'y-axis-humidity',
        labels: {
          show: true
        },
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: '습도',
        },
        ticks: {
          suggestedMin: 0,
          suggestedMax: 100
        }
      }
    ]
  }
};

class Chart extends Component {
  render() {
    const { report } = this.props;
    let datasets = getEventDatasets(report);

    if (datasets === undefined) {
      return null;
    }

    let data = {};
    data.datasets = datasets.map((e, index) => {
      const keys = Object.keys(chartColors);
      const key = keys[(~~(index/2)) % keys.length];
      const color = chartColors[key];

      return {
        fill: false,
        // lineTension: 0.1,
        lineTension: 0,
        borderWidth: 1,
        borderDash: e.name === 'humidity' ? [5, 5] : [],
        borderColor: color,
        backgroundColor: rgb2rgba(color, 0.5),
        label: e.label,
        data: e.data,
        yAxisID: e.name === 'humidity' ? 'y-axis-humidity' : 'y-axis-temperature'
      };
    });

    return <ThermometerChart data={data} type={ThermometerTemperatureHumidity.name}/>
  }
}

Setting.defaultProps = {
  settings: {
    devices: {}
  }
};

const ThermometerTemperatureHumidity = {
  nickname: '온습도 리포트',
  name: 'ThermometerTemperatureHumidity',
  Setting,
  Chart
};

export default ThermometerTemperatureHumidity;

import config from './config';

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserRouter as Router, Switch, Route, Redirect, useParams } from "react-router-dom";
import Amplify, { Auth, Hub } from 'aws-amplify';
import * as Actions from "./actions";
import Login from './components/pages/Login'
import Login2 from './components/pages/Login2'
import Main from './components/pages/Main'
import KakaoPayResult from './containers/pages/KakaoPayResult'
import PaymentTerms from './components/account/PaymentTerms'

import OAuthCallback1 from "./components/auth/OAuthCallback1";
import OAuthCallback3 from "./components/auth/OAuthCallback3";

Amplify.configure({
  Auth: config.awsAuth,
  API: config.awsAPI,
  Storage: config.awsStorage,
});

Amplify.Logger.LOG_LEVEL = 'DEBUG';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: 'loading',
      principalId: ''
    };

    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });
  }

  componentDidMount() {
    this.loadUser();
  }

  onAuthEvent = (payload) => {
    switch (payload.event) {
      case 'signIn':
        this.loadUser();
        break;
      case 'signUp':
        break;
      case 'signOut':
        this.setState({authState: 'signIn'});
        break;
      case 'signIn_failure':
        this.setState({authState: 'signIn'});
        break;
      case 'configured':
        break;
    }
  }

  loadUser = async () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        let u = {};
        if (user.hasOwnProperty('attributes')) { // Cognitor login
          console.log('attributes', user.attributes);

          const {
            email: username,
            name,
            sub: principalId,
          } = user.attributes;

          u = {
            username,
            name,
            principalId,
          }
        } else if (user.hasOwnProperty('id')) { // Google & Nemoahn OIDC
          console.log('user', user);

          // TODO : 구글 로그인시 username 체크 !!!
          const {
            username,
            id: principalId,
          } = user;

          u = {
            username,
            principalId,
          }
        }

        this.setState({
          principalId: u.principalId,
          authState: 'signedIn'
        });

        this.props.actions.receiveUser(u);

        // fetch User's information
        this.props.actions.fetchUser(u.principalId);
      })
      .catch(err => {
          console.error(err);
          this.setState({
            principalId: '',
            authState: 'signIn'
          });
        }
      )
  }

/* <Redirect to="/login"/> */
render() {
  const { authState, principalId } = this.state;

    console.debug('authState:', authState);

    return (
      <Router>
        <Switch>
          <Route exact path="/" render={props => (
            authState === 'signedIn' ? (
              <Redirect to="/dashboard"/>
            ) : (
              <Login2 authState={authState} {...props}/>
            ))}
          />
          <Route path="/login" children={props => (
            authState === 'signedIn' ? (
              <Redirect to="/dashboard"/>
            ) : (
              <Login2 authState={authState} {...props}/>
            ))}
          />
          <Route path="/dashboard" render={props => (
            authState === 'signedIn' ? (
              <Main principalId={principalId}/>
            ) : (
              <Redirect to="/login"/>
            ))}
          />
          <Route path="/terms/payment" render={props => (
            <PaymentTerms {...props} principalId={principalId}/>
          )}
          />
          <Route path="/kakao/pay/:uuid" render={props => (
            <KakaoPayResult {...props} principalId={principalId}/>
          )}
          />
          <Route path="/oauth-callback1" component={OAuthCallback1} />
          <Route path="/oauth-callback3" component={OAuthCallback3} />
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(App);


import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions";

import PaymentFree from '../../components/account/PaymentFree'
import Payment from '../../components/account/Payment'
import { InfoCard, ItemRow } from '../../components/common'

import {
  queryPaymentSubscriptionGet,
  queryPaymentReadyPost
} from "../../api/payment";
import { queryUserPut } from "../../api/r9cloud";
import { priceFormat } from '../../utils';

const items = {
  free: {
    item: 'free',
    rating: 'free',
    name: '네모안 클라우드 무료',
    price: 0,
  },
  basic: {
    item: 'basic',
    rating: 'basic',
    name: '네모안 클라우드 베이직',
    price: 9900,
  },
  premium: {
    item: 'premium',
    rating: 'premium',
    name: '네모안 클라우드 프리미엄',
    price: 19800,
  },
  sms: {
    item: 'sms',
    name: '네모안 클라우드 문자(100건) 충전',
    price: 3300
  },
  undefined: {
    rating: '',
    name: '',
    price: 0
  }
};

function payRedirectPopup (url) {
  return(
    <>
      {window.open(url,'window_name','width=430,height=500,location=no,status=no,scrollbars=yes')}
    </>
  )
}

const PaymentButton = ({ className, ...otherProps }) => (
  <button type="button" className={['btn', className].join(' ')} {...otherProps} />
);

const PaymentCard = ({ className, ...otherProps }) => {
  const { header, title, children } = otherProps;
  return (
    <div className={['card', className].join(' ')}>
      <div className="card-header">{header}</div>
      <div className="card-body d-flex flex-column">
        <div className="card-title h5">{title}</div>
        {children}
      </div>
    </div>
  );
};

class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPaymentFree: false,
      showPayment: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handlePaymentFreeApply = this.handlePaymentFreeApply.bind(this);
    this.handlePaymentApply = this.handlePaymentApply.bind(this);
    this.handlePaymentCancel = this.handlePaymentCancel.bind(this);

    console.debug('>>>>>>>>>>>>>>> constructor');
  }

  componentDidMount() {
    const { principalId } = this.props;
    queryPaymentSubscriptionGet(principalId)
      .then(res => {
        this.setState({ userPayment: res.data });
      });
  }

  handleClick(event) {
    const { id }= event.target;
    switch(id) {
      case 'free':
        this.setState({
          payment: items[id],
          showPaymentFree: true
        });
        break;
      case 'basic':
      case 'premium':
      case 'sms':
        this.setState({
          payment: items[id],
          showPayment: true
        });
        break;
      default:
        this.setState({
          payment: items[id],
          showPaymentFree: true
        });
        break;
    }
  }

  handlePaymentFreeApply(payment) {
    const { principalId } = this.props;

    this.setState({ showPaymentFree: false });

    let params = {
      rating: payment.rating
    };

    queryUserPut(principalId, params)
      .then(() => {
        this.props.actions.fetchUser(principalId);
      })
      .catch(err => console.error(err));
  }

  handlePaymentApply(payment) {
    const {principalId} = this.props;

    this.setState({showPayment: false});

    queryPaymentReadyPost(principalId, payment.item)
      .then(res => {
        let body = res.data;
        payRedirectPopup(body.next_redirect_pc_url);
      })
      .catch(err => console.error(err));
  }

  handlePaymentCancel() {
    this.setState({
      showPaymentFree: false,
      showPayment: false
    });
  }

  render() {
    const { user, devices } = this.props;
    const { userPayment } = this.state;
    const item = user.rating && items[user.rating] || items['undefined'];

    return (
      <React.Fragment>
        <div className="d-flex flex-column" style={{ height: "85vh" }}>
          <InfoCard className="mb-4" header="나의 서비스">
            <ItemRow label="서비스">
              <span>{item.name}</span>
            </ItemRow>
            <ItemRow label="결제금액">
              <span>{priceFormat.format(item.price)} / 월</span>
            </ItemRow>
            <ItemRow label='결제일'>
              {userPayment &&
              <span>
                {new Date(userPayment.paidAt).toLocaleString('ko-KR', {
                  hour12: false, weekday: 'short', month: 'numeric', day: 'numeric'
                })}
              </span>}
            </ItemRow>
            <ItemRow label='사용 기기 수'>
              <span>{Object.keys(devices).filter(uuid => devices[uuid].type !== 'gateway').length}</span>
            </ItemRow>

            {user.rating === 'premium' &&
            <ItemRow label='문자사용량'>
              <span>{user.smsCount}/{user.smsMax + user.smsCharge}</span>
              <PaymentButton
                className="paymentbutton-premium ml-3"
                id="sms"
                onClick={this.handleClick}
              >
                {"100문자 " + priceFormat.format(items.sms.price)}
              </PaymentButton>
            </ItemRow>}
          </InfoCard>

          <div className="card-deck">
            <PaymentCard className="paymentcard-free" header="무료" title={priceFormat.format(items.free.price)}>
              <ul>
                <li>실시간 기기 모니터링 및 제어</li>
                <li>10개 기기 등록</li>
                <li>앱 푸시 알람</li>
              </ul>
              <div className="mt-auto">
                <PaymentButton
                  className="w-100"
                  id="free"
                  onClick={this.handleClick}
                >
                  {priceFormat.format(items.free.price)}/월
                </PaymentButton>
              </div>
            </PaymentCard>
            <PaymentCard className="paymentcard-basic" header="베이직" title={priceFormat.format(items.basic.price)}>
              <ul>
                <li>무료 등급 기능</li>
                <li>20개 기기 등록</li>
                <li>e-mail 알람</li>
                <li>알람 메시지 저장</li>
                <li>
                  기기 실시간 상태값이 클라우드 데이터베이스에 저장
                  <p>주) 데이터는 3년 동안 유지되며 3년이후 데이타는 1일 단위로 과거 데이터 부터 삭제</p>
                </li>
                <li>리포트 작성 기능</li>
              </ul>
              <div className="mt-auto">
                <PaymentButton
                  className="w-100"
                  id="basic"
                  onClick={this.handleClick}
                >
                  {priceFormat.format(items.basic.price)}/월
                </PaymentButton>
              </div>
            </PaymentCard>
            <PaymentCard className="paymentcard-premium" header="프리미엄" title={priceFormat.format(items.premium.price)}>
              <ul>
                <li>베이직 등급 기능</li>
                <li>50개 기기 등록</li>
                <li>
                  SMS(문자 서비스) 100개/월 알람
                  <p>주)추가구매 100개/월: {priceFormat.format(3000)}</p>
                </li>
              </ul>
              <div className="mt-auto">
                <PaymentButton
                  className="w-100"
                  id="premium"
                  onClick={this.handleClick}
                >
                  {priceFormat.format(items.premium.price)}/month
                </PaymentButton>
              </div>
            </PaymentCard>
          </div>
          <div className="w-100 mt-auto border-top">
              <span className="mt-3" style={{fontSize: 'small', fontWeight: 'lighter'}}>
                <b>주식회사 라온익스</b> 대표전화 : 031-735-0501 주소 : 경기도 광주시 오포읍 창뜰윗길 21-11 사업자등록번호 : 129-86-15107 대표이사 : 김동용
              </span>
          </div>
        </div>

        <PaymentFree
          user={this.props.user}
          show={this.state.showPaymentFree}
          payment={this.state.payment}
          onApply={this.handlePaymentFreeApply}
          onCancel={this.handlePaymentCancel}
        />

        <Payment
          user={this.props.user}
          show={this.state.showPayment}
          payment={this.state.payment}
          onApply={this.handlePaymentApply}
          onCancel={this.handlePaymentCancel}
        />

      </React.Fragment>
    )
  }
}

Subscription.defaultProps = {
  user: {
    rating: 'free'
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    devices: state.devices
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription);

export { items };

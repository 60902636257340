import React, { Component } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Auth, Logger } from 'aws-amplify';

const logger = new Logger('ConfirmSignUp');

export default class ConfirmSignUp extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.changeState = this.changeState.bind(this);
    this.inputs = {};
    this.state = { error: '', alert: false }
  }

  changeState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(state, data);
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { username } = this.props.authData || this.inputs;
    const { code } = this.inputs;

    logger.debug('authData: ', this.props.authData);
    logger.debug('username: ', username);
    logger.debug('code: ', code);

    Auth.confirmSignUp(username, code)
      .then((result) => {
        logger.info(result);
        // queryUserNotificationPost(username )
        logger.info('confirm sign up success with ' + username);
        this.changeState('signIn', username);
      })
      .catch(err => this.handleError(err));
  }

  resendCode(e) {
    e.preventDefault();

    const { username } = this.props.authData || this.inputs;

    Auth.resendSignUp(username)
      .then(() => this.setState({
        error: 'Code sent',
        alert: true
      }))
      .catch(err => this.handleError(err));
  }

  handleError(err) {
    logger.error('confirm sign up error', err);
    this.setState({
      error: err.message || err,
      alert: true
    });
  }

  render() {
    const { authState, authData } = this.props;
    if (authState !== 'confirmSignUp' || typeof authData === 'undefined') { return null; }

    const { error } = this.state;

    return (
      <div className="text-center">
        <div className="login bg-white">
          <h5 className="font-weight-bold text-primary title">
            Confirm Sign Up
          </h5>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="formBasicUsername">
              <Form.Label className="text-primary">Username<small>*</small></Form.Label>
              <Form.Control type="email" placeholder="Email" defaultValue={authData.username || ''}
                            readOnly={!!authData.username}
                            onChange={event => this.inputs.username = event.target.value}/>
            </Form.Group>
            <Form.Group controlId="formBasicCode">
              <Form.Label className="text-primary">Confirmation Code<small>*</small></Form.Label>
              <Form.Control type="text" placeholder="Enter your code" autoFocus
                            onChange={event => this.inputs.code = event.target.value}/>
              <Form.Text>
                <a href="#" onClick={this.resendCode}>
                  Lost your code? Resend Code
                </a>
              </Form.Text>
            </Form.Group>

            <div className="d-flex align-items-center submit-box">
              <a className="mr-auto" href="#" onClick={(e) => {
                e.preventDefault();
                this.changeState('signIn')
              }}>Back to Sign In</a>
              <Button className="submit-btn" type="submit">CONFIRM</Button>
            </div>
          </Form>

          <Alert className="fixed-top" variant="warning" dismissible
                 show={this.state.alert}
                 onClose={() => this.setState({ alert: false })}>
            <p>{error}</p>
          </Alert>
        </div>
      </div>
    )
  }
}

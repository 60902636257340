/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import ThermometerTemperature from "./ThermometerTemperature";
import ThermometerHumidity from "./ThermometerHumidity";
import ThermometerTemperatureHumidity from "./ThermometerTemperatureHumidity";
import HeatcableTemperature from "./HeatcableTemperature";

export const REPORT_WRITERS = {
  thermometer: [
    ThermometerTemperature,
    ThermometerHumidity,
    ThermometerTemperatureHumidity,
  ],
  heatcable: [
    HeatcableTemperature,
  ]
};
export const dateOpt = {
  hour12: false,
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit'
};

export const getStatistics = (records, start, end, step) => {
  let stat = [];

  const endt = new Date(end);
  let st = new Date(start);
  let et;
  if (step === undefined) {
    et = endt;
  } else {
    et = new Date(start);
    et.setHours(et.getHours() + step);
  }

  /**
   * 구간의 끝 시간에 없는 값을 앞의 값을 이용하여 추가함.
   *
   * 아래 그림에서
   *
   * x : records 값
   * o : 바로전 x 값을 이용해서 새로 추가된 값.
   *
   * start          end
   * |              |              |  ...
   * x   x    x   x o   x   x  x   o    x
   *
   */
  let datas = records.reduce((acc, cur, idx, arr) => {
    let ct = new Date(cur.date);
    if (ct > endt) {
      return acc;
    }

    if (idx === 0 && ct > st) {
      acc.push({ x: new Date(st.getTime()), y: undefined });
    }

    if (ct > et) {
      do {
        acc.push({x: new Date(et.getTime()), y: (idx === 0) ? undefined : arr[idx-1].value});

        st.setHours(st.getHours() + step);
        et.setHours(et.getHours() + step);
      } while (ct > et);

      acc.push({x: new Date(cur.date), y: cur.value});

      return acc;
    } else if (ct === et) {

      if (step !== undefined) {
        st.setHours(st.getHours() + step);
        et.setHours(et.getHours() + step);
      }

      acc.push({x: new Date(cur.date), y: cur.value});

      return acc;
    }

    acc.push({x: new Date(cur.date), y: cur.value});
    return acc;
  }, []);

  // st, et를 새로 초기화
  st = new Date(start);
  if (step === undefined) {
    et = endt;
  } else {
    et = new Date(start);
    et.setHours(et.getHours() + step);
  }

  //
  // 데이타가 없는 경우 avg, min, max... undefined로 설정
  datas.reduce((acc, cur, idx, arr) => {
    let ct = cur.x;
    let nt = (idx === (arr.length - 1)) ? endt : arr[idx+1].x;

    if (ct >= endt) {
      return acc;
    }

    if (cur.y != undefined) {
      // min, max
      acc.min = acc.min && ((cur.y < acc.min) ? cur.y : acc.min) || cur.y;
      acc.max = acc.max && ((cur.y > acc.max) ? cur.y : acc.max) || cur.y;

      // 평균값 계산을 위한 면적.
      const w = nt - ct;
      acc.widthSum += w;
      acc.areaSum = acc.areaSum && (acc.areaSum + w * cur.y) || (w * cur.y) ;
    }

    // 구간의 통계값을 저장.
    if (nt.getTime() === et.getTime()) {
      stat.push({
        start: new Date(st.getTime()),
        end: new Date(et.getTime()),
        average: acc.areaSum && acc.areaSum / acc.widthSum,
        min: acc.min && acc.min,
        max: acc.max && acc.max
      });

      // acc 초기화
      acc.widthSum = 0;
      acc.areaSum = undefined;
      acc.min = undefined;
      acc.max = undefined;

      // start, end 시간을 다음 step 으로 변경
      if (step !== undefined) {
        st.setHours(st.getHours() + step);
        et.setHours(et.getHours() + step);
      }
    }

    return acc;
  }, { widthSum: 0, areaSum: undefined, min: undefined, max: undefined });

  return stat;
}

export const getEventDatasets = (report) => {
  let datasets =  Object.keys(report.traits).map(uuid => {
    let dataset = {
      name: report.traits[uuid].name,
      label: report.traits[uuid].label
    };
    if (report.records[uuid]) {
      dataset.data = report.records[uuid].map(el => ({ x: new Date(el.date), y: el.value }));
    } else {
      dataset.data = [];
    }
    return dataset;
  });

  datasets.sort((a, b) =>  {
    const la = a.label;
    const lb = b.label;
    return (la < lb) ? -1 : (la > lb) ? 1 : 0;
  });

  return datasets;
};

export const getTraitByName = (device, name) => {
  const traits = device.traits;
  for (let uuid in traits) {
    let tr = traits[uuid];
    if (tr.name === name) {
      return {
        uuid,
        ...tr,
      }
    }
  }

  return undefined;
};

export const getAverageDatasets = (report, step) => {
  let datasets = { average: [], min: [], max: [] };

  Object.keys(report.traits).forEach(uuid => {
    let stat = getStatistics(report.records[uuid], report.startDate, report.endDate, step);

    let dataset = {
      label: 'AVG-' + report.traits[uuid].label,
    };
    dataset.data = stat.map(el => ({ x: el.start, y: el.average && el.average.toFixed(1) }));
    datasets.average = datasets.average.concat(dataset);

    dataset = {
      label: 'MIN-' + report.traits[uuid].label,
    };
    dataset.data = stat.map(el => ({ x: el.start, y: el.average && el.min }));
    datasets.min = datasets.min.concat(dataset);


    dataset = {
      label: 'MAX-' + report.traits[uuid].label,
    };
    dataset.data = stat.map(el => ({ x: el.start, y: el.average && el.max }));
    datasets.max = datasets.max.concat(dataset);
  });

  return datasets;
};

export const getStepDatasets = (report, step) => {
  return Object.keys(report.traits).map(uuid => {
    let dataset = {
      label: report.traits[uuid].label,
      data: []
    };

    const traitRecords = report.records[uuid];

    if (traitRecords.length > 0) {
      for (let t = new Date(report.startDate); t <= new Date(report.endDate); t.setHours(t.getHours() + step)) {
        let traitRecord = traitRecords.reduce((accumulator, currentValue) => {
          let d1 = t - new Date(accumulator.date);
          let d2 = t - new Date(currentValue.date);

          if (d2 < 0) {
            return accumulator;
          } else {
            return (d1 < 0 || d1 > d2) ? currentValue : accumulator;
          }
        });
        dataset.data.push({
          x: new Date(t.getTime()),
          y: traitRecord.value
        });
      }
    }

    return dataset;
  });
};

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

// Dashboard
export const SELECT_SIDEBAR_ITEM = 'SELECT_SIDEBAR_ITEM';
export const RECEIVE_ALARM_LIST = 'RECEIVE_ALARM_LIST';

// User
export const RECEIVE_USER = 'RECEIVE_USER';

// Device
export const ADD_DEVICE = 'ADD_DEVICE';
export const REQUEST_DEVICE = 'REQUEST_DEVICE';
export const RECEIVE_DEVICE = 'RECEIVE_DEVICE';
export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const REMOVE_DEVICE_ALL = 'REMOVE_DEVICE_ALL';
export const SELECT_DEVICE = 'SELECT_DEVICE';
export const INVALIDATE_DEVICE = 'INVALIDATE_DEVICE';
export const REQUEST_DEVICE_STATE = 'REQUEST_DEVICE_STATE';
export const RECEIVE_DEVICE_STATE = 'RECEIVE_DEVICE_STATE';
export const SET_DEVICE_FETCH_ERROR = 'SET_DEVICE_FETCH_ERROR';

// Place
export const ADD_PLACE = 'ADD_PLACE';
export const REMOVE_PLACE = 'REMOVE_PLACE';
export const UPDATE_PLACE = 'UPDATE_PLACE';
export const REQUEST_PLACES = 'REQUEST_PLACES';
export const RECEIVE_PLACES = 'RECEIVE_PLACES';
export const ADD_PLACE_DEVICE = 'ADD_PLACE_DEVICE';
export const REMOVE_PLACE_DEVICE = 'REMOVE_PLACE_DEVICE';
export const SET_PLACE_FETCH_ERROR = 'SET_PLACE_FETCH_ERROR';

// Report
export const RECEIVE_REPORT = 'RECEIVE_REPORT';
export const REMOVE_REPORT_ALL = 'REMOVE_REPORT_ALL';
export const REMOVE_REPORT = 'REMOVE_REPORT';
export const RECEIVE_REPORT_RECORDS = 'RECEIVE_REPORT_RECORDS';

// Record
export const REQUEST_RECORD_DEVICES = 'REQUEST_RECORD_DEVICES';
export const RECEIVE_RECORD_DEVICES = 'RECEIVE_RECORD_DEVICES';
export const REMOVE_RECORD_DEVICE = 'REMOVE_RECORD_DEVICE';

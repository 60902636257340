import React from 'react';
import r9iot from "../../api/r9iot";
import BinarySwitch from "./BinarySwitch";
import DeviceItem from "./DeviceItem";
import DeviceTraits from "./DeviceTraits";
import config from "../../config";

class Generalio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceState: undefined
    }
  }

  componentDidMount() {
    const { principalId, device } = this.props;
    r9iot.getChildState(principalId, device.id)
      .then(res => {
        console.debug(res);
        if (res.status === 200) {
          this.setState({ deviceState: res.data })
        }
      })
      .catch(error => console.error(error));
  }

  handleSelectMenu = (eventKey, event) => {
    this.props.onSelectMenu(eventKey, this.props.device);
  };

  handleBinarySwitchChange = (name, value) => {
    let swidx = parseInt(name.match(/\d+/g));
    let key = name.match(/[a-zA-Z]+/g);

    const { principalId, device } = this.props;
    const params = {
      switches: [
        {
          swidx,
          [key]: value ? 1 : 0
        }
      ]
    };
    r9iot.setChildState(principalId, device.id, params)
      .catch(error => console.error(error));
  };

  handleChange = (name, value) => {
    const { principalId, device } = this.props;
    let model;

    if (config.models.hasOwnProperty(device.modelCode)) {
      model = config.models[device.modelCode];
    }

    if (model.traitFilter.includes(name)) {
      let swidx = parseInt(name.match(/\d+/g));
      let key = name.match(/[a-zA-Z]+/g);

      const params = {
        switches: [
          {
            swidx,
            [key]: value
          }
        ]
      };
      r9iot.setChildState(principalId, device.id, params)
        .catch(error => console.error(error));
    }
  };

  render() {
    const { principalId, device } = this.props;
    const { deviceState } = this.state;

    if (deviceState === undefined) {
      return null;
    }

    let sws = [];
    deviceState.switches.forEach((sw, index) => {
      sws.splice(sw.swidx-1, 0, sw);
    });

    return (
      <DeviceItem {...this.props}>
        {/*
        {sws && sws.map(sw => (
          <React.Fragment key={sw.swidx}>
            <BinarySwitch
              label={'스위치'+sw.swidx}
              name={'on'+sw.swidx}
              value={sw.on}
              onChange={this.handleBinarySwitchChange}
            />
            <BinarySwitch
              label={'예약'+sw.swidx}
              name={'schedule'+sw.swidx}
              value={sw.schedule}
              onChange={this.handleBinarySwitchChange}
            />
          </React.Fragment>
        ))}
        */}
        <DeviceTraits
          device={device}
          onChange={this.handleChange}
        />
      </DeviceItem>
    )
  }
}

export default Generalio;

import React from 'react';

import { Modal } from "react-bootstrap";
// TODO: FATAL ERROR: Ineffective mark-compacts near heap limit Allocation failed - JavaScript heap out of memory
import { Document, Page } from 'react-pdf/dist/entry.webpack';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
// import { Document, Page } from 'react-pdf';


const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

class PdfPreview extends React.Component {
  constructor(props) {
    super(props);

    this.handleHide = this.handleHide.bind(this);

    this.state = {
      numPages: null,
      pageNumber: 1
    }
  }

  componentDidMount() {
//    this.setState({width: this.pdfWrapper.getBoundingClientRect().width})
    this.setState({width: 780});
  }

  handleHide() {
    this.props.onClose(false);
  };

  handleDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1,
    });

    this.setState({width: this.pdfWrapper.getBoundingClientRect().width})
  };

  goToPrevPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  render() {
    const { file, show } = this.props;
    const { numPages, pageNumber } = this.state;

    return(
      <Modal
        size="lg"
        scrollable
        show={show}
        onHide={this.handleHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Viewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="pdfWrapper" style={{width: "100%"}} ref={(ref) => this.pdfWrapper = ref}>
            <div>
              <button type="button" disabled={pageNumber <= 1} onClick={this.goToPrevPage}>
                Previous
              </button>
              <button type="button" disabled={pageNumber >= numPages} onClick={this.goToNextPage}>
                Next
              </button>
            </div>
            <Document
              file={file}
              onLoadSuccess={this.handleDocumentLoadSuccess}
              onLoadError={console.error}
              options={options}
            >
              <Page pageNumber={pageNumber} width={this.state.width}/>
            </Document>
            <div>
              <p>
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

PdfPreview.defaultProps = {
  title: 'Report'
};

export default PdfPreview;

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React, {Component, Fragment} from 'react';
import * as Actions from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Auth } from "aws-amplify";
import { InfoCard, ItemRow } from '../../components/common';

class Account extends Component {
  constructor (props) {
    super(props);

    this.state = {
      user: undefined
    }
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        let u = {};
        if (user.hasOwnProperty('attributes')) { // Cognitor login
          console.log('attributes', user.attributes);

          const {
            email: userName,
            name,
            address,
            phone_number: phoneNumber
          } = user.attributes;

          u = {
            userName,
            name,
            address,
            phoneNumber
          }
        } else if (user.hasOwnProperty('id')) { // Google Login
          console.log('user', user);

          const {
            email: userName,
          } = user;

          u = {
            userName
          }
        }
        this.setState({
          user: u
        });
      });
  }

  render() {
    const { user } = this.state;

    return (
      <Fragment>
        {user && <InfoCard className="mb-4" header="계정">
          <ItemRow label="아이디">
            <span>{user.userName}</span>
          </ItemRow>
          <ItemRow label="이름">
            <span>{user.name}</span>
          </ItemRow>
          <ItemRow label="주소">
            <span>{user.address}</span>
          </ItemRow>
          <ItemRow label="전화번호">
            <span>{user.phoneNumber}</span>
          </ItemRow>
        </InfoCard>}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(Account);

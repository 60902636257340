/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

import {
  REQUEST_RECORD_DEVICES,
  RECEIVE_RECORD_DEVICES,
  REMOVE_RECORD_DEVICE
} from './types';

import { queryRecordDevices } from '../api/r9cloud';

//
// Device
//
export function requestRecordDevices(userUuid) {
  return {
    type: REQUEST_RECORD_DEVICES,
    userUuid
  };
}

export function receiveRecordDevices(userUuid, data) {
  return {
    type: RECEIVE_RECORD_DEVICES,
    userUuid,
    records: { ...data }
  };
}

export function removeRecordDevice(userUuid, deviceUuid) {
  return {
    type: REMOVE_RECORD_DEVICE,
    deviceUuid
  }
}

export function fetchRecordDevices(principalId) {
  return (dispatch, getState) => {
    dispatch(requestRecordDevices(principalId));
    return queryRecordDevices(principalId)
      .then(response => {
        if (response.status === 200) {
          const devices = getState().devices;
          const records = response.data;

          let sortdev = Object.keys(records).reduce((acc, uuid) => {
            let device = records[uuid];

            if (devices[uuid] === undefined) {
              device.history = true;
              acc.history.push(device);
            } else {
              device.history = false;
              acc.current.push(device);
            }
            return acc;
          }, { current: [], history: []});

          sortdev.current.sort((a, b) => {
            return (a.createdAt > b.createdAt) ? -1 : (a.createdAt < b.createdAt) ? 1 : 0;
          });

          sortdev.history.sort((a, b) => {
            return (a.createdAt > b.createdAt) ? -1 : (a.createdAt < b.createdAt) ? 1 : 0;
          });

          return dispatch(receiveRecordDevices(principalId, sortdev));
        }
      })
      .catch((err) => console.error(err));
  };
}

import React from 'react';
import { Auth, Logger } from 'aws-amplify';

const logger = new Logger('SignOut');

export default class SignOut extends React.Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    Auth.signOut()
      .then(() => logger.info('sign out success'))
      .catch(err => logger.info('sign out error', err));
  }

  render() {
    return (
      <span onClick={this.signOut} >
        Sign Out
      </span>
    )
  }
}

import {
  SELECT_SIDEBAR_ITEM,
  RECEIVE_ALARM_LIST
} from '../actions/types';

const initialState = {
  sidebarItem: '/dashboard/device/list',
  alarms: []
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_SIDEBAR_ITEM:
      return {
        ...state,
        sidebarItem: action.sidebarItem
      };
    case RECEIVE_ALARM_LIST:
      return {
        ...state,
        alarms: action.alarms
      };
    default:
      return state;
  }
};

export default dashboard;

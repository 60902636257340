import React, { Component } from 'react';

import { Authenticator, SignIn, SignUp, ConfirmSignUp, VerifyContact, ForgotPassword } from 'aws-amplify-react';

import * as Custom from '../auth';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

    this.state = {
      authState: '',
    };
  }

  handleAuthStateChange(state) {
    if (state === 'signedIn') {
      /* Do something when the user has signed-in */
    }

    this.setState({ authState: state });
  }

  render() {
    const { authState } = this.state;

    console.debug('authState:', authState);

    return (
      <Authenticator hideDefault={true} onStateChange={this.handleAuthStateChange}>
        <Custom.SignIn override={SignIn}/>
        <Custom.SignUp override={SignUp}/>
        <Custom.ConfirmSignUp override={ConfirmSignUp}/>
        <Custom.ForgotPassword overide={ForgotPassword}/>
        <VerifyContact/>
      </Authenticator>
    );
  }
}

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React, { Component } from 'react';

export default class Toolbar extends Component {
  render() {
    const { items, onClick } = this.props;
    return (
      <div className="row toolbar">
        <div className="col">
          <div className="btn-group float-right">
            {items && items.map(item => (
              <button
                key={item.name}
                name={item.name}
                type="button"
                className="btn btn-outline-primary"
                onClick={onClick}
              >
                <span className={'icon ' + item.icon}/>
              </button>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
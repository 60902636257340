import React, { Component } from 'react';

class DeviceHistoryTable extends Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange(index, e) {
    const { onChange } = this.props;

    const target = e.target;
    const { name } = target;

    onChange(index, target.checked);
  }

  handleSelect(eventKey, event) {
    const { onMenu } = this.props;

    switch(eventKey) {
      case 'check':
        onMenu(eventKey);
        break;
      case 'delete':
        onMenu(eventKey);
        break;
    }
  }

  render() {
    const { devices, selectedUuids } = this.props;

    return (
      <table className="table">
        <thead>
        <tr>
          <th width="5%" style={{ minWidth: 60 }}>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text pl-0 border-0 bg-transparent">
                  <input name="all" type="checkbox" onChange={this.handleChange.bind(this, -1)}/>
                </div>
              </div>
            </div>
          </th>
          <th width="15%">종류</th>
          <th width="20%">이름</th>
          <th width="20%">아이디</th>
          <th width="auto">날짜</th>
        </tr>
        </thead>
        <tbody>
        {Array.isArray(devices) && devices.map((device, index) => {
          return (
            <tr key={device.uuid} style={{fontWeight: device.history ? 'lighter' : 'bold'}}>
              <td>
                <input
                  name="one"
                  type="checkbox"
                  checked={selectedUuids.indexOf(device.uuid) !== -1}
                  onChange={this.handleChange.bind(this, index)}
                />
              </td>
              <td>{device.type}</td>
              <td>{device.nickname}</td>
              <td>{device.id}</td>
              <td>{new Date(device.createdAt).toLocaleString()}</td>
            </tr>
          )
        })}
        </tbody>
      </table>
    )
  }
}
export default DeviceHistoryTable;

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions";
import { Dropdown } from "react-bootstrap";

import { Toolbar } from "../../components/common";
import r9iot from "../../api/r9iot";
import Place from "../device/Place";
import PlaceAdd from "../device/PlaceAdd";
import PlaceSetting from "../device/PlaceSetting";
import PlaceDelete from "../device/PlaceDelete";
import DeviceSubscribe from "../device/DeviceSubscribe";
import DeviceSetting from "../device/DeviceSetting";
import DeviceResult from "../device/DeviceResult";
import DeviceUnsubscribe from "../device/DeviceUnsubscribe";
import UserSetting from "../device/UserSetting";
import CSVFileDownload from "../device/CSVFileDownload";

import {
    queryUserPut,
    queryNotificationEmailPost,
    queryNotificationEmailDelete,
    queryNotificationSmsPost,
    queryNotificationSmsDelete,
} from "../../api/r9cloud";
import {
    queryDeviceSubscribe,
    queryPutDeviceSetting,
    queryDeviceUnsubscribe,
    queryPutPlace as queryPutDevicePlace,
} from "../../api/device";
import { queryTraitPutAlarm } from "../../api/alarm";
import { queryPlacesPost, queryPutPlace, queryDeletePlace } from "../../api/place";

const toolBar = [
    { name: "setting", icon: "icon-cog" },
    { name: "refresh", icon: "icon-cw" },
];

const DropdownToolbar = React.forwardRef(({ children, onClick }, ref) => (
    <div
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="btn btn-outline-primary toolbar-dropdown"
    >
        {children}
    </div>
));

class Device extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddPlace: false,
            showAddDevice: false,
            showUserSettings: false,
            showPlaceSettings: false,
            showPlaceDelete: false,
            showDeviceSettings: false,
            showUnsubscribeDevice: false,
            showResult: false,
            showFileDownload: false,
        };
    }

    componentDidMount() {
        const { principalId } = this.props;
        this.props.actions.fetchDeviceList(principalId);
        this.props.actions.fetchPlaces(principalId);
    }

    handleToolbar = (e) => {
        e.preventDefault();

        const { devices, principalId } = this.props;
        //
        // event의 target은 span element을 return함
        //
        const name = e.currentTarget.name;
        switch (name) {
            case "refresh":
                Object.keys(devices).forEach((uuid) => {
                    this.props.actions.fetchDeviceState(principalId, uuid);
                });
                break;
            case "setting":
                this.setState({ showUserSettings: true });
                break;
            default:
                break;
        }
    };

    handleSelect = (eventKey, event) => {
        console.debug(eventKey);
        switch (eventKey) {
            case "place":
                this.setState({ showAddPlace: true });
                break;
            case "device":
                this.setState({ showAddDevice: true });
                break;
            default:
                break;
        }
    };

    handleSelectPlaceMenu = (eventKey, place) => {
        switch (eventKey) {
            case "settings":
                this.setState({
                    showPlaceSettings: true,
                    selectedPlace: place,
                });
                break;
            case "delete":
                this.setState({
                    showPlaceDelete: true,
                    selectedPlace: place,
                });
                break;
            default:
                break;
        }
    };

    handleSelectDeviceMenu = (eventKey, device) => {
        switch (eventKey) {
            case "settings":
                this.setState({
                    showDeviceSettings: true,
                    setting: {
                        ...device,
                    },
                });
                break;
            case "filedownload":
                this.setState({
                    showFileDownload: true,
                    download: {
                        ...device,
                    },
                });
                break;
            case "delete":
                this.setState({
                    showUnsubscribeDevice: true,
                    deleting: {
                        ...device,
                    },
                });
                break;
            default:
                break;
        }
    };

    handleApplyAddPlace = async (place) => {
        const { principalId } = this.props;
        this.setState({ showAddPlace: false });
        await queryPlacesPost(principalId, place).catch((err) => console.error(err));

        this.props.actions.fetchPlaces(principalId);
    };

    handleCancelAddPlace = () => {
        this.setState({ showAddPlace: false });
    };

    handleApplyPlaceSettings = async (place) => {
        const { principalId } = this.props;
        const { uuid, ...rest } = place;
        await queryPutPlace(principalId, uuid, rest).catch((err) => console.error(err));

        this.setState({ showPlaceSettings: false, selectedPlace: undefined });
    };

    handleCancelPlaceSettings = () => {
        this.setState({ showPlaceSettings: false });
    };

    handleApplyPlaceDelete = async (place) => {
        const { principalId } = this.props;
        const { selectedPlace } = this.state;
        let res = await queryDeletePlace(principalId, selectedPlace.uuid).catch((err) => console.error(err));

        this.setState({ showPlaceDelete: false, selectedPlace: undefined });
        this.props.actions.fetchPlaces(principalId);
    };

    handleCancelPlaceDelete = () => {
        this.setState({ showPlaceDelete: false });
    };

    handleAddHub = (hubId) => {
        const { principalId } = this.props;

        r9iot
            .getHub(principalId, hubId)
            .then((response) => {
                const deviceUuid = response.data.uuid;
                return queryDeviceSubscribe(principalId, deviceUuid)
                    .then((response) => {
                        if (response.status === 200) {
                            this.showResultModal("네모안허브가 구독 되었습니다.", 2000);
                            this.props.actions.addDeviceAndFetchDevice(principalId, deviceUuid);
                        } else {
                            this.showResultModal("네모안허브 구독을 실패했습니다.", 2000);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.showResultModal("네모안허브 구독을 실패했습니다.", 2000);
                    });
            })
            .catch((err) => {
                console.error(err);
                this.showResultModal("네모안허브 구독을 실패했습니다.", 2000);
            });

        this.setState({ showAddDevice: false });
    };

    handleAddDevice = (device) => {
        const { principalId } = this.props;

        this.setState({ showAddDevice: false });

        queryDeviceSubscribe(principalId, device.uuid)
            .then((response) => {
                if (response.status === 200) {
                    return this.props.actions.addDeviceAndFetchDevice(principalId, device.uuid).then((result) => {
                        this.showResultModal("디바이스가 구독 되었습니다.", 2000);
                    });
                } else {
                    this.showResultModal("디바이스 구독을 실패했습니다.", 2000);
                }
            })
            .catch((err) => {
                console.error(err.response);
                this.showResultModal("디바이스 구독을 실패했습니다.", 2000);
            });
    };

    handleCancelAddDevice = () => {
        this.setState({ showAddDevice: false });
    };

    handleApplyDeviceSettings = (values) => {
        const { principalId } = this.props;
        const { setting } = this.state;
        let ps = [];

        ps.push(
            queryPutDevicePlace(principalId, setting.uuid, {
                placeUuid: values.placeUuid,
            })
        );

        Object.keys(values.alarms).map((uuid) => {
            const alarm = values.alarms[uuid];
            let params = {
                ...alarm,
            };
            ps.push(queryTraitPutAlarm(principalId, uuid, params));
        });

        ps.push(
            queryPutDeviceSetting(principalId, setting.uuid, {
                nickname: values.nickname,
                memo: values.memo,
            })
        );

        if (setting.type === "thermometer") {
            //
            // TODO: AS400 을 위한 내용임...
            //
            const {
                samplingTemperature: deltatemp,
                samplingHumidity: deltahumidity,
                periodicReport,
                periodicReportInterval,
            } = values.thermometer;

            let params = {
                mode: {
                    deltatemp,
                    deltahumidity,
                    reporttime: periodicReportInterval * 60,
                    // delta event는 항상 enable
                    enable: 0x30,
                    mask: 0x30,
                },
            };

            if (periodicReport) {
                params.mode.enable |= 0x40;
                params.mode.mask |= 0x40;
            } else {
                params.mode.mask |= 0x40;
            }

            Object.keys(values.alarms).forEach((uuid) => {
                const alarm = values.alarms[uuid];
                const trait = setting.traits[uuid];
                if (trait.name === "temperature") {
                    if (alarm.alarm) {
                        params.mode.enable |= 0x04;
                        params.mode.mask |= 0x04;

                        if (typeof alarm.alarmCondition !== "undefined") {
                            if (typeof alarm.alarmCondition.min !== "undefined") {
                                params.mode.mintemp = alarm.alarmCondition.min;
                            }
                            if (typeof alarm.alarmCondition.max !== "undefined") {
                                params.mode.maxtemp = alarm.alarmCondition.max;
                            }
                        }
                    } else {
                        params.mode.mask |= 0x04;
                    }
                }
                if (trait.name === "humidity") {
                    if (alarm.alarm) {
                        params.mode.enable |= 0x08;
                        params.mode.mask |= 0x08;

                        if (typeof alarm.alarmCondition !== "undefined") {
                            if (typeof alarm.alarmCondition.min !== "undefined") {
                                params.mode.minhumi = alarm.alarmCondition.min;
                            }
                            if (typeof alarm.alarmCondition.max !== "undefined") {
                                params.mode.maxhumi = alarm.alarmCondition.max;
                            }
                        }
                    } else {
                        params.mode.mask |= 0x08;
                    }
                }
            });

            ps.push(r9iot.setChildState(principalId, setting.id, params));
        }

        Promise.all(ps)
            .then((result) => {
                console.debug(result);
                this.props.actions.fetchDevice(principalId, setting.uuid);
                this.showResultModal("Success", 1500);
            })
            .catch((err) => {
                console.debug(err);
                this.showResultModal("Fail", 1500);
            });

        this.setState({ showDeviceSettings: false, setting: undefined });
        this.props.actions.fetchPlaces(principalId);
    };

    handleCancelDeviceSettings = () => {
        this.setState({
            showDeviceSettings: false,
            setting: undefined,
        });
    };

    handleApplyDownload = () => {
        this.setState({
            showFileDownload: false,
            download: undefined,
        });
    };

    handleCancelDownload = () => {
        this.setState({
            showFileDownload: false,
            download: undefined,
        });
    };

    handleApplyDeviceDelete = () => {
        const { principalId } = this.props;
        const { deleting } = this.state;

        this.setState({ showUnsbuscribeDevice: false });

        // push message disable
        queryDeviceUnsubscribe(principalId, deleting.uuid)
            .then((result) => {
                this.props.actions.removeDevice(deleting.uuid);
                this.showResultModal("디바이스가 구독 취소 되었습니다.", 2000);
            })
            .catch((err) => {
                this.showResultModal("디바이스 구독 취소를 실패했습니다.", 2000);
                console.error(err);
            });

        this.setState({ showUnsubscribeDevice: false, deleting: undefined });
    };

    handleCancelDeviceDelete = () => {
        this.setState({ showUnsubscribeDevice: false });
    };

    handleResultClose = () => {
        this.setState({ showResult: false });
    };

    showResultModal = (text, timeout) => {
        this.setState({
            showResult: true,
            showResultText: text,
        });

        this.hideResultModal(timeout);
    };

    hideResultModal(msec) {
        setTimeout(() => {
            this.setState({ showResult: false });
        }, msec);
    }

    handleApplyUserSettings = (values) => {
        const { principalId } = this.props;
        const { pushNotification, emailNotification, smsNotification, oldPhones, phones, oldEmails, emails } = values;

        let ps = [];
        ps.push(
            queryUserPut(principalId, {
                pushNotification,
                smsNotification,
                emailNotification,
            })
        );
        if (smsNotification && oldPhones[0] !== phones[0]) {
            ps.push(
                queryNotificationSmsDelete(principalId, oldPhones).then((result) => {
                    console.debug(result);
                    return queryNotificationSmsPost(principalId, phones);
                })
            );
        }
        if (emailNotification && oldEmails[0] !== emails[0]) {
            ps.push(
                queryNotificationEmailDelete(principalId, oldEmails).then((result) => {
                    console.debug(result);
                    return queryNotificationEmailPost(principalId, emails);
                })
            );
        }

        Promise.all(ps)
            .then((result) => {
                console.debug(result);
                this.showResultModal("Success", 1500);
            })
            .catch((err) => {
                console.error(err);
                this.showResultModal("Fail", 1500);
            });
        this.setState({ showUserSettings: false });
    };

    handleCancelUserSettings = () => {
        this.setState({ showUserSettings: false });
    };

    render() {
        const { user, devices, places, principalId } = this.props;
        const {
            showAddPlace,
            showAddDevice,
            showUserSettings,
            showPlaceSettings,
            showPlaceDelete,
            showDeviceSettings,
            showUnsubscribeDevice,
            showResult,
            showResultText,
            selectedPlace,
            setting,
            deleting,
            download,
            showFileDownload,
        } = this.state;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <Dropdown className="dropdown" onSelect={this.handleSelect}>
                            <Dropdown.Toggle as={DropdownToolbar}>
                                <span className="icon icon-plus" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={"device"}>디바이스 추가</Dropdown.Item>
                                <Dropdown.Item eventKey={"place"}>장소 추가</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="col">
                        <Toolbar items={toolBar} onClick={this.handleToolbar} />
                    </div>
                </div>

                {Object.keys(places).map((uuid) => {
                    let place = { uuid, ...places[uuid] };
                    let placeDevices = Object.keys(devices).reduce((acc, uuid) => {
                        let dev = devices[uuid];
                        if (place.devices.includes(dev.uuid)) {
                            acc.push(dev);
                        }
                        return acc;
                    }, []);

                    return (
                        <Place
                            key={place.uuid}
                            place={place}
                            principalId={principalId}
                            devices={placeDevices}
                            onSelectMenu={this.handleSelectPlaceMenu}
                            onSelectDeviceMenu={this.handleSelectDeviceMenu}
                        />
                    );
                })}
                {(() => {
                    let placeDevices = Object.keys(this.props.devices).reduce((acc, uuid) => {
                        let dev = this.props.devices[uuid];
                        for (let uuid in places) {
                            let place = places[uuid];
                            if (place.devices.includes(dev.uuid)) {
                                return acc;
                            }
                        }
                        acc.push(dev);
                        return acc;
                    }, []);

                    return placeDevices.length > 0 ? (
                        <Place
                            principalId={principalId}
                            devices={placeDevices}
                            onSelectMenu={this.handleSelectPlaceMenu}
                            onSelectDeviceMenu={this.handleSelectDeviceMenu}
                        />
                    ) : null;
                })()}

                <PlaceAdd
                    title="장소 추가"
                    principalId={principalId}
                    show={showAddPlace}
                    onApply={this.handleApplyAddPlace}
                    onCancel={this.handleCancelAddPlace}
                />

                {selectedPlace && (
                    <PlaceSetting
                        title="설정"
                        principalId={principalId}
                        place={selectedPlace}
                        show={showPlaceSettings}
                        onApply={this.handleApplyPlaceSettings}
                        onCancel={this.handleCancelPlaceSettings}
                    />
                )}

                {selectedPlace && (
                    <PlaceDelete
                        place={selectedPlace}
                        show={showPlaceDelete}
                        onApply={this.handleApplyPlaceDelete}
                        onCancel={this.handleCancelPlaceDelete}
                    />
                )}

                <DeviceSubscribe
                    title="디바이스 구독"
                    principalId={principalId}
                    show={showAddDevice}
                    onAddHub={this.handleAddHub}
                    onAddDevice={this.handleAddDevice}
                    onCancel={this.handleCancelAddDevice}
                />

                {setting && (
                    <DeviceSetting
                        title="설정"
                        principalId={principalId}
                        device={setting}
                        places={places}
                        show={showDeviceSettings}
                        onApply={this.handleApplyDeviceSettings}
                        onCancel={this.handleCancelDeviceSettings}
                    />
                )}
                {download && (
                    <CSVFileDownload
                        title="CSV Download"
                        principalId={principalId}
                        device={download}
                        places={places}
                        show={showFileDownload}
                        onApply={this.handleApplyDownload}
                        onCancel={this.handleCancelDownload}
                    />
                )}

                {deleting && (
                    <DeviceUnsubscribe
                        show={showUnsubscribeDevice}
                        onApply={this.handleApplyDeviceDelete}
                        onCancel={this.handleCancelDeviceDelete}
                    />
                )}

                <DeviceResult show={showResult} text={showResultText} onClose={this.handleResultClose} />

                <UserSetting
                    show={showUserSettings}
                    principalId={principalId}
                    user={user}
                    onApply={this.handleApplyUserSettings}
                    onCancel={this.handleCancelUserSettings}
                />
            </React.Fragment>
        );
    }
}

Device.defaultProps = {
    places: {},
    devices: {},
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        devices: state.devices,
        places: state.places,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Device);

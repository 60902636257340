import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import { InputRow } from "../../components/common";

export default class ReportPeriodic extends Component {
  constructor(props) {
    super(props);

    const { report } = props;

    this.state = {
      validInputs: true,
      dailyReport: report.dailyReport,
      dailyReportTime: report.dailyReportTime === undefined ? 0 : report.dailyReportTime,
      weeklyReport: report.weeklyReport,
      weeklyReportDay: report.weeklyReportDay === undefined ? 1 : report.weeklyReportDay,
      monthlyReport: report.monthlyReport,
      monthlyReportDate: report.monthlyReportDate || 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : parseInt(target.value);

    this.setState({ validInputs: true, [name]: value });
  };

  handleApply() {
    const { onApply } = this.props;

    const {
      validInputs,
      ...rest
    } = this.state;

    onApply({
      ...rest
    });
  };

  handleCancel() {
    this.props.onCancel();
  };

  render () {
    const modalTitle = this.props.title;
    const {
      dailyReport,
      dailyReportTime,
      weeklyReport,
      weeklyReportDay,
      monthlyReport,
      monthlyReportDate,
    } = this.state;

    return (
      <Modal
        show={this.props.show}
        size="lg"
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputRow label="리포트 유형 ">
            <div className="input-group mb-0">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    name="dailyReport"
                    checked={dailyReport}
                    onChange={this.handleChange}
                  />
                </div>
                <span className="input-group-text">일간</span>
              </div>
              <input
                name="dailyReportTime"
                type="number"
                className="form-control"
                placeholder="매일 정시에(0~23) 리포트 작성"
                value={dailyReportTime}
                onChange={this.handleChange}
                min={0}
                max={23}
              />
              <div className="input-group-append">
                <span className="input-group-text">시</span>
              </div>
            </div>
            <div className="mb-3">
              <span className="input-hint">{`이전 일 ${dailyReportTime}시 ~ 다음 일 ${dailyReportTime === 0 ? 23 : dailyReportTime -1}시`}</span>
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    name="weeklyReport"
                    checked={weeklyReport}
                    onChange={this.handleChange}
                  />
                </div>
                <span className="input-group-text">주간</span>
              </div>

              <select
                className="custom-select"
                name="weeklyReportDay"
                value={weeklyReportDay.toString()}
                onChange={this.handleChange}
              >
                <option value="1">매주 월요일 ~ 일요일</option>
                <option value="2">매주 화요일 ~ 월요일</option>
                <option value="3">매주 수요일 ~ 화요일</option>
                <option value="4">매주 목요일 ~ 수요일</option>
                <option value="5">매주 금요일 ~ 목요일</option>
                <option value="6">매주 토요일 ~ 금요일</option>
                <option value="0">매주 일요일 ~ 토요일</option>
              </select>
              <div className="input-group-append">
                <span className="input-group-text">요일</span>
              </div>
            </div>

            <div className="input-group mb-0">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    name="monthlyReport"
                    checked={monthlyReport}
                    onChange={this.handleChange}
                  />
                </div>
                <span className="input-group-text">월간</span>
              </div>
              <input
                name="monthlyReportDate"
                type="number"
                className="form-control"
                placeholder="이전달 (1~31)일 리포트 작성"
                value={monthlyReportDate}
                onChange={this.handleChange}
                min={1}
                max={31}
              />
              <div className="input-group-append">
                <span className="input-group-text">일</span>
              </div>
            </div>
            <div className="mb-3">
              <span className="input-hint">{`이전 달 ${monthlyReportDate}일 ~ 다음 달 ${monthlyReportDate === 1 ? '말' : monthlyReportDate -1}일`}</span>
            </div>

          </InputRow>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleApply}>Apply</button>
          <button className="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ReportPeriodic.defaultProps = {
  show: false
};

import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

export default class PlaceDelete extends Component {
  constructor(props) {
    super(props);
  }

  handleApply = () => {
    this.props.onApply();
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>장소 삭제</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>장소를 삭제합니다.</span>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={this.handleApply}>장소 삭제</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

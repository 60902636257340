import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

import DeviceTraitsAlarm from "./DeviceTraitsAlarm";
import ThermometerSetting from "./ThermometerSetting";
import { InputRow } from '../../components/common';
import {
  queryGetPlace,
} from '../../api/device'


export default class DeviceSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nickname: props.device.nickname,
      placeUuid: '',
      memo: props.device.memo || '',
      record: props.device.record,
      alarms: {},
      thermometer: {}
    };

    this.handleAlarmChange = this.handleAlarmChange.bind(this);
    this.handleThermometerChange = this.handleThermometerChange.bind(this);
  }

  componentDidMount() {
    const { principalId, device, places } = this.props;

    queryGetPlace(principalId, device.uuid)
      .then(result => {
        const { placeUuid } = result.data;
        this.setState({ placeUuid });
      })
      .catch(err => {
        console.error(err);
        this.setState({ placeUuid: Object.keys(places)[0] });
      });
  };

  handleApply = () => {
    const { onApply } = this.props;
    onApply({
      ...this.state
    });
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [name]: value });
  };

  handleAlarmChange(traitUuid, alarm) {
    this.setState((state) => ({
      alarms: {
        ...state.alarms,
        [traitUuid]: { ...alarm }
      }
    }))
  };

  handleThermometerChange(settings) {
    this.setState((state) => ({
      thermometer: {
        ...state.thermometer,
        ...settings,
      }
    }))
  };

  render() {
    const { principalId, places, device, show } = this.props;
    const { nickname, memo } = this.state;

    return (
      <Modal
        show={show}
        size={"lg"}
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>설정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputRow label="이름">
            <input
              name="nickname"
              type="text"
              className="form-control"
              placeholder ="nickname"
              value={nickname}
              onChange={this.handleChange}
            />
          </InputRow>
          <InputRow label="장소">
            <select
              className="form-control"
              name="placeUuid"
              aria-label="select placeUuid"
              value={this.state.placeUuid}
              onChange={this.handleChange}
            >
              {Object.keys(places).map(uuid => {
                let place = places[uuid];
                return (<option value={uuid}>{place.nickname}</option>);
              })}
            </select>
          </InputRow>
          <InputRow label="메모">
            <input
              name="memo"
              type="text"
              maxLength="255"
              className="form-control"
              placeholder="장치에 대해 간단히 메모 해주세요."
              value={memo}
              onChange={this.handleChange}
            />
          </InputRow>
          <InputRow label="디바이스 아이디">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              id="serialno"
              value={device.id}
            />
          </InputRow>
          {(device.type !== 'gateway') &&
          <>
            <InputRow label="알람 조건">
              {device && <DeviceTraitsAlarm
                principalId={principalId}
                device={device}
                alarms={this.state.alarms}
                onChange={this.handleAlarmChange}
              />}
            </InputRow>
          </>}

          {device.type === "thermometer"  &&
          <ThermometerSetting
            principalId={principalId}
            device={device}
            settings={this.state.thermometer}
            onChange={this.handleThermometerChange}
          />}

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleApply}>적용</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

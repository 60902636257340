import React, { PureComponent } from 'react';
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "../../components/common/DropdownToggle";

import {InfoCard, ItemRow} from '../../components/common'
import {REPORT_WRITERS, dateOpt, getStatistics} from './report';

export default class ReportItem extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleSelect = (eventKey, event) => {
    console.debug(eventKey);
    switch(eventKey) {
      case 'setting':
        this.props.onModify(this.props.report);
        break;
      case 'periodicReport':
        this.props.onPeriodicCreate(this.props.report);
        break;
      case 'delete':
        this.props.onDelete(this.props.report);
        break;
      case 'writeReport':
        this.props.onCreatePdf(this.props.report);
        break;
    }
  };

  render() {
    const { report } = this.props;
    const { title, description, startDate, endDate, traits , records } = report;

    let reportHandler = REPORT_WRITERS[report.deviceType || 'thermometer'].find(e => {
      return e.name === report.writer;
    });

    const menu = (
      <Dropdown className="dropdown" onSelect={this.handleSelect}>
        <Dropdown.Toggle as={DropdownToggle}>{title}</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey={"setting"}>설정</Dropdown.Item>
          <Dropdown.Item eventKey={"periodicReport"}>자동 리포트 설정</Dropdown.Item>
          <Dropdown.Item eventKey={"writeReport"}>리포트 생성</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey={"delete"}>삭제</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );

    const {
      hour,
      ...options
    } = dateOpt;

    let atraits = Object.keys(traits).map(uuid => {
      return { uuid, label: traits[uuid].label };
    }).sort((a, b) =>  {
      const la = a.label;
      const lb = b.label;
      return (la < lb) ? -1 : (la > lb) ? 1 : 0;
    });

    const stats = atraits.reduce((acc, e) => {
      if (records[e.uuid]) {
        const stat = getStatistics(records[e.uuid], startDate, endDate);
        acc.push({ label: e.label, ...stat[0] });
      } else {
        acc.push({ label: e.label });
      }
      return acc;
    }, []);

    return (
      <InfoCard className="infocard-primary mb-4" header={menu}>
        <ItemRow label={"메모"}>{description}</ItemRow>
        <ItemRow label={"날짜"}>
          {new Date(startDate).toLocaleString('ko-KR', options)} ~
          {new Date(endDate).toLocaleString('ko-KR', options)}
        </ItemRow>
        <ItemRow className="mt-3" label="최소/최대/평균">
          {stats.map((stat, index) => {
            return (
              <p key={index}>
                {`${stat.label}: ${stat.min} / ${stat.max} / ${stat.average && stat.average.toFixed(1)}`}
              </p>
            );
          })}
        </ItemRow>
        {reportHandler && <reportHandler.Chart report={report}/>}
      </InfoCard>
    )
  }
}

import React, { useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { InputRow } from "../../components/common";
import { queryRecordsByTraitId } from "../../api/record";
import { CSVLink } from "react-csv";
import _ from "lodash";
import moment from "moment-timezone";
import ReactDatePicker from "react-datepicker";

const CSVFileDownload = (props) => {
    const { principalId, title, show, onApply, onCancel, device } = props;
    const [csvButtonVisible, setCsvButtonVisible] = useState(false);
    const [csvRawData, setCsvRawData] = useState([]);

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    // 설정된 기간으로 record 데이터를 받아와서 상태변수에 주입한다.
    const onClickSearchButton = () => {
        let records = {};
        let ps = [];
        if (startDate && endDate) {
            for (let trait in device?.traits) {
                //console.log("alaveiw trait", trait);

                const name = device.traits[trait].name;
                if (device.traits[trait].record === 1) {
                    ps.push(
                        queryRecordsByTraitId(principalId, {
                            uuid: trait,
                            start: startDate,
                            end: endDate,
                        })
                            .then((result) => {
                                //records.push(_.map(result, (item) => ({ ...result, name })));
                                //records.push(result);
                                records[name] = result;
                            })
                            .catch((err) => console.error(err))
                    );
                }
            }

            Promise.all(ps).then((result) => {
                //console.log("alaveiw records end ", records);
                setCsvRawData(records);
                setCsvButtonVisible(true);
            });

            //onApply && onApply();
        } else {
        }
    };

    function mergeByDate(des, src) {
        let flag = false;
        for (const i in src) {
            flag = false;

            for (const value in des) {
                // 중복 체크
                if (des[value].date === src[i].date) {
                    const temp = { ...des[value], ...src[i] };

                    // delete
                    const idx = des.indexOf(des[value]);
                    if (idx > -1) des.splice(idx, 1);

                    // push
                    des.push(temp);

                    flag = true;
                }
            }
            if (!flag) {
                des.push(src[i]);
            }
        }

        return des;
    }

    const CsvDownloadLinkRenderOneFile = useMemo(() => {
        /**
         {date: '', value: ''}
         형식으로 되어있어서 value 항목 이름을 각각 key 이름으로 변경한뒤 머지수행
         */

        let mergeData = [];
        for (let data in csvRawData) {
            /*
            mergeData = _.merge(
                mergeData,
                _.map(csvRawData[data], (item) => ({ date: item?.date, [data]: item?.value }))
            );
            */
           /*
           mergeData = [
               ...mergeData,
               ..._.map(csvRawData[data], (item) => ({ date: item?.date, [data]: item?.value })),
           ];
           */
           mergeData = mergeByDate(mergeData, 
                _.map(csvRawData[data], (item) => ({ date: item?.date, [data]: (item?.value === true ? 1 : (item?.value === false ? 0 : item?.value)) }))
                //_.map(csvRawData[data], (item) => ({ date: item?.date, [data]: item?.value }))
            );
        }

        // 날짜로 소팅
        const result = mergeData.sort((a, b) => new Date(a.date) - new Date(b.date));

        /**
         * 열 만들기 
         * 
         * sample
            const headers = [
                { label: "First Name", key: "firstname" },
                { label: "Last Name", key: "lastname" },
                { label: "Email", key: "email" },
            ];
         */

        /*
        // label을 기준으로 정렬. array가 매번 바뀌는 것을 방지
        let headers = _.sortBy(
            _.map(Object.keys(csvRawData), (item) => ({ label: item, key: item })),
            "label"
        );

        // date 항목을 맨 앞으로 위치
        headers.splice(0, 0, { label: "일시", key: "date" });
        */
        // 24.01.25 dshwang 요청으로 이름,순서 고정. 다시 원복하려면 위 주석으로 변경하면 됨.
        const headers = [
            { label: "일시", key: "date" },
            { label: "외기센서 open", key: "ext_open" },
            { label: "외기센서 short", key: "ext_short" },
            { label: "열선1 open", key: "line1_cable_open" },
            { label: "열선1 short", key: "line1_cable_short" },
            { label: "배관센서1 open", key: "line1_open" },
            { label: "배관센서1 short", key: "line1_short" },
            { label: "열선2 open", key: "line2_cable_open" },
            { label: "열선2 short", key: "line2_cable_short" },
            { label: "배관센서2 open", key: "line2_open" },
            { label: "배관센서2 short", key: "line2_short" },
            { label: "열선1", key: "on1" },
            { label: "열선2", key: "on2" },
            { label: "배관온도1", key: "curtemp1" },
            { label: "배관온도2", key: "curtemp2" },
            { label: "외기온도", key: "ext_curtemp" },
            { label: "연결상태", key: "connected" },
        ];

        return (
            <CSVLink
                key={"csvlink"}
                headers={headers} // 선택옵션. headers가 지정되면 해당 배열값으로 열이 고정됨.
                data={result}
                filename={`${moment().format("YYYY-MM-DD_HH:mm:ss")}.csv`}
                target="_blank"
            >
                {"CSV FILE DOWNLOAD"}
                <br />
            </CSVLink>
        );
    }, [csvRawData]);

    /*
    const CsvDownloadLinkRender = useMemo(() => {
        let linkArray = [];
        console.log("alaveiw csvRawData", csvRawData);
        for (let data in csvRawData) {
            // 종류별로 csv 파일 따로만드는 부분
            console.log("alaveiw data", data);
            console.log("alaveiw data", csvRawData[data]);
            const filename = `${data}_${moment().format("YYYY-MM-DD_HH:mm:ss")}.csv`;
            linkArray.push(
                <CSVLink
                    key={data}
                    data={csvRawData[data]}
                    filename={filename}
                    //className="btn btn-primary"
                    target="_blank"
                >
                    {filename}
                    <br />
                </CSVLink>
            );
        }

        return linkArray;
    }, [csvRawData]);
    */
    return (
        <>
            <Modal
                show={show}
                size="lg"
                /*
                // 모달창 외부 클릭시 모달 닫히는것을 방지하기 위해 주석으로 막음.
                // 대신에 닫기 버튼 추가.
                onHide={() => {
                    onCancel && onCancel();
                }}
                */
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputRow label="기간">
                        <ReactDatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                        />
                    </InputRow>
                    {/** 각각 csv 파일 따로 생성, 231018 dshwang 요청으로 1개파일로 합성하기로 변경 */}
                    {/*
                        csvButtonVisible && CsvDownloadLinkRender
                    */}

                    {/** 전체 항목에 대해 머지후 1개의 파일로 생성 */}
                    {csvButtonVisible && CsvDownloadLinkRenderOneFile}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn"
                        onClick={() => {
                            onCancel && onCancel();
                        }}
                    >
                        닫기
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            onClickSearchButton();
                        }}
                    >
                        검색
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CSVFileDownload;

import { RECEIVE_USER } from "../actions/types";

const user = (state = {}, action) => {
  switch(action.type) {
    case RECEIVE_USER: {
      return {
        ...state,
        ...action.user
      }
    }
    default:
      return state;
  }
};

export default user;

import React from 'react';
import { Modal } from "react-bootstrap";

class DeviceAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hubId: '',
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleAddDevice = () => {
    const { hubId } = this.state;
    this.props.onApply(hubId);
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render () {
    const modalTitle = this.props.title;
    const { principalId, devices } = this.props;

    return (
      <Modal
        show={this.props.show}
        size="lg"
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label htmlFor="nickname" className="col-sm-2 col-form-label">Hub ID</label>
            <div className="col-sm-10">
              <input
                name="hubId"
                type="text"
                className="form-control"
                placeholder="Hub ID (ex: 04011234@raonix.co.kr)"
                value={this.state.hubId}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleAddDevice}>허브 추가</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeviceAdd;

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React, { Component, Fragment } from 'react';
import { chartColors, rgb2rgba } from "./utils";
import { getEventDatasets, getTraitByName } from "./report";
import ThermometerChart from "./ThermometerChart";

class Setting extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(uuid, e) {
    const { devices, onChange } = this.props;
    let traits = this.props.traits;
    const target = e.target;
    const name = target.name;

    let dev = devices.find(e => e.uuid === uuid);
    let temp = getTraitByName(dev, 'temperature');

    if (name === 'checkbox') {
      if (target.checked) {
        traits[temp.uuid] = { name: temp.name, label: dev.nickname+'온도' };
      } else {
        delete traits[temp.uuid];
      }
    }
    if (name === 'label') {
      traits[temp.uuid].label = target.value+'온도';
    }

    onChange(traits);
  };

  render() {
    const { devices, traits } = this.props;

    const compareNickname = (a, b) => {
      const na = a.nickname;
      const nb = b.nickname;
      return (na < nb) ? -1 : (na > nb) ? 1 : 0;
    };

    devices.sort(compareNickname);

    return (
      <>
        <ul className="list-group mb-3">
          {devices.reduce((listItems, dev) => {
            if (dev.type === 'thermometer') {
              let tr = getTraitByName(dev, "temperature");
              let checked = traits.hasOwnProperty(tr.uuid);
              let label = checked ? traits[tr.uuid].label.slice(0, -2) : dev.nickname;

              listItems.push((
                <li className="list-group-item" key={dev.uuid}>
                  <div className="row">
                    <div className="col-auto m-auto">
                      <input
                        name="checkbox"
                        type="checkbox"
                        checked={checked}
                        onChange={this.handleChange.bind(this, dev.uuid)}
                      />
                    </div>
                    <div className="col-2">
                      <label className="col-form-label">{dev.nickname}</label>
                    </div>
                    <div className="col">
                      <input
                        name='label'
                        className="form-control"
                        type="text"
                        placeholder="label"
                        disabled={!checked}
                        value={label}
                        onChange={this.handleChange.bind(this, dev.uuid)}
                      />
                    </div>
                  </div>
                </li>
              ));
            }
            return listItems.sort(compareNickname);
          }, [])}
        </ul>
      </>
    )
  }
}

class Chart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datasets: undefined
    }
  }

  componentDidMount() {
    const { report } = this.props;
    const datasets = getEventDatasets(report);
    this.setState({ datasets });
  }

  render() {
    const { datasets } = this.state;

    if (datasets === undefined) {
      return null;
    }

    let data = {};
    data.datasets = datasets.map((e, index) => {
      const keys = Object.keys(chartColors);
      const key = keys[index % keys.length];
      const color = chartColors[key];

      return {
        fill: false,
        // lineTension: 0.1,
        lineTension: 0,
        borderWidth: 1,
        borderColor: color,
        backgroundColor: rgb2rgba(color, 0.5),
        label: e.label,
        data: e.data,
        yAxisID: 'y-axis-temperature'
      };
    });

    return <ThermometerChart data={data} type={ThermometerTemperature.name}/>
  }
}

Setting.defaultProps = {
  settings: {
    devices: {}
  }
};

const ThermometerTemperature = {
  nickname: '온도 리포트',
  name: 'ThermometerTemperature',
  Setting,
  Chart
};

export default ThermometerTemperature;

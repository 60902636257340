import React, { Component } from 'react';
import config from '../../config'

import Nav from 'react-bootstrap/Nav'
import { withRouter } from "react-router-dom";

import logo from '../../assets/img/raonix_logo.png';
import SidebarItem from './SidebarItem';


class Sidebar extends Component {
  constructor (props) {
    super(props);

    this.state = {toggle: 0};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({toggle: (this.state.toggle) ? 0 : 1});
  }

  // TODO: new menu
  render () {
    return (
      <nav className="sidebar-nav">
        <div className="sidebar-header">
          <button className="nav-toggler nav-toggler-md sidebar-toggler" type="button" data-toggle="collapse"
                  data-target="#nav-toggleable-md" onClick={this.handleClick}>
            <span className="sr-only">Toggle nav</span>
          </button>
          <a className="sidebar-brand img-responsive" href="/">
            <div className="d-flex flex-column">
              <img className="align-self-center sidebar-brand-logo" src={logo} alt="Raonix"/>
              <span className="align-self-end sidebar-brand-version">{config.version}</span>
              {/*
              <span className="icon icon-leaf sidebar-brand-icon"/>
              <span className="align-self-center sidebar-brand-title mr-1">Raonix</span>
                */}
            </div>
          </a>
        </div>

        <div className={`nav-toggleable-md collapse ${this.state.toggle ? 'show' : ''}`} id="nav-toggleable-md">
          <Nav defaultActiveKey="/dashboard/device/list" className="nav nav-pills nav-stacked flex-column" as="ul">
            <li className="nav-header">디바이스</li>
            <Nav.Item as="li">
              <SidebarItem to="/dashboard/device/list">현재 상태</SidebarItem>
              <SidebarItem to="/dashboard/device/management">허브 관리</SidebarItem>
              <SidebarItem to="/dashboard/device/history">과거 목록</SidebarItem>
            </Nav.Item>

            <li className="nav-header">알람</li>
            <Nav.Item as="li">
              <SidebarItem to="/dashboard/alarm/list">알람 목록</SidebarItem>
            </Nav.Item>

            <li className="nav-header">보고서</li>
            <Nav.Item as="li">
              <SidebarItem to="/dashboard/report/report">보고서 작성</SidebarItem>
              <SidebarItem to="/dashboard/report/list">보고서 목록</SidebarItem>
            </Nav.Item>

            <li className="nav-header">서비스 정보</li>
            <Nav.Item as="li">
              <SidebarItem to="/dashboard/account/subscription">이용 정보</SidebarItem>
            </Nav.Item>
          </Nav>
          <hr className="visible-xs mt-3"/>
        </div>
      </nav>
    );
  }
}

export default withRouter(Sidebar);

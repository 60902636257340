/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

import {
  SELECT_SIDEBAR_ITEM,
  RECEIVE_ALARM_LIST
} from './types';

import { queryRecordAlarmGet } from '../api/r9cloud';

export function selectSidebarItem(sidebarItem) {
  return {
    type: SELECT_SIDEBAR_ITEM,
    sidebarItem
  };
}

export function receiveUnchkedAlarmList(alarms) {
  alarms.map(alarm => ({
    variant: 'info',
    ...alarm
  }));
  return {
    type: RECEIVE_ALARM_LIST,
    alarms
  };
}

export const fetchUncheckedAlarms = (principalId) => {
  return (dispatch, getState) => {
    queryRecordAlarmGet(principalId, { checked: false })
      .then(response => {
        let alarms = response.data;
        dispatch(receiveUnchkedAlarmList(alarms));
      })
      .catch(error => console.error(error));
  }
};

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React, { PureComponent } from 'react';

export default class PaymentTerms extends PureComponent {
  render() {
    return (
      <div>
        <strong>정기결제 약관</strong>
        <div>
          <strong>제1조 (약관의 적용)</strong>
          <p>신용카드, 직불카드, 간편결제(이하 ‘결제수단’)의 정기결제 방법(이하 '정기결제'라 한다)에 의하여 각종 대금을 납부 하고자 하는 자(이하 '고객'이라 한다)와 고객의 지급수단을 보유한 금융기관에 대하여 이 약관을 적용합니다.</p>
        </div>

        <div>
          <strong>제2조 (출금)</strong>
          <p>정기결제 고객이 지급하여야 할 요금이 있는 경우 별도의 통지 없이 고객의 지정결제수단에서 출금을 의뢰하는 기관(이하 '당사'라 한다)이 지정하는 납기일에 결제합니다. 다만, 정기결제 경우에는 금융기관의 약관이나 약정서의 규정에 불구하고 결제청구서, 기타 관련 증서 없이 정기결제 처리절차에 의하여 결제할 수 있습니다.</p>
        </div>

        <div>
          <strong>제3조 (과실책임)</strong>
          <p>자동 “결제수단”의 잔액 또는 한도(자동대출 약정이 있는 경우 대출한도 포함)가 결제일 현재 “당사”의 청구금액보다 부족하거나, 결제의 지급제한, 연체 등 고객의 과실에 의하여 결제가 불가능한 경우의 손해는 고객의 책임으로 한다.</p>
        </div>

        <div>
          <strong>제4조 (부분출금)</strong>
          <p>부분출금방식으로 승인된 금융기관의 경우 당사의 청구금액에 비해 결제잔액이 부족하여도 잔액을 전액 출금할 수 있습니다.</p>
        </div>

        <div>
          <strong>제5조 (출금우선순위)</strong>
          <p>납기일에 동일한 수종의 “정기결제” 청구가 있는 경우의 출금 우선 순위는 “금융기관”에서 정하는 바에 따릅니다.</p>
        </div>

        <div>
          <strong>제6조 (최초 개시일)</strong>
          <p>“정기결제” 신규신청에 의한 결제개시일은 “당사”의 상품 구매 시 “정기결제” 동의에 의하여 결정되며 “당사”로부터 최초 상품 결제일을 최초 개시일로 합니다.</p>
        </div>

        <div>
          <strong>제7조 (신청 및 취소)</strong>
          <p>“정기결제” 신규 신청은 해당 상품의 구매 결제 시 “정기결제”에 동의함으로 신청서를 대체하며, 취소 및 변경은 “당사” 네모안 크라우드 서비스(cloud.raonix.com)의 웹사이트에서 해당 상품을 변경하셔야 합니다.</p>
        </div>

        <div>
          <strong>제8조 (결제기준 및 이의제기)</strong>
          <p>“정기결제” 신청에 의한 “결제수단”에서의 결제금은 해당 일에 청구 된 청구금액에 한하여 “당사”의 청구대로 결제가 이루어지며, 청구금액에 이의가 있는 경우에는 “고객”과 “당사”가 조정하여야 합니다.</p>
        </div>

        <div>
          <strong>제9조 (정보제공)</strong>
          <p>“정기결제” 업무처리를 위하여 “정기결제”와 관련한 고객정보(신용카드정보, 직불카드정보, 법인인 경우 사업자등록번호, 개인인 경우 주민번호 일부 등)가 “당사”에 제공되며, 제공된 정보에 대해 당사는 관련 업무 이외의 목적에 사용하지 않습니다.</p>
        </div>

        <div>
          <strong>제10조 (“금융기관”의 “정기결제” 임의해지)</strong>
          <p>“정기결제” 등록 “결제수단”이 “금융기관”에서 별도로 정하는 기간 동안 “정기결제” 이용실적이 없는 경우 “금융기관”에서 해당 고객에게 사전통지 없이 “정기결제”를 해지할 수 있습니다.</p>
        </div>

        <div>
          <strong>제11조 (약관 적용)</strong>
          <p>“정기결제” 신청을 “당사”의 온라인 서비스에서 동의하여 “정기결제”를 신청한 경우 이 약관을 적용합니다.</p>
        </div>

        <div>
          <strong>부칙</strong>
          <p>1. (시행일) 본 약관은 2019년 09월 01일부터 시행합니다.</p>
        </div>
      </div>
    );
  }
}

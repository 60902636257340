import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap";

import payImage from "../../assets/img/payment_icon_yellow_medium.png";
import { priceFormat } from '../../utils';
import { ItemRow} from '../../components/common';

export default class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChange(checked) {
    this.setState({checked});
  }

  handleApply() {
    const { payment } = this.props;

    payment.subscribe = this.state.checked;

    this.props.onApply(payment);
  };

  handleCancel() {
    this.props.onCancel();
  };

  render() {
    const { payment } = this.props;

    return (
      <React.Fragment>
        <Modal
          show={this.props.show}
          onHide={this.handleCancel}
        >
          <Modal.Header closeButton>
            <Modal.Title>서비스 신청</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ItemRow label='서비스'><p>{payment.rating}</p></ItemRow>
            <ItemRow label='상품명'><p>{payment.name}</p></ItemRow>
            <ItemRow label='가격'><p>{priceFormat.format(payment.price)}</p></ItemRow>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <img src={payImage} style={{cursor: 'pointer'}} onClick={this.handleApply}/>
              </div>
              <div className="row mt-3 border-top">
                <Link className="mr-1" to="/terms/payment" target="_blank">정기 결제 약관</Link>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

Payment.defaultProps = {
  payment: {}
};

import React, { useState, useEffect } from 'react';
import * as Custom from '../auth';

function Login2 ({authState: initAuthState, location, match}) {
  const [state, setState] = useState(initAuthState);
  const [data, setData] = useState();

  useEffect(() => {
    setState(initAuthState)
  }, [initAuthState]);

  const handleAuthStateChange = (nextState, nextData) => {
    console.log(nextState);
    console.log(nextData);

    if (nextState === "signedIn") {
      /* Do something when the user has signed-in */
    }

    setState(nextState);
    setData(nextData);
  }

  return (
    <div>
      <Custom.SignIn authState={state} onStateChange={handleAuthStateChange}/>
      <Custom.SignUp authState={state} onStateChange={handleAuthStateChange}/>
      <Custom.ConfirmSignUp authState={state} authData={data} onStateChange={handleAuthStateChange}/>
      <Custom.ForgotPassword authState={state} onStateChange={handleAuthStateChange}/>
    </div>
  );
}

export default Login2;

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React from 'react';
import {bindActionCreators} from "redux";
import * as Actions from "../../actions";
import {connect} from "react-redux";
import {Card, Dropdown} from "react-bootstrap";
import DeviceItem from "./DeviceItem";
import DeviceTraits from "./DeviceTraits";
import DropdownToggle from "../../components/common/DropdownToggle";

import Thermometer from "./Thermometer";
import Generalio from "./Generalio";
import Farmcontroller from "./Farmcontroller";
import Thermostat from "./Thermostat";
import Heatcable from "./Heatcable";
import Smartsensor from "./Smartsensor";

class Place extends React.Component {
  constructor(props) {
    super(props);
  }

  handleSelectMenu = (eventKey, event) => {
    this.props.onSelectMenu(eventKey, this.props.place);
  };

  render() {
    const {
      principalId,
      place,
      devices,
      onSelectDeviceMenu
    } = this.props;

    // TODO: new device
    let thermometers = [];
    let thermostats = [];
    let generalios = [];
    let farmcontrollers = [];
    let heatcables = [];
    let smartsensors = [];
    let hubs = [];
    let others = [];

    // TODO: new device
    devices.forEach(dev => {
      switch(dev.type) {
        case "thermometer":
          thermometers.push(dev);
          break;
        case "thermostat":
          thermostats.push(dev);
          break;
        case "generalio":
          generalios.push(dev);
          break;
        case "farmcontroller":
          farmcontrollers.push(dev);
          break;
        case "heatcable":
          heatcables.push(dev);
          break;
        case "smartsensor":
          smartsensors.push(dev);
          break;
        case "gateway":
          hubs.push(dev);
          break;
        default:
          others.push(dev);
          break;
      }
    });

    const compareNickname = (a, b) => {
      const na = a.nickname;
      const nb = b.nickname;
      return (na < nb) ? -1 : (na > nb) ? 1 : 0;
    };

    // TODO: new device
    thermometers.sort(compareNickname);
    thermostats.sort(compareNickname);
    generalios.sort(compareNickname);
    farmcontrollers.sort(compareNickname);
    heatcables.sort(compareNickname);
    smartsensors.sort(compareNickname);
    hubs.sort(compareNickname);
    others.sort(compareNickname);

    return (
        <div className="row">
            <div className="col">
                <hr className="mt-0 mb-1" />
                {place ? (
                    <Dropdown className="dropdown" onSelect={this.handleSelectMenu}>
                        <Dropdown.Toggle as={DropdownToggle}>{place.nickname}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey={"settings"}>설정</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey={"delete"}>삭제</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <div className={"toggle"}>장소없음</div>
                )}

                <div className="card-deck">
                    {thermometers.map((device) => (
                        <Thermometer
                            key={device.uuid}
                            device={device}
                            application={place && place.application}
                            principalId={principalId}
                            onSelectMenu={onSelectDeviceMenu}
                        />
                    ))}
                </div>
                <div className="card-deck">
                    {smartsensors.map((device) => (
                        <Smartsensor
                            key={device.uuid}
                            device={device}
                            principalId={principalId}
                            onSelectMenu={onSelectDeviceMenu}
                        />
                    ))}
                </div>
                <div className="card-deck">
                    {farmcontrollers.map((device) => (
                        <Farmcontroller
                            key={device.uuid}
                            device={device}
                            principalId={principalId}
                            onSelectMenu={onSelectDeviceMenu}
                        />
                    ))}
                </div>
                <div className="card-deck">
                    {generalios.map((device) => (
                        <Generalio
                            key={device.uuid}
                            device={device}
                            principalId={principalId}
                            onSelectMenu={onSelectDeviceMenu}
                        />
                    ))}
                </div>
                <div className="card-deck">
                    {heatcables.map((device) => (
                        <Heatcable
                            key={device.uuid}
                            device={device}
                            principalId={principalId}
                            onSelectMenu={onSelectDeviceMenu}
                        />
                    ))}
                </div>
                <div className="card-deck">
                    {thermostats.map((device) => (
                        <Thermostat
                            key={device.uuid}
                            device={device}
                            principalId={principalId}
                            onSelectMenu={onSelectDeviceMenu}
                        />
                    ))}
                </div>
                {/*  디바이스 리스트에서 허브 제외
            <div className="card-deck">
              {hubs.map((device) => (
                <DeviceItem
                  key={device.uuid}
                  device={device}
                  principalId={principalId}
                onSelectMenu={onSelectDeviceMenu}
                />
              ))}
            </div>
            */}
                <div className="card-deck">
                    {others.map((device) => (
                        <DeviceItem
                            key={device.uuid}
                            device={device}
                            principalId={principalId}
                            onSelectMenu={onSelectDeviceMenu}
                        >
                            <DeviceTraits device={device} />
                        </DeviceItem>
                    ))}
                </div>
            </div>
        </div>
    );
  }
}

Place.defaultProps = {
  devices: []
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(Place);

import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

export default class DeviceResult extends Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onClose}
        variant={"success"}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.text}</Modal.Body>
      </Modal>
    );
  }
}


import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions";
import { Switch, Route, Redirect } from "react-router-dom"

import Dashboard from '../../containers/dashboard/Dashboard'
import DevicePage from '../../containers/pages/Device'
import DeviceHistoryPage from '../../containers/pages/DeviceHistory'
import DeviceManagementPage from '../../containers/pages/HubManagement'
import AlarmListPage from '../../containers/pages/AlarmList'
import ReportPage from '../../containers/pages/Report'
import ReportChartPage from '../../containers/pages/ReportChart'
import ReportListPage from '../../containers/pages/ReportList'
import SubscriptionPage from '../../containers/pages/Subscription'
import AccountPage from '../../containers/pages/Account'

const defaultProps = {
  user: {}
};

class Main extends Component {
  constructor(props) {
    super(props);
  }

  getUnckedAlarms() {
    const { principalId } = this.props;

    this.props.actions.fetchUncheckedAlarms(principalId);
  }

  componentDidMount() {
    this.getUnckedAlarms();

    this.timer = setInterval(() => {
      this.getUnckedAlarms();
    }, 30000);
//    }, 1000000);
  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {
    const { principalId, user } = this.props;

    // TODO: new menu
    return (
        <Dashboard user={user}>
          <Switch>
            <Route exact path="/dashboard" render={(props) => (
              <Redirect to="/dashboard/device/list"/>
              )}
            />
            <Route exact path="/dashboard/device/list" render={(props) => (
              <DevicePage principalId={principalId} user={user}/>
              )}
            />
            <Route exact path="/dashboard/device/history" render={(props) => (
              <DeviceHistoryPage principalId={principalId} user={user}/>
            )}
            />
            <Route exact path="/dashboard/device/management" render={(props) => (
              <DeviceManagementPage principalId={principalId} user={user}/>
            )}
            />
            <Route exact path="/dashboard/report/report" render={(props) => (
              <ReportPage {...props} principalId={principalId}/>
              )}
            />
            <Route exact path="/dashboard/report/chart" render={(props) => (
              <ReportChartPage {...props} principalId={principalId}/>
            )}
            />
            <Route exact path="/dashboard/report/list" render={(props) => (
              <ReportListPage principalId={principalId}/>
              )}
            />
            <Route exact path="/dashboard/alarm/list" render={(props) => (
              <AlarmListPage principalId={principalId}/>
              )}
            />
            <Route exact path="/dashboard/account/subscription" render={props => (
              <SubscriptionPage {...props} principalId={principalId} user={user}/>
              )}
            />
            <Route exact path="/dashboard/account/setting" render={props => (
              <AccountPage {...props} principalId={principalId} user={user}/>
            )}
            />
          </Switch>
        </Dashboard>
    )
  }
}

Main.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);

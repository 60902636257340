import React, { Component } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Auth, Logger } from 'aws-amplify';
import { withFederated } from 'aws-amplify-react';
import SignInWithNemoahn1 from "./SignInWithNemoahn1";
import SignInWithNemoahn3 from "./SignInWithNemoahn3";

const logger = new Logger('SignIn');

const FederatedButtons = (props) => (
  <div style={{marginTop: "1rem"}}>
    <Button mt="30" block  onClick={props.googleSignIn}>
      <span className="icon icon-google-plus" style={{marginRight: '0.5rem'}}/>Sign in using Google
    </Button>
  </div>
);

const FederatedNemoahnButtons = (props) => (
  <div style={{marginTop: "1rem"}}>
    <Button mt="30" block  onClick={props.googleSignIn}>
      <span className="icon icon-google-plus" style={{marginRight: '0.5rem'}}/>Sign in using Google
    </Button>
  </div>
);


const Federated = withFederated(FederatedButtons);

const federated_data = {
    google_client_id: '242142492941-g68i7euee6uil3fq84dk49bm84d3snrl.apps.googleusercontent.com'
};

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.checkContact = this.checkContact.bind(this);
    this.changeState = this.changeState.bind(this);
    this.changeFederatedState = this.changeFederatedState.bind(this);
    this.inputs = {};
    this.state = { error: '', alert: false }
  }

  changeState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      onStateChange(state, data);
    }
  }

  changeFederatedState(state, data) {
    const { onStateChange } = this.props;
    if (onStateChange) {
      logger.debug('==============================');
      logger.debug('state', state);
      logger.debug('data', data);
      logger.debug('==============================');
      onStateChange(state, data);
    }
  }

  signIn(e) {
    e.preventDefault();

    const { username, password } = this.inputs;

    Auth.signIn(username || '', password)
      .then(user => this.handleSuccess(user))
      .catch(err => this.handleError(err));
  }

  handleSuccess(user) {
    logger.info('sign in success', user);
    this.checkContact(user);
  }

  handleError(err) {
    logger.info('sign in error:', err);
    /*
      err can be in different structure:
        1) plain text message;
        2) object { code: ..., message: ..., name: ... }
    */
    this.setState({
      error: err.message || err,
      alert: true
    });
    if ( err.code && err.code === 'UserNotConfirmedException') {
      this.changeState('confirmSignUp', {username: this.inputs.username});
    }
  }

  checkContact(user) {
    Auth.verifiedContact(user)
      .then(data => {
        if (data.verified) {
          this.changeState('signedIn', user);
        } else {
          user = Object.assign(user, data);
          this.changeState('verifyContact', user);
        }
      });
  }

  render() {
    const { authState } = this.props;
    if (!['signIn', 'signedOut', 'signedUp'].includes(authState)) { return null; }

    const { error } = this.state;

    return (
      <div className="text-center">
        <div className="login bg-white">
          <h4 className="text-primary title">Sign in your account</h4>
          <Form onSubmit={this.signIn}>
            <Form.Group controlId="formBasicUsername">
              <Form.Label className="text-primary">Username<small>*</small></Form.Label>
              <Form.Control type="email" placeholder="Enter your username(Email)" autoFocus
                            onChange={event => this.inputs.username = event.target.value}/>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="text-primary">Password<small>*</small></Form.Label>
              <Form.Control type="password" placeholder="Enter your password"
                            onChange={event => this.inputs.password = event.target.value}/>
              <Form.Text>
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  this.changeState('forgotPassword')
                }}>
                  Forget your password? Reset password
                </a>
              </Form.Text>
            </Form.Group>

            <div className="d-flex align-items-center submit-box">
              <a className="mr-auto" href="#" onClick={(e) => {
                e.preventDefault();
                this.changeState('signUp')
              }}>No account? Create account</a>
              <Button className="submit-btn" type="submit">SIGN IN</Button>
            </div>
          </Form>

          <div className="hr-divider">
            <h3 className="hr-divider-content hr-divider-heading bg-white text-primary">
              OR
            </h3>
          </div>

          <Federated federated={federated_data} onStateChange={this.changeFederatedState} />
          {/*
          <SignInWithNemoahn1/>
          <SignInWithNemoahn3/>
          */}

          <Alert className="fixed-top" variant="warning" dismissible
                 show={this.state.alert}
                 onClose={() => this.setState({ alert: false })}>
            <p>{error}</p>
          </Alert>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';

class DeviceSelectBox extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(e.target.value);
  };

  render() {
    const { devices, selectedUuid } = this.props;

    return (
      <select
        className="custom-select"
        id="deviceSelect"
        value={selectedUuid && selectedUuid || 'all'}
        onChange={this.handleChange}
      >
        <option key="all"  value="all">All</option>
        {devices.reduce((acc, device) => {
          if(device.type !== 'gateway') {
            acc.push((
              <option key={device.uuid} value={device.uuid}>
                {device.nickname}
              </option>
            ))
          }
          return acc;
        }, [])}
      </select>
    )
  }
}

DeviceSelectBox.defaultPorps = {
  devices: []
};

export default DeviceSelectBox;

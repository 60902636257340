/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

import {
  RECEIVE_REPORT,
  REMOVE_REPORT_ALL,
  REMOVE_REPORT,
  RECEIVE_REPORT_RECORDS
} from './types';

import { queryGetReports } from "../api/report";
import { queryRecordsByTraitId } from "../api/record";

export function receiveReport(uuid, data) {
  return {
    type: RECEIVE_REPORT,
    uuid,
    data
  }
}

export function removeReport(uuid) {
  return {
    type: REMOVE_REPORT,
    uuid
  }
}

export function removeReportAll() {
  return {
    type: REMOVE_REPORT_ALL
  }
}

export function receiveReportRecords(uuid, data) {
  return {
    type: RECEIVE_REPORT_RECORDS,
    uuid,
    data
  }
}

export function fetchReports(principalId) {
  let reports = {};
  return (dispatch, getState) => {
    queryGetReports(principalId)
      .catch((err) => console.error(err))
      .then(response => {
        console.log('queryGetReports Response : ', response);
        reports = response.data;

        if (Object.keys(reports).length === 0) {
          dispatch(removeReportAll());
        } else {
          Object.keys(reports).forEach(key=>{
            dispatch(receiveReport(key, reports[key]));
          });
        }
      })
      .catch(err => {
        console.error(err);
      })
  };
}

export function fetchReportRecords(principalId, reportUuid, report) {
  return (dispatch, getState) => {
    const { traits, startDate, endDate } = report;

    let records = {};
    let ps = [];
    for(let traitUuid in traits) {
      let params = {
        uuid: traitUuid,
        start: startDate,
        end: endDate
      };

      ps.push(queryRecordsByTraitId(principalId, params)
        .then(result => {
          records[traitUuid] = result;
        })
        .catch(err => console.error(err))
      );
    }

    Promise.all(ps).then(result => {
      dispatch(receiveReportRecords(reportUuid, records));
    });
  };
}

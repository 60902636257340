import React, { Component } from 'react';

class AlarmTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAll: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { onChange } = this.props;

    const target = e.target;
    const { id, name } = target;

    if (name === 'all') {
      this.setState({ selectedAll: target.checked });
      onChange(-1, target.checked);
    } else {
      onChange(id, target.checked);
    }
  }

  render() {
    const { alarms } = this.props;

    return (
      <table className="table">
        <thead>
        <tr>
          <th width="5%" style={{ minWidth: 60 }}>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text pl-0 border-0 bg-transparent">
                  <input name="all" type="checkbox" onChange={this.handleChange}/>
                </div>
              </div>
            </div>
          </th>
          <th width="30%">날짜</th>
          <th width="10%">값</th>
          <th width="55%">메시지</th>
        </tr>
        </thead>
        <tbody>
        {Array.isArray(alarms) && alarms.map((alarm, index) => {
          return (
            <tr key={alarm.date} style={{fontWeight: alarm.checked ? 'lighter' : 'bold'}}>
              <td>
                <input
                  name="one"
                  id={index}
                  type="checkbox"
                  checked={alarm.selected || false}
                  onChange={this.handleChange}
                />
              </td>
              <td>{new Date(alarm.date).toLocaleString()}</td>
              <td>{alarm.value}</td>
              <td>{alarm.message}</td>
            </tr>
          )
        })}
        </tbody>
      </table>
    )
  }
}
export default AlarmTable;

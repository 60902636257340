import { merge } from '../utils'
import {
  ADD_DEVICE,
  REQUEST_DEVICE,
  RECEIVE_DEVICE,

  REMOVE_DEVICE,
  REMOVE_DEVICE_ALL,

  REQUEST_DEVICE_STATE,
  RECEIVE_DEVICE_STATE,

  SET_DEVICE_FETCH_ERROR
} from '../actions/types';

const initialState = {
  isFetching: false,
  isInvalid: true,
  icon: -1,
};

const device = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEVICE:
      return {
        ...state
      };
    case RECEIVE_DEVICE:
      return {
        ...state,
        ...action.device,
        isFetching: false,
        isInvalid: false,
      };
    case REQUEST_DEVICE_STATE:
      return {
        ...state,
        isFetching: true,
        isInvalid: false
      };
    case RECEIVE_DEVICE_STATE:
      merge(state.traits, action.traits);
      state.receivedAt = action.receivedAt;

      return {
        ...state,
        isFetching: false,
        isInvalid: false,
      };
    case SET_DEVICE_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        isInvalid: true
      };
    default:
      return state;
  }
};

const devices = (state = {}, action) => {
  //console.log('state: ', state);
  //console.log('action: ', action);
  //console.log('actiontype: ', action.type)
  switch (action.type) {
    case ADD_DEVICE:
      return {
        ...state,
        [action.uuid]: device(undefined, action)
      };
    case REQUEST_DEVICE:
      return state;
    case RECEIVE_DEVICE:
      return {
        ...state,
        [action.uuid]: device({...state[action.uuid]}, action)
      };
    case REMOVE_DEVICE:
      const {...rest} = state;
      delete rest[action.uuid];
      return rest;
    case REMOVE_DEVICE_ALL:
      return {};
    case SET_DEVICE_FETCH_ERROR:
    case REQUEST_DEVICE_STATE:
      return {
        ...state,
        [action.uuid]: device({...state[action.uuid]}, action)
      };
    case RECEIVE_DEVICE_STATE:
      return {
        ...state,
        [action.uuid]: device({...state[action.uuid]}, action)
      };
    default:
      return state;
  }
};

export default devices;

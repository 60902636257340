import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import { InputRow } from '../../components/common'

import {
  queryNotificationSmsGet,
  queryNotificationEmailGet
} from "../../api/r9cloud";

class UserSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      phones: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    console.debug('>>>>>>>>>>>>>>> constructor');
  }

  componentDidMount() {
    const { principalId, user } = this.props;

    queryNotificationEmailGet(principalId)
      .then(response => {
        const emails = response.data;

        this.setState({
          emails,
          oldEmails: emails
        });
      }, err => {console.error(err)});

    queryNotificationSmsGet(principalId)
      .then(response => {
        let phones = response.data;

        this.setState({ oldPhones: phones, phones });
      }, err => {console.error(err)});

    const {
      pushNotification,
      smsNotification,
      emailNotification
    } = user;
    this.setState({ pushNotification, smsNotification, emailNotification })

    console.debug('>>>>>>>>>>>>>>> componentDidMount');
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user !== prevProps.user) {
      console.debug(user);
      const {
        pushNotification,
        smsNotification,
        emailNotification
      } = user;
      this.setState({ pushNotification, smsNotification, emailNotification })
    }
  }

  handleApply() {
    const { onApply } = this.props;
    onApply({
      ...this.state
    });
  };

  handleCancel() {
    this.props.onCancel();
  };

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    switch(name) {
      case 'email':
        this.setState({ emails: [value] });
        break;
      case 'phone':
        this.setState({ phones: [value] });
        break;
      default:
        this.setState({ [name]: value });
        break;
    }
  };

  render() {
    const { show } = this.props;
    const { pushNotification, emailNotification, smsNotification, phones, emails } = this.state;

    let email = emails ? emails[0] : '';

    return (
      <Modal
        show={show}
        size={"lg"}
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputRow label="Notification">
            <form className="form-inline">
              <div className="form-check w-25 justify-content-start">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="pushNotification"
                  checked={pushNotification}
                  onChange={this.handleChange}
                />
                <label className="form-check-label">to App</label>
              </div>
            </form>

            <form className="form-inline">
              <div className="form-check w-25 justify-content-start">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="smsNotification"
                  checked={smsNotification}
                  onChange={this.handleChange}
                />
                <label className="form-check-label">to phone</label>
              </div>
              <div className="form-group flex-fill ml-sm-3">
                <input
                  type="tel"
                  className="form-control w-100"
                  name="phone"
                  placeholder="+8201012345678"
                  value={phones[0]}
                  onChange={this.handleChange}
                />
              </div>
            </form>

            <form className="form-inline">
              <div className="form-check w-25 justify-content-start">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="emailNotification"
                  checked={emailNotification}
                  onChange={this.handleChange}
                />
                <label className="form-check-label">to e-mail</label>
              </div>
              <div className="form-group flex-fill ml-sm-3">
                <input
                  type="email"
                  className="form-control w-100"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
            </form>
          </InputRow>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleApply}>Apply</button>
          <button className="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default UserSetting;

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React from 'react';

/*
export default class ItemRow extends Component {
  render() {
    const { className, ...otherProps } = this.props;
    const { label } = otherProps;

    return (
      <div className={['itemrow row', className].join(' ')}>
        <label className="col-sm-2">{label}</label>
        <div className="col-sm-10">
          {this.props.children}
        </div>
      </div>
    )
  }
}
*/

export default function ItemRow(props) {
  const { className, ...otherProps } = props;
  const { label } = otherProps;

  return (
    <div className={['itemrow row', className].join(' ')}>
      <label className="col-sm-2">{label}</label>
      <div className="col-sm-10">
        {props.children}
      </div>
    </div>
  )
}
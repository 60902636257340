import React from 'react';

import {bindActionCreators} from "redux";
import * as Actions from "../../actions";
import {connect} from "react-redux";
import { Modal } from "react-bootstrap";

import { InputRow } from "../../components/common";

import r9iot from "../../api/r9iot";
import {queryGetDevice} from "../../api/r9cloud";

const DEVICE_TYPE_GATEWAY = 'gateway';

function SubscribeButton(props) {
  function handleClick(e) {
    e.preventDefault();
    props.onClick(props.device);
  }

  return (
    <button
      className="btn btn-text color-primary"
      onClick={handleClick}
      disabled={props.disabled}
    >
      구독
    </button>
  );
}

class ChildDeviceList extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = (device) => {
    this.props.onClick(device);
  };

  render() {
    let { hub, hubDevices, devices } = this.props;

    const compareNickname = (a, b) => {
      const na = a.nickname;
      const nb = b.nickname;
      return (na < nb) ? -1 : (na > nb) ? 1 : 0;
    };

    return (
      <React.Fragment>
        <div className="row col">
          <h6>{hub.id}</h6>
        </div>
        <ul className="list-group mb-3">
          { hubDevices && hubDevices.sort(compareNickname).map(device => {

            const disabled = devices.hasOwnProperty(device.uuid);
            return (
              <li className="list-group-item" key={device.uuid}>
                <div className="form-group row mb-0">
                  <label htmlFor="type" className="col-sm-3 col-form-label">{device.type}</label>
                  <label htmlFor="type" className="col-sm-3 col-form-label">{device.nickname}</label>
                  <label htmlFor="type" className="col-sm-3 col-form-label">{device.id}</label>
                  <div className="col-sm-3 d-flex justify-content-end">
                    <SubscribeButton device={device} disabled={disabled} onClick={this.handleClick}/>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </React.Fragment>
    )
  }
}

ChildDeviceList.defaultProps = {
  hubDevices: []
};

class DeviceSubscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'device',
    };
  }

  componentDidMount() {
    const { principalId, devices } = this.props;

      Object.keys(devices).map((key) => {
        if (devices[key].type === DEVICE_TYPE_GATEWAY) {
          let hubId = devices[key].id;

          this.getHubDevices(principalId, hubId)
            .catch(error=>console.error(error));
        }
      });
  }

  async getHubDevices(userUuid, hubId) {
    try {
      let res = await r9iot.getHubDevices(userUuid, hubId);

      let devices = [];
      for (let uuid in res.data) {
        await queryGetDevice(userUuid, uuid)
          .then(result => {
            devices.push(result.data);
          })
          .catch(err => {
            console.error(err);
          });
      }
      this.setState({ [hubId]: devices });
    } catch (err) {
      console.error(err);
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleAddHub = () => {
    const { hubId } = this.state;
    this.props.onAddHub(hubId);
  };

  handleSubscribeDevice = (device) => {
    this.props.onAddDevice(device);
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render () {
    const modalTitle = this.props.title;
    const { principalId, devices } = this.props;
    let hubs = [];

    Object.keys(devices).map((key) => {
      if (devices[key].type === DEVICE_TYPE_GATEWAY) hubs.push(devices[key]);
    });

    return (
      <Modal
        show={this.props.show}
        size="lg"
        onHide={this.handleCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <InputRow label="디바이스 종류">
            <div className="form-check form-check-inline" style={{ height: '100%' }}>
              <input
                name="type"
                className="form-check-input"
                type="radio"
                value="device"
                checked={this.state.type === 'device'}
                onChange={this.handleChange}
              />
              <label className="form-check-label" htmlFor="type">디바이스</label>
            </div>
            <div className="form-check form-check-inline" style={{ height: '100%' }}>
              <input
                name="type"
                className="form-check-input"
                type="radio"
                value="hub"
                checked={this.state.type === 'hub'}
                onChange={this.handleChange}
              />
              <label className="form-check-label" htmlFor="type">네모안 허브</label>
            </div>
          </InputRow>

          {(this.state.type === "hub") &&
          <div className="form-group row">
            <label htmlFor="nickname" className="col-sm-2 col-form-label">Hub ID</label>
            <div className="col-sm-10">
              <input
                name="hubId"
                type="text"
                className="form-control"
                placeholder="Hub ID (ex: 04011234@raonix.co.kr)"
                value={this.state.hubId}
                onChange={this.handleChange}
              />
            </div>
          </div>}

          {(this.state.type === "device") && hubs.map(hub =>
            <ChildDeviceList
              key={hub.id}
              hub={hub}
              principalId={principalId}
              hubDevices={this.state[hub.id]}
              devices={devices}
              onClick={this.handleSubscribeDevice}
            />
          )}
        </Modal.Body>
        {(this.state.type === "hub") &&
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleAddHub}>허브 추가</button>
        </Modal.Footer>}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.devices
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceSubscribe);


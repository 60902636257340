import React from 'react';
import DeviceTraits from './DeviceTraits';

import config from '../../config'
import r9iot from "../../api/r9iot";
import DeviceItem from "./DeviceItem";
import LevelSwitch from "./LevelSwitch";

function Connection(props) {
  const { value } = props;
  return (
    <span>{value ? "con" : "dis"}</span>
  );
}

function Rssi(props) {
  const { value } = props;
  return (
    <span>{value}</span>
  );
}

function Voltage(props) {
  const { value } = props;
  return (
    <span>{value}</span>
  );
}

class Farmcontroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceState: undefined
    }
  }

  componentDidMount() {
    const { principalId, device } = this.props;
    r9iot.getChildState(principalId, device.id)
      .then(res => {
        console.debug(res);
        if (res.status === 200) {
          // TODO: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method
          this.setState({ deviceState: res.data })
        }
      })
      .catch(error => console.error(error));
  }

  handleLevelSwitchChange = (name, value) => {
    let swidx = parseInt(name.match(/\d+/g));
    let key = name.match(/[a-zA-Z]+/g);

    const { principalId, device } = this.props;
    const params = {
      switches: [
        {
          swidx,
          [key]: value
        }
      ]
    };
    r9iot.setChildState(principalId, device.id, params)
      .catch(error => console.error(error));
  };

  render() {
    const { principalId, device } = this.props;
    const { deviceState } = this.state;

    if (deviceState === undefined) {
      return null;
    }

    let sws = [];
    deviceState.switches.forEach((sw, index) => {
      sws.splice(sw.swidx-1, 0, sw);
    });

    return (
      <DeviceItem {...this.props}>
        {sws && sws.map(sw => (
          <React.Fragment key={sw.swidx}>
            <LevelSwitch
              name={'setposition'+sw.swidx}
              value={sw.setposition}
              min={0}
              max={100}
              unit="%"
              onChange={this.handleLevelSwitchChange}
            />
          </React.Fragment>
        ))}
      </DeviceItem>
    )
  }
}

export default Farmcontroller;

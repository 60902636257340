import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../actions";

import { queryPostReport, queryPutReport, queryDeleteReport } from "../../api/report";
import { queryRecordDevices } from "../../api/record";
import ReportSetting from '../report/ReportSetting';
import ReportResult from '../report/ReportResult';
import { TablePagination, Toolbar } from "../../components/common";
import { dateOpt } from "../report/report";

const PAGE_LINE  = 20;
const PAGE_GROUP  = 10;
const toolBar = [
  { name: 'add', icon: 'icon-plus' },
  { name: 'delete', icon: 'icon-trash' },
  { name: 'refresh', icon: 'icon-cw' }
];

function Theader(props) {
  const { width, id, sortType, sort, onClick } = props;
  let className = 'header';

  if (id === sortType) {
    className += (sort === 1) ? ' headerSortUp' : ' headerSortDown';
  }

  return (<th width={width} id={id} className={className} onClick={onClick}>{props.children}</th>)
}

function TRow(props) {
  const { id, onSelect } = props;

  function handleClick(e) {
    if (e.target.type === undefined) {
      e.preventDefault();
      onSelect(id);
    }
  }

  return (
    <tr onClick={handleClick}>
      {props.children}
    </tr>
  )
}

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdd: false,
      showResult: false,

      reportList: [],
      recordedDevices: [],

      sortType: 'title',
      sortDirections: {
        title: 0,
        memo: 0,
        date: 1
      },

      // Table pagination
      selectedPage: 1
    };
  }

  componentDidMount() {
    console.debug('>>>>>>>>>>>>>>> componentDidMount');
    const { principalId, reports, devices } = this.props;

    queryRecordDevices(principalId)
      .then(response => {
        let recordedDevices = Object.values(response.data);

        recordedDevices.forEach(recordDev => {
          if (devices.hasOwnProperty(recordDev.uuid)) {
            recordDev.history = true;
          } else {
            recordDev.history = false;
          }
        });

        recordedDevices.sort((a, b) => {
          return (a.type < b.type) ? 1 : (a.type > b.type) ? -1 : 0;
        });

        this.setState({ recordedDevices });
      })
      .catch(err => console.error(err));

    this.props.actions.fetchReports(principalId);

  }

  componentDidUpdate(prevProps) {
    const { reports } = this.props;

    if (reports !== prevProps.reports) {
      const reportList = Object.keys(reports).map(uuid => ({ uuid, ...reports[uuid] }));
      this.setState({ reportList });
    }
  }

  handleToolbar = (e) => {
    e.preventDefault();

    const { principalId } = this.props;
    const { reportList } = this.state;
    const name = e.currentTarget.name;

    switch (name) {
      case 'add':
        this.setState({ showAdd: true });
        break;
      case 'delete':
        (async (array) => {
          try {
            for (const e of array) {
              if (e.checked) {
                await queryDeleteReport(principalId, e.uuid)
                  .then(result => {
                    this.props.actions.removeReport(e.uuid);
                  })
                  .catch(err => {console.error(err);});
              }
            }
          } catch(err) {
            console.error(err);
          }
        })(reportList)
          .then(() => {
            this.showResultModal("리포트가 삭제 되었습니다.", 2000);
          })
          .catch(err => console.error(err));
        break;
      case 'refresh':
        this.props.actions.fetchReports(principalId);
        break;
    }
  };

  handleAddApply = (params) => {
    const { principalId } = this.props;

    queryPostReport(principalId, params)
      .then(response => {
        if (response.status === 200) {
          this.showResultModal("리포트가 추가 되었습니다.", 2000);
        } else {
          this.showResultModal("리포트 추가를 실패했습니다.", 2000);
        }
        this.props.actions.fetchReports(principalId);
      })
      .catch(err => {console.error(err);});

    this.setState({ showAdd: false });
  }

  handleAddCancel = () => {
    this.setState({ showAdd: false });
  }

  handlePageSelect = (selectedPage) => {
    this.setState({ selectedPage });
  };

  handleSort = (e) => {
    const { sortType, sortDirections } = this.state;

    const { id } = e.target;

    if (id === sortType) {
      this.setState({
        sortDirections: {
          ...sortDirections,
          [id]:  sortDirections[id] === 1 ? 0 : 1
        }
      })
    } else {
      this.setState({
        sortType: id
      })
    }
  }

  handleRowSelect = (uuid) => {
    const { history } = this.props;

    this.props.actions.selectSidebarItem('/dashboard/report/chart');
    history.push({
      pathname: '/dashboard/report/chart',
      state: { reportUuid: uuid }
    })
  };

  handleCheck = (e) => {
    const { reportList } = this.state;

    const target = e.target;
    const { id, name } = target;

    if (name === 'selectAll') {
      const { selectedPage } = this.state;
      reportList.forEach((e, index) => {
        const start = (selectedPage - 1) * PAGE_LINE;
        const end = start + PAGE_LINE;
        if (index >= start && index < end) {
          e.checked = target.checked;
        }
      });
    } else {
      let report = reportList.find(e => {
        return (e.uuid === id);
      });
      report.checked = target.checked;
    }

    this.setState({ reportList });
  };

  handleResultClose = () => {
    this.setState({showResult: false});
  };

  showResultModal = (text, timeout) => {
    this.setState({
      showResult :true,
      showResultText :text
    });

    this.hideResultModal(timeout);
  };

  hideResultModal (msec) {
    setTimeout(()=>{
      this.setState({showResult: false});
    }, msec);
  }

  sortTable(rows, sort, direction) {
    const compare = (a, b) => {
      if (a === b) {
        return 0;
      } else {
        if (direction === 0) {
          return (a > b) ? 1 : -1
        } else {
          return (a < b) ? 1 : -1
        }
      }
    };

    rows.sort((a, b) => {
      switch(sort) {
        case 'title':
          return compare(a.title, b.title);
        case 'memo':
          return compare(a.description, b.description);
        case 'date':
          return compare(a.startDate, b.startDate);
      }
    });

    return rows;
  }

  render () {
    const { principalId, user } = this.props;
    const { reportList, recordedDevices, showAdd } = this.state;
    const { selectedPage, sortType, sortDirections } = this.state;

    let rows = reportList.filter((e, index) => {
      const start = (selectedPage - 1) * PAGE_LINE;
      const end = start + PAGE_LINE;
      return (index >= start && index < end);
    });
    this.sortTable(rows, sortType, sortDirections[sortType]);

    return (
      <React.Fragment>
        <Toolbar items={toolBar} onClick={this.handleToolbar}/>
        <div className="table-responsive">
          <table className="table" data-sort="table">
            <thead>
            <tr>
              <th width="5%" id='check' className="header" >
                <input
                  type="checkbox"
                  className="select-all"
                  name="selectAll"
                  onChange={this.handleCheck}
                />
              </th>
              <Theader width="20%" id='title' sortType={sortType} sort={sortDirections['title']} onClick={this.handleSort}>
                제목
              </Theader>
              <Theader width="30%" id='memo' sortType={sortType} sort={sortDirections['memo']} onClick={this.handleSort}>
                메모
              </Theader>
              <Theader width="25%" id='date' sortType={sortType} sort={sortDirections['date']} onClick={this.handleSort}>
                날짜
              </Theader>
            </tr>
            </thead>
            <tbody>
            {rows.map((e) => {
              return (
                <TRow key={e.uuid} id={e.uuid} onSelect={this.handleRowSelect}>
                  <td>
                    <input
                      id={e.uuid}
                      type="checkbox"
                      checked={e.checked}
                      onChange={this.handleCheck}
                    />
                  </td>
                  <td>{e.title}</td>
                  <td>{e.description}</td>
                  <td>
                    {new Date(e.startDate).toLocaleString('ko-KR', dateOpt)} ~
                    {new Date(e.endDate).toLocaleString('ko-KR', dateOpt)}
                  </td>
                </TRow>
              )
            })}
            </tbody>
          </table>
        </div>

        <TablePagination
          selectedPage={selectedPage}
          count={reportList.length}
          pageLine={PAGE_LINE}
          pageGroup={PAGE_GROUP}
          onPageSelect={this.handlePageSelect}/>

        <ReportSetting
          title='리포트 추가'
          principalId={principalId}
          devices={recordedDevices}
          show={showAdd}
          onApply={this.handleAddApply}
          onCancel={this.handleAddCancel}
        />

        <ReportResult
          show={this.state.showResult}
          text={this.state.showResultText}
          onClose={this.handleResultClose}
        />

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    reports: state.reports,
    devices: state.devices
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Report);

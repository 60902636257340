/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

import { queryUserGet } from "../api/r9cloud";

import { RECEIVE_USER } from "./types";

export function receiveUser(data) {
  return {
    type: RECEIVE_USER,
    user: {...data}
  };
}

export function fetchUser(principalId) {
  return (dispatch, getState) => {
    queryUserGet(principalId)
      .then(result => {
        dispatch(receiveUser(result.data));
      })
      .catch(err => console.error(err));
  };
}

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */
import React from 'react';
import { APPLICATIONS } from '../../constants';
import DeviceItem from "./DeviceItem";
import TraitItem from "./TraitItem";
import { findTrait } from '../../utils';
import mollier from '../../mollier';
import DeviceTraits from "./DeviceTraits";

class Thermometer extends React.Component {
  render() {
    const { device, application, principalId } = this.props;

    let humidity = findTrait(device.traits, 'humidity');
    let temperature = findTrait(device.traits, 'temperature');

    let result = mollier(temperature.currentValue, humidity.currentValue);

    return (
      <DeviceItem {...this.props}>
        <DeviceTraits device={device} principalId={principalId} />
        {application && (application === APPLICATIONS.strawberry.type) &&
        <>
          <TraitItem label="이슬점">
            <h6>{result.dewPoint.toString() + ' °C'}</h6>
          </TraitItem>
          <TraitItem label="수분부족량">
            <h6>{result.humidityDeficit.toString() + ' g/m3'}</h6>
          </TraitItem>
          <TraitItem label="수증기압차">
            <h6>{result.vapourPressureDeficit.toString() + ' kPa'}</h6>
          </TraitItem>
        </>
        }
      </DeviceItem>
    );
  }
}

export default Thermometer;

/**
 * @fileoverview
 *
 *
 *
 * @date
 * @author
 */

import {
  ADD_DEVICE,
  REQUEST_DEVICE,
  RECEIVE_DEVICE,
  REMOVE_DEVICE,
  REMOVE_DEVICE_ALL,
  SELECT_DEVICE,
  INVALIDATE_DEVICE,
  REQUEST_DEVICE_STATE,
  RECEIVE_DEVICE_STATE,

  SET_DEVICE_FETCH_ERROR
} from './types';

import { queryGetDevice, queryDevices, queryDeviceTraits } from '../api/r9cloud';

//
// Device
//
export function addDevice(uuid){
  return {
    type: ADD_DEVICE,
    uuid
  }
}

export function requestDevice(uuid) {
  return {
    type: REQUEST_DEVICE,
    uuid
  };
}

export function receiveDevice(uuid, data) {
  return {
    type: RECEIVE_DEVICE,
    uuid,
    device: {...data}
  };
}

export function removeDevice(uuid) {
  return {
    type: REMOVE_DEVICE,
    uuid
  }
}

export function removeDeviceAll() {
  return {
    type: REMOVE_DEVICE_ALL
  }
}

export function selectDevice(uuid) {
  return {
    type: SELECT_DEVICE,
    uuid
  };
}

export function invalidateDevice(uuid) {
  return {
    type: INVALIDATE_DEVICE,
    uuid
  };
}

export function requestDeviceState(uuid) {
  return {
    type: REQUEST_DEVICE_STATE,
    uuid
  };
}

export function receiveDeviceState(uuid, traits) {
  return {
    type: RECEIVE_DEVICE_STATE,
    uuid,
    traits,
    receivedAt: Date.now()
  };
}

export function setFetchError(uuid) {
  return {
    type: SET_DEVICE_FETCH_ERROR,
    uuid
  };
}

export function fetchDevice(principalId, uuid) {
  return (dispatch, getState) => {
    dispatch(requestDevice(uuid));
    return queryGetDevice(principalId, uuid)
      .then(response => {
        dispatch(receiveDevice(uuid, response.data));
        return dispatch(fetchDeviceState(principalId, uuid));
      })
      .then(result => {
        return getState();
      })
      .catch((err) => {
        console.error(err);
        dispatch(setFetchError(uuid))
      })
  };
}

export function addDeviceAndFetchDevice(principalId, uuid) {
  return (dispatch, getState) => {
    dispatch(addDevice(uuid));
    return dispatch(fetchDevice(principalId, uuid));
  };
}

function shouldFetchDeviceState(state, uuid) {
  const device = state.devices[uuid];
  if (!device) {
    return false
  } else if (device.isFetching) {
    return false
  } else {
    return device.isInvalid;
  }
}

export function fetchDeviceStateIfNeeded(principalId, uuid) {
  return (dispatch, getState) => {
    if (shouldFetchDeviceState(getState(), uuid)) {
      return dispatch(fetchDeviceState(principalId, uuid))
    }
  }
}

export function fetchDeviceList (principalId) {
  return (dispatch, getState) => {
    queryDevices(principalId)
      .then((result) => {
        let uuids = Object.keys(result);
        if (uuids.length === 0){
          dispatch(removeDeviceAll());
        } else {
          uuids.forEach((uuid) => {
            dispatch(receiveDevice(uuid, result[uuid]));
            dispatch(fetchDeviceState(principalId, uuid));
          });
        }
      })
      .catch((err) => console.error(err))
  };
}

export function fetchDeviceState(principalId, uuid) {
  return (dispatch, getState) => {
    dispatch(requestDeviceState(uuid));
    queryDeviceTraits(principalId, uuid)
      .then((response) => {
        dispatch(receiveDeviceState(uuid, response.data));
      })
      .catch(error => {
        dispatch(setFetchError(uuid))
        console.error(error);
      })
  };
}
